.adminContainerArea {
  height: 100%;
  width: 100%;
  display: block;
}
.adminContainArea {
  height: 100%;
  width: 100% !important;
  margin: 0;
  padding: 0;
}
.AdminWorkSpace {
  padding: 14px 70px 20px 70px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 12px;
}
.AdminWorkSpace .hmTabsContainer {
  flex-direction: row;
  width: 100%;
  align-items: center;
}
.lrarrowBtn.left {
  left: 0;
}

.lrarrowBtn.right {
  right: 0;
}
.lrarrowBtn {
  height: 32px;
  width: 32px;
}
.lrarrowBtn svg {
  color: #adadad;
}
.AdminWorkSpace .hmTabsContainer .hmTabsGroup {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  flex-direction: row;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none;
}
.noscrollBar::-webkit-scrollbar {
  width: 0px !important;
  height: 1px !important;
}
.noscrollBar::-webkit-scrollbar-thumb {
  background-color: rgba(107, 107, 107, 0.4);
}
.noscrollBar::-webkit-scrollbar-track {
  background: rgb(18 18 18 / 70%);
}
.AdminWorkSpace .hmTabsBtn {
  width: 100%;
  align-items: center;
  justify-content: center;
}
.AdminWorkSpace .hmTabsBtn .hmTabLayer {
  display: flex;
  padding: 0px 16px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  flex: 1 0;
  align-self: stretch;
  position: relative;
  width: calc(100% - 32px);
}
.AdminWorkSpace .hmTabActvIndcator {
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: 0px;
  background: #fff;
  transition: all 0.5s;
}
.createUnitsArea {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  height: calc(100vh - 182px);
}
.cuHeaderGrp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
}
.cuhederTxt {
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;
  font-family: Inter;
}
.AdminRightFlotingContainer {
  height: calc(100vh - 80px);
  width: 40vw;
  position: absolute;
  bottom: 0px;
  right: 0px;
  background-color: #000000;
  transition: all 0.6s;
  /* animation: schFloting-appear 1s ease forwards; */
  overflow: hidden;
  z-index: 999;
}
.cuContainArea {
  height: 100%;
  width: 100%;
  overflow: hidden !important;
  overflow-y: overlay !important;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none;
}
.cuContainArea::-webkit-scrollbar {
  width: 0px !important;
  height: 6px !important;
}
.cuContainArea::-webkit-scrollbar-thumb {
  background-color: rgba(107, 107, 107, 1);
}
.cuContainArea::-webkit-scrollbar-track {
  background: rgb(18 18 18 / 70%);
}
.cuContainArea .noDataCard {
  max-width: 100%;
}
.noDataCard .sallMsg {
  font-size: 14px;
  font-weight: 400;
}

.AdminRightFlotingContainer .flotingAreaClose {
  border-bottom: 1px solid #545454;
  padding-bottom: 10px;
}

.AdminRightFlotingContainer .elementFooter {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 30px;
  border-top: 1px solid #545454;
  width: calc(100% - 60px);
  gap: 10px;
  flex-direction: row;
}
.AdminRightFlotingContainer .elementFormContainer {
  gap: 10px;
  padding: 20px 70px 20px 30px;
}
.formControl .MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementStart {
  justify-content: flex-end !important;
  margin-left: 0px;
  color: #fff;
}
.AdminRightFlotingContainer .formControl .MuiButtonBase-root.MuiCheckbox-root {
  color: #707070;
  padding: 4px;
  margin-left: 10px;
}
.AdminRightFlotingContainer
  .formControl
  .MuiButtonBase-root.MuiCheckbox-root
  svg {
  height: 22px;
  width: 22px;
}
.formControl .feformGroup {
  gap: 50px;
}
.textWithOption {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.textWithOption .fromText {
  width: auto;
  color: #ffffff;
  font-family: Inter;
}
.textWithOption .formControl {
  width: auto;
}

.textWithOption .MuiFormGroup-root.formRadioGroup {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  gap: 5px;
  padding-left: 15px;
}

.textWithOption
  .formControl
  .MuiButtonBase-root.MuiRadio-root.MuiRadio-colorPrimary {
  padding: 5px;
}

.AdminRightFlotingContainer .formElement {
  /* min-width: 375px; */
  width: 100%;
  max-width: 100%;
}

.AdminRightFlotingContainer
  .formElement
  .MuiFormControlLabel-root.formRadioField {
  color: #b2b2b2;
}

.AdminRightFlotingContainer
  .formElement
  .formControl
  .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-shrink {
  background-color: transparent;
  color: #d5d5d5;
  transform: translate(14px, -13px) scale(0.75);
}
.addUnitsBtn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}
.cuCardList .cardContent {
  width: calc((100% - 48px) / 4);
  max-width: 100%;
  border-radius: 8px;
  background-color: #333333;
  box-shadow: 2px 3px 3px -1px #141414;
  color: #b2b2b2;
  font-family: Inter;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  gap: 2px;
}
.cuCardList .cardContent .MuiCardContent-root {
  padding-bottom: 6px;
  width: calc(100% - 32px);
}
.cuCardList .cardContent .MuiCardActions-root.MuiCardActions-spacing {
  width: calc(100% - 16px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
}
.CUcardTitle {
  font-size: 20px;
  line-height: 26px;
  color: #fff;
}
.CUCardLocation {
  width: 100%;
  border-bottom: 1px dashed #292929;
  padding-bottom: 2px;
}
.cuCheckItem {
  width: 100%;
  border-bottom: 1px dashed #292929;
  padding-bottom: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: all 0.8s;
}
.cuCheckItem:last-child {
  border-bottom: none;
}
.cuCheckItem span {
  font-weight: 600;
}
.cuCheckItem:hover {
  color: #fff;
}
.cuCardList {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 12px;
}
.ortxt {
  color: #fff;
}
.mxW-150 {
  max-width: 120px !important;
}
/* tree view css start */
.TV_Container {
  --level-1: #d9d9d9;
  --level-2: #f5cc7f;
  --level-3: #d9d9d9;
  --level-4: #f27c8d;
  --black: #545454;
  padding: 0 10px;
  width: fit-content;
  text-align: left;
}
.TV_Container .MuiButtonBase-root.MuiCheckbox-root {
  color: #707070;
  padding: 4px;
  margin-left: 2px;
}
.TV_Container .MuiButtonBase-root.MuiCheckbox-root svg.MuiSvgIcon-root {
  height: 20px;
  width: 20px;
}
.TV_Container ol {
  list-style: none;
  padding-inline-start: 0px;
}

.TV_Container li {
  min-width: 200px;
}
.TV_Container .rectangle span i {
  cursor: pointer;
}

.TV_Container .rectangle {
  position: relative;
  padding: 4px 4px 4px 4px;
  /*            box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);*/
  width: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2px;
  cursor: pointer;
}

.TV_Container .level-start {
  margin-bottom: 20px;
  color: #fff;
  width: fit-content;
}

.TV_Container .level-1-wrapper {
  position: relative;
  width: auto;
  margin-left: 31px;
}

.TV_Container .level-1-wrapper::before {
  content: "";
  position: absolute;
  top: -20px;
  left: -20px;
  width: 2px;
  height: calc(100% + 5px);
  background: var(--black);
}

.TV_Container .level-1-wrapper li + li {
  margin-top: 8px;
}

.TV_Container .level-1 {
  font-weight: normal;
  /*            background: #333333;*/
  color: #fff;
}

.TV_Container .level-1::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translate(-100%, -50%);
  width: 20px;
  height: 2px;
  background: var(--black);
}

/* tree view css end */

.fullContainArea {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  height: calc(100vh - 182px);
}
.width100 {
  width: 100px;
}
.tblActionBtnGrp {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
}
.tblActionBtnGrp .MuiButtonBase-root.MuiIconButton-root {
  color: #d5d5d5;
}
.tblActionBtnGrp .MuiButtonBase-root.MuiIconButton-root.removeRowBtn {
  color: #f7a7a7;
}
.tblActionBtnGrp .MuiButtonBase-root.MuiIconButton-root svg {
  height: 20px;
  width: 20px;
}

.elementWithBtn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
}

.orDiv {
  min-width: 375px;
  width: auto;
  max-width: 100%;
  text-align: center;
  color: #707070;
}
.orDiv span {
  position: relative;
}

.tdText {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 7px;
  color: #b2b2b2;
  font-size: 14px;
  font-family: "Inter";
}
.defRotPos {
  color: #fff;
  font-size: 18px;
  font-family: "Inter";
}
.alredRotPos {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px;
}
.rotposNm {
  color: #fff;
  font-size: 18px;
  font-family: "Inter";
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 16px;
}
.crtChildBtnGrp {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
.nextPosition {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}
.posIcon {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #545454;
}
.posName {
  color: #b5b5b5;
  cursor: pointer;
  font-size: 16px;
}
.activePos .posName {
  color: #fff;
  cursor: pointer;
  font-size: 18px;
}

.elementListGrp {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  flex-direction: column;
  width: 100%;
  gap: 6px;
}
.elementListGrp .elementHeding {
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.7;
}
.elementListGrp ul.elmListInfo {
  padding-inline-start: 0px;
  color: #fff;
  margin: 0px;
  list-style: none;
  font-size: 16px;
  font-family: Inter;
}
.elmListInfo .rmvBtn {
  color: #ff8f8f;
  opacity: 0;
}
.elmListInfo .rmvBtn svg {
  height: 18px;
  width: 18px;
}
.elmListInfo .downlBtn {
  color: #ffffff;
  opacity: 0;
  margin-left: 10px;
}
.elmListInfo .downlBtn svg {
  height: 18px;
  width: 18px;
}
.elmListInfo li {
  padding: 0px 0px 0px 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  transition: all 0.4s;
}

.elmListInfo li:hover {
  background-color: #1c1c1c;
}

.elmListInfo li:hover .downlBtn,
.elmListInfo li:hover .rmvBtn {
  opacity: 1;
}
.AdminRightFlotingContainer .formElementGroup .width50p {
  width: calc(50% - 5px) !important;
}
.expenditureContainer {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
}
.expenElement {
  height: 100%;
  width: calc(100% / 2);
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: flex-start;
  align-items: flex-start;
}

.expElemhead {
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;
  font-family: Inter;
}

.expContain {
  height: 100%;
  width: 100%;
  overflow: hidden;
  overflow-y: overlay;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none;
}
.formElementGroupWthBtn {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 6px;
}
.expContain .elementFormContainer {
  gap: 20px;
}
.elementListGrp ul.elmListInfo.custlist {
  padding-inline-start: 40px;
  color: #fff;
  margin: 0px;
  list-style: decimal;
  font-size: 16px;
  font-family: Inter;
}
.elmListInfo.custlist li {
  padding: 0px 0px 0px 10px;
  cursor: pointer;
  display: list-item;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  transition: all 0.4s;
}
.CriticalRightsContainer {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 60px;
}
.critiaclElement {
  height: 100%;
  width: calc(100% / 2);
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: flex-start;
  align-items: flex-start;
}

.wthP40 {
  width: 40%;
}
.wthP60 {
  width: 60%;
}
.critElemntHead {
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;
  font-family: Inter;
}
.criHeadContenr {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.critContain {
  width: 100%;
}
.AdminRightFlotingContainer .elementFormContainer .formElementGrp {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
  /* flex-wrap: wrap; */
  width: 100%;
}
.strogeSearchSpace {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  width: 100%;
}

.storeTimeline {
  width: 100%;
  max-width: 420px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
}

.storeTimBar {
  width: 100%;
}

.storeTimBar .progress {
  background-color: #333333;
  border-radius: 4px;
  position: relative;
  margin: 2px 0;
  height: 18px;
  width: 100%;
  min-width: 200px;
  max-width: 420px;
}

.storeTimBar .progress-done {
  border-radius: 4px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  max-width: 100%;
  background-color: #f39c12;
  opacity: 1;
  transition: 1s ease 0.3s;
}
.storeTimBar .progress-done.storageFull {
  background-color: #ff0000 !important;
}
.storeValueTxt .stvStart {
  font-size: 14px;
  font-family: "Inter";
  color: #707070;
  font-weight: 700;
  letter-spacing: 0.3px;
}
.taskListtableHeader tr th.TDtextright {
  text-align: right;
  width: 120px;
  padding-right: 10px;
}
.taskListTable tbody tr td.TDtextright {
  padding-right: 10px;
}
.TDtextright .tblActionBtnGrp {
  justify-content: flex-end;
}
.TDtextright .tdText {
  justify-content: flex-end;
}
.curntStorCont {
  background-color: #101010;
  background-image: url(../images/art.png);
  height: 160px;
  width: calc(100% - 40px);
  background-position: top left;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.curntDaImg {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-right: 50px;
}
.curntDaImg span svg {
  height: 66px;
  width: 66px;
  color: #ffffff;
}
.curntDaImg span.cuStText {
  font-family: "Russo One", sans-serif;
  font-size: 20px;
  line-height: 38px;
  color: #ffffff;
  letter-spacing: 1px;
}
.CurntStrData .curntStrgTxtlight {
  color: #b2b2b2;
  font-size: 22px;
  font-weight: 300;
  line-height: 30px;
}
.CurntStrData .curntStrgBold {
  color: #f39c12;
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
  display: block;
}
.textWhie {
  color: #ffffff !important;
}
.stroageSlider {
  color: #545454 !important;
  margin-top: 20px;
}
.stroageSlider .MuiSlider-thumb {
  color: #b2b2b2;
}
.stroageTable {
  height: 100%;
  width: 100%;
}
.strgItmResponsive {
  overflow-x: overlay;
}

.stroageTable table {
  border-collapse: collapse;
  width: 100%;
}
.stroageTable td,
.stroageTable th {
  border: 1px solid #141414;
  color: #ffffff;
  padding: 4px 10px;
  font-size: 14px;
}
.stroageTable th {
  background-color: #1c1c1c;
  padding: 10px;
  font-weight: 600;
  letter-spacing: 1px;
}
.heit150 {
  min-height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.taRight {
  text-align: right;
  justify-content: flex-end;
}
.taCenter {
  text-align: center;
  justify-content: center;
}
.crtStorgFoot tr td {
  padding: 6px 10px;
}
.crtStorgFoot strong {
  font-weight: 700;
  letter-spacing: 1px;
  font-size: 18px;
}
.couponStatusSwitch span.MuiFormControlLabel-label {
  width: 60px !important;
  min-width: 60px !important;
  color: #fff;
  font-size: 14px !important;
}
.couponCode {
  font-weight: 600;
  font-size: 16px;
}
.width260 {
  width: 260px;
}
.couponStatusSwitch .MuiSwitch-switchBase.Mui-checked {
  color: #ef6c00;
}
.couponStatusSwitch .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
  background-color: #ef6c00;
}

.accessPerSpan {
  display: -webkit-box;
  width: 136px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #b2b2b2;
  font-size: 14px;
  font-family: "Inter";
  background-color: #262626;
  padding: 2px 6px;
  border-radius: 4px;
  cursor: pointer;
}
.minMax200 {
  min-width: 200px;
  max-width: 200px;
}
.accessName {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
}
.accessName .acnIoc svg {
  color: #b2b2b2;
}
.acsConLstInd {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #333333;
  width: calc(100% - 20px);
  padding: 2px 10px;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  margin-bottom: 8px;
}
ul.accessControlList {
  padding-inline-start: 0px;
  list-style-type: none;
  width: 100%;
}
.instrucDataCard {
  color: #fff;
  position: sticky;
  top: 0px;
  background-color: #1c1c1c;
  z-index: 1;
  padding-bottom: 10px;
  font-size: 16px;
}
.organizationPosition .formInputSelect {
  color: #ffffff !important;
  background-color: #262626 !important;
  width: calc(100% - 8px);
  border-radius: 6px;
}
.organizationPosition
  .formInputSelect
  .MuiSelect-select.MuiSelect-outlined.Mui-disabled {
  opacity: 1;
  -webkit-text-fill-color: rgba(255, 255, 255, 0.9);
}
.organizationPosition .formInputSelect .MuiSvgIcon-root.Mui-disabled {
  color: rgba(255, 255, 255, 0.9);
}
.organizationPosition
  .formInputSelect
  .MuiInputBase-root.MuiOutlinedInput-root {
  color: #ffffff !important;
}
.orgPText {
  font-size: 14px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
.organizationPosition .taskListTable tbody tr td {
  padding: 8px 10px;
  padding-left: 10px;
}
.txtWithBtn .MuiButtonBase-root.MuiIconButton-root {
  color: #fff;
  background-color: #333333;
}
.organizationPosition .txtWithBtn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.MuiButtonGroup-root.MuiButtonGroup-contained.buttonContainerGrp {
  background-color: #262626;
  border-radius: 18px;
  padding: 1px 6px;
}
.buttonContainerGrp .MuiButtonBase-root.MuiIconButton-root {
  color: #d9d9d9;
}
.buttonContainerGrp .MuiButtonBase-root.MuiIconButton-root.remove {
  color: #ec5357;
}

.organizationPosition tbody tr td .buttonContainerGrp {
  opacity: 0.3;
}
.organizationPosition tbody tr:hover .buttonContainerGrp {
  opacity: 1;
}

.organizationPosition .taskListTable {
  width: 100%;
  border-spacing: 1px;
  border-collapse: collapse;
}
.organizationPosition .taskListTable th,
.organizationPosition .taskListTable td {
  border: 0.5px solid #363636;
}

.MuiButtonGroup-root.MuiButtonGroup-contained.buttonContainerGrp.singleBtn {
  background-color: #262626;
  border-radius: 18px;
  padding: 0px 0px;
}
.emptyRowMessage {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 50px 10px;
  gap: 10px;
  font-size: 18px;
  color: rgb(255 255 255 / 30%);
}
.emptyRowMessage .bigTxt {
  font-size: 28px;
}
.emptyRowMessage .MuiButtonBase-root.MuiButton-root {
  background-color: #00adef !important;
  color: #fff !important;
  border-radius: 32px !important;
  padding: 4px 24px;
}
.emptyRowMessage .MuiButtonBase-root.MuiButton-root .MuiSvgIcon-root {
  height: 34px;
  width: 34px;
}
.departmentItem {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
.AdminWorkSpace .ModelBox .elementFormContainer {
  scrollbar-width: thin;
  -ms-overflow-style: inherit;
}

.gdkraBtnGrp {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
th.mxWidth100 {
  min-width: 100px;
  width: 100px;
  max-width: 100px;
}
th.mxWidth120 {
  min-width: 120px;
  width: 120px;
  max-width: 120px;
}
th.mxWidth150 {
  min-width: 150px;
  width: 150px;
  max-width: 150px;
}
.gdkraBtnGrp .MuiButtonBase-root.MuiButton-root {
  text-transform: capitalize;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  background: #606060;
  padding: 6px 18px;
  border-radius: 4px;
  border: 1px solid #323232;
  display: flex;
  align-items: center;
  color: rgb(255 255 255 / 60%);
  max-height: 36px;
}
.bgOfset {
  position: fixed;
  z-index: 1300;
  inset: 0px;
}
.bgOfsetOverlay {
  position: fixed;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgb(28 28 28 / 50%);
  backdrop-filter: blur(4px);
  -webkit-tap-highlight-color: transparent;
  z-index: -1;
}
.width800 {
  min-width: 800px;
  width: 800px;
}
.JDKRAContainer {
  max-height: 70vh;
  overflow: hidden;
  overflow-y: overlay;
  width: 100%;
}
.jdkraHeader {
  position: sticky;
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  z-index: 2;
}
.jdkTitle {
  font-size: 20px;
  font-weight: 600;
}
.jdkraCHeead {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
.w220 {
  width: 220px;
}

.JDKRAContainEdit {
  padding: 16px;
  background-color: #f9f9f9;
  border-radius: 8px;
  margin: 4px;
}

.JDKRAContainEdit ol {
  list-style-type: decimal;
  padding-left: 20px;
  margin: 0px;
}

.jdFromElement {
  margin-bottom: 10px;
}
li.topList {
  border-bottom: 1px solid #b2b2b2;
  margin-bottom: 10px;
}

.KratitleAdd {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
  gap: 6px;
}

.subElement {
  padding: 10px;
  /* background-color: #ffffff; */
  border-radius: 8px;
  margin-bottom: 10px;
}

.subElmntHead {
  font-weight: bold;
  margin-bottom: 10px;
}

.subElmntList {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  gap: 6px;
}
.JDKRAContainEdit .MuiButtonBase-root.MuiIconButton-root {
  background-color: #d2d2d2;
}
.PmsContainer {
  width: 610px;
  height: calc(100vh - 230px);
  flex-shrink: 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 10px;
  overflow: hidden;
}
.minMax150 {
  width: 150px !important;
  min-width: 150px !important;
  max-width: 150px !important;
}
.labelText {
  color: #fff;
  font-size: 16px;
  font-family: Inter;
}
.itemName {
  color: #fff;
  font-size: 14px;
  font-family: Inter;
}
.itemNMActionGrp {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 6px;
}
.itemNMActionGrp .MuiButtonBase-root.MuiIconButton-root {
  background-color: #262626;
  color: #ff8f8f;
  padding: 6px;
}
.itemNMActionGrp .MuiButtonBase-root.MuiIconButton-root svg {
  height: 16px;
  width: 16px;
}
.pmItemlist {
  border-bottom: 1px solid #545454;
  margin-bottom: 10px;
  padding-bottom: 4px;
}
.evaluationMetrics {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
}
.evmTitle {
  font-size: 16px;
  color: #fff;
  font-family: Inter;
  font-weight: 600;
}
ol.evmList {
  padding-inline-start: 20px;
  color: #fff;
  width: calc(100% - 20px);
}
ol.evmList li::marker {
  top: 10px;
}
.evmList
  .pmItemlist
  .MuiFormControlLabel-root
  .MuiFormControlLabel-label.Mui-disabled {
  color: rgba(255, 255, 255, 0.5) !important;
  cursor: no-drop;
}
.copyPositionRow {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}
.copyPositionbtn .MuiButtonBase-root.MuiButton-root {
  background-color: #00adef !important;
  color: #fff !important;
  border-radius: 6px !important;
  padding: 4px 24px;
  height: 40px;
}
.copyPositionbtn .MuiButtonBase-root.MuiButton-root .MuiSvgIcon-root {
  height: 22px;
  width: 22px;
}
.cuCardList .cardContent.widthfalf {
  width: 50% !important;
  max-width: 100% !important;
}
.JDKRAContainer p {
  margin: 2px 0px;
}
.jdkElement {
  margin-bottom: 20px;
}
.jdKraBtnFlex {
  display: flex;
  gap: 4px;
}

.gdkraBtnGrp .MuiButtonBase-root.MuiButton-root.activeKra,
.gdkraBtnGrp .MuiButtonBase-root.MuiButton-root.activeJd {
  text-transform: capitalize;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  background: #00adef;
  padding: 6px 18px;
  border-radius: 4px;
  border: 1px solid #323232;
  display: flex;
  align-items: center;
  color: rgb(255 255 255);
  max-height: 36px;
}
ul.tskLstTaskNMGroup {
  display: flex;
  flex-direction: column;
  gap: 2px;
  color: #a1a1a1;
  padding-inline-start: 20px;
  margin: 4px 0px;
}
.width100p {
  width: 100%;
}

.ModelBox.salaryComponent .elementFormContainer {
  max-height: 60vh;
  height: auto;
  padding: 0px;
  width: 100%;
  padding-top: 10px;
}
.facHeaderSpan {
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
}
.AdminRightFlotingContainer .salGropuHead {
  font-weight: 600;
  font-size: 16px;
  background-color: #0d0d0d;
}
.pastSalaryViewGrp,
.epfEsiSetingsGrp {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}
.epfEsiSetingsGrp .MuiButtonBase-root.MuiIconButton-root {
  background-color: #1c1c1c;
}
.epfEsiSetingsGrp .MuiButtonBase-root.MuiIconButton-root svg {
  color: #b2b2b2;
}
.mxWidth250 {
  min-width: 250px;
  max-width: 250px;
  width: 250px;
}

.responsiveTable {
  overflow-x: auto;
}
.w50px {
  width: 50px;
}
.w25percent {
  width: 25%;
}
.parameterTdUl {
  margin: 0;
  padding-inline-start: 0px;
  list-style-type: none;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: stretch;
}
.parameterTdLi {
  border-radius: 6px;
  background-color: #bababa1a;
  padding: 4px 10px;
  border-bottom: 0.2px solid #b2b2b269;
  min-width: 260px;
}
.parameterTdLi .orgPText span:first-child {
  font-weight: 400;
  opacity: 0.8;
  min-width: 82px;
  letter-spacing: 0.5px;
}
.custParamUl .parameterTdUl {
  width: 100%;
  display: block;
}
.btnGroup {
  display: flex;
  align-items: center;
  gap: 5px;
}

.custParamUl .parameterTdUl .parameterTdLi {
  margin-bottom: 6px;
}
.paginationContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}
.paginationContainer .MuiButtonBase-root.MuiPaginationItem-root {
  color: #989898 !important;
}
.paginationContainer .MuiButtonBase-root.MuiPaginationItem-root.Mui-selected {
  color: #000000 !important;
  background-color: #b2b2b2 !important;
}
.centerCircularProgress {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50% -50%);
}
.MuiAutocomplete-popper {
  z-index: 1500 !important;
}
.elementGrouping {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.vaTop {
  vertical-align: top;
}
.trwithFrm {
  height: 48px;
}
.text-highlight {
  color: #989898;
}
.pEcontrolGroup {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  flex-direction: row;
}
.pEcontrolGroup .MuiButtonBase-root.MuiIconButton-root {
  padding: 4px;
  color: #969696;
  background-color: #262626;
}
.pEcontrolGroup .MuiButtonBase-root.MuiIconButton-root svg {
  height: 18px;
  width: 18px;
}
.pERemoveRowBtn.MuiButtonBase-root.MuiIconButton-root {
  padding: 6px;
  color: #ff7b7b;
  background-color: #262626;
}
.pERemoveRowBtn.MuiButtonBase-root.MuiIconButton-root svg {
  height: 16px;
  width: 16px;
}
