.schedulerContainerArea {
  height: 100%;
  width: 100%;
  display: block;
}
.schedulerContainArea {
  height: 100%;
  width: 100% !important;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.pRelative {
  position: relative;
}
.schedulerContainer {
  padding: 10px 70px 10px 70px;
  width: calc(100% - 140px);
  height: calc(100vh - 100px);
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 10px;
  overflow: hidden;
}
.SchduleRightFlotingContainer {
  height: calc(100vh - 80px);
  width: 40vw;
  position: absolute;
  bottom: 0px;
  right: 0;
  background-color: #000000;
  transition: all 0.6s;
  /* animation: schFloting-appear 1s ease forwards; */
  overflow: hidden;
  z-index: 999;
}

@keyframes schFloting-appear {
  to {
    right: 0;
    opacity: 1;
  }
  from {
    right: -40vw;
    opacity: 0;
  }
}
.SchedulerHeader {
  color: #fff;
  font-family: Inter;
  font-size: 28px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  cursor: pointer;
}

.schedulerTopBtnGroup {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
}

.schdulerTopBtnLeft {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  flex: 1;
}
.schdulerTopBtnLeft .formElement {
  max-width: 160px;
}
.schedulerTopBtnGroup .MuiButtonGroup-root.MuiButtonGroup-contained {
  background-color: #545454;
}
.schedulerTopBtnGroup .MuiButtonGroup-root.MuiButtonGroup-contained svg {
  color: #ffffff;
}

.CalendarBtnViewGroup .MuiButtonGroup-root.MuiButtonGroup-contained {
  background-color: #545454;
}
.CalendarBtnViewGroup .MuiButtonBase-root.MuiButton-root {
  background-color: #545454 !important;
  border-color: transparent !important;
  font-family: Inter;
  font-size: 14px;
  color: #fff;
  text-transform: capitalize !important;
  transition: all 0.6s;
}
.CalendarBtnViewGroup .MuiButtonBase-root.MuiButton-root:hover {
  background-color: #333333 !important;
}
.CalendarBtnViewGroup .MuiButtonBase-root.MuiButton-root.activeBtn {
  background-color: #333333 !important;
}
.formSingBtn .MuiButtonBase-root.MuiButton-root {
  color: #ffffff;
  background-color: #545454;
  border-radius: 4px;
  padding: 3px 16px;
  font-family: Inter;
  font-size: 16px;
  line-height: 24px;
  height: 40px;
  text-transform: capitalize;
}

.formDatePick
  .react-datepicker-wrapper
  .react-datepicker__input-container
  input {
  height: 40px;
  outline: none;
  border: none;
  padding-block: 0px;
  padding: 0px 32px 0px 16px;
  border-radius: 4px;
  color: #ffffff;
  background-color: #545454;
  font-size: 16px;
  font-family: Inter;
}
.schedulerLeagents {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  cursor: pointer;
}

.schLeagendsInd:first-child {
  margin-left: 0px;
}
.schLeagendsInd {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
  margin-left: -20px;
  transition: all 0.8s;
}
.legHead {
  font-size: 12px;
  font-weight: 700;
  font-family: Inter;
  text-transform: uppercase;
  color: #fff;
  height: 24px;
  width: 24px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.myCalendar {
  background-color: #16a085;
  border-right: 1px solid #0a7963;
}
.gCalendar {
  background-color: #f39c12;
  border-right: 1px solid #ad6d05;
}
.weekend {
  background-color: #606060;
}
.holiday {
  background-color: #8c7ae6;
}
.leave {
  background-color: #e84118;
}
.legLabel {
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: center;
  color: #ffffff;
  display: none;
  transition: all 5s;
}

.schedulerLeagents:hover .schLeagendsInd {
  margin-left: 0px;
}
.schedulerLeagents:hover .legLabel {
  display: inline-block;
}

.schdulerTopBtnRight {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}
.flotingAreaContainer {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 10px;
  overflow: hidden;
  padding: 0px;
}

.flotingAreaContainer .elementFormContainer {
  overflow: hidden !important;
  overflow-y: overlay !important;
  height: calc(100vh - 200px);
  width: calc(100% - 60px);
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 14px;
  padding: 0px 30px 0px 30px;
}
.pRelative {
  position: relative;
}

.flotingAreaContainer .elementFormContainer::-webkit-scrollbar {
  width: 0px !important;
  height: 0px !important;
}

.flotingAreaContainer .elementFormContainer::-webkit-scrollbar-thumb {
  background-color: rgba(107, 107, 107, 1);
}

.flotingAreaContainer .elementFormContainer::-webkit-scrollbar-track {
  background: rgb(18 18 18 / 70%);
}
.facHeader {
  color: #fff;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.24px;
  padding: 0px 10px 0px 30px;
}
.CloseBtn {
  color: #fff !important;
  padding: 4px !important;
}
.flotingAreaClose {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 10px);
  padding: 10px 10px 6px 0px;
}

.formElement
  .MuiInputBase-root.MuiFilledInput-root
  .MuiInputBase-input.MuiFilledInput-input {
  background: #333333;
  border-radius: 8px;
  color: #ffffff;
  padding: 10px 10px;
  width: 100%;
  font-size: 20px;
  line-height: 26px;
}
.formElement .MuiInputBase-root.MuiFilledInput-root {
  border-radius: 8px;
}
.formElement .MuiInputBase-root.MuiFilledInput-root::after {
  border-bottom: 2px solid #545454;
}
.controlWithRemove {
  display: flex !important;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row !important;
  width: 100%;
  gap: 6px;
}
.controlWithRemove .formTextFieldArea {
  flex: 1;
}

.multiElementFrmGrp {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2px;
  flex-direction: column;
  width: 100%;
  padding: 6px 0px 6px 0px;
  border-bottom: 1px solid #545454;
}
.removeBtn {
  border: 1px solid #545454 !important;
  color: #ff9d9d !important;
  padding: 8px !important;
  background: #333333 !important;
}
.audioNote svg,
.removeBtn svg {
  height: 20px;
  width: 20px;
}
.audioNote {
  border: 1px solid #545454 !important;
  color: #ffffff !important;
  padding: 6px !important;
  background: #707070 !important;
}
.audioNoteTooltip {
  position: absolute !important;
  bottom: 8px;
  right: 8px;
}

.playAudioNotes {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  color: #ffffff;
  font-size: 14px;
  font-family: Inter;
  margin-top: 5px;
}
.playAudioNotes .MuiButtonBase-root.MuiIconButton-root {
  border: 1px solid #545454 !important;
  color: #ffffff !important;
  padding: 4px !important;
  background: #333333 !important;
}
.elementFooter {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  border-top: 1px solid #545454;
  width: 100%;
  gap: 10px;
  flex-direction: row;
}

.fixedButtonsContainer {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  border-top: 1px solid #545454;
  width: 100%;
  gap: 10px;
  flex-direction: row;
  bottom: 0;
}
.settingsView button {
  color: #545454 !important;
}
.searchLeaveBtn .MuiButtonBase-root.MuiIconButton-root {
  background-color: #545454;
}
.searchLeaveBtn .MuiButtonBase-root.MuiIconButton-root svg {
  fill: #fff;
  color: #fff;
}
.ReactTable.viewLeaveListTD {
  border: 1px solid #545454;
  color: #d2d2d2;
}
.rt-td .MuiButtonBase-root.MuiIconButton-root {
  background-color: #333333;
  color: #d2d2d2;
}

.viewLeaveListTD .rt-thead.-filters .rt-th input {
  background-color: #333333;
  border: 1px solid #545454;
  height: 37px;
}
.MuiButtonBase-root.MuiButton-root.showHideLeaveDtlBtn {
  background-color: #333333;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 8px 12px;
  height: 36px;
  border: 1px solid #545454;
}

.viewLeaveListTD .rt-tr.-padRow.-odd .rt-td {
  background-color: #080808;
}

.headWthInfo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #ffffff !important;
  caret-color: #ffffff !important;
}
.SchduleRightFlotingContainer .MuiAutocomplete-endAdornment svg {
  color: rgb(255 255 255 / 50%);
}
