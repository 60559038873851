.kycVerfContainer {
  padding: 50px 140px;
  min-height: 22vh;
  margin-top: 130px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.kycVerfyForm {
  border-radius: 8px;
  background-color: #242424;
  padding: 20px;
  width: 810px;
  flex-shrink: 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 12px;
}
p.MuiFormHelperText-root {
  font-style: italic;
  color: #aaaaaa;
}
.kycVerfyForm .formRadioField .MuiButtonBase-root.MuiRadio-root {
  padding: 4px;
}
.questionGroup {
  width: 100%;
}
.w80 {
  width: 80px;
  min-width: 80px;
  max-width: 80px;
}
.mb6 {
  margin-bottom: 6px;
}
.kycVerfyForm .iconBtnGroup .saveBtn {
  padding: 6px 8px !important;
}
.w150 {
  width: 150px;
  min-width: 150px;
  max-width: 150px;
}
.w140 {
  width: 140px !important;
  min-width: 140px !important;
  max-width: 140px !important;
}
.kycActionBtn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.kycActionBtn .MuiButtonBase-root.MuiIconButton-root.removeRowBtn svg {
  fill: #e09595;
  font-size: 18px;
  height: 18px;
  width: 18px;
}
.kycActionBtn .MuiButtonBase-root.MuiIconButton-root.removeRowBtn {
  color: #e09595;
  background-color: #333;
  padding: 6px;
}
.kycVerfyForm .formTextFieldArea input {
  padding: 8px 12px 8px 12px;
}
.uplodedFileName {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
.dfAc {
  display: flex;
  align-items: center;
  gap: 6px;
}
.fileNotAvailable {
  color: #e09595;
}
.fileAvailable {
  color: #2c921f;
}
.uplodedFileName span.name {
  display: -webkit-box;
  width: 300px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}
.kycVerfyForm .formControl .MuiButtonBase-root.MuiCheckbox-root {
  color: #979797;
  padding: 4px;
  margin-left: 10px;
}
.kycVerfyForm
  .formControl
  .MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementEnd.checked {
  justify-content: flex-end !important;
  margin-left: 0px;
  color: #fff;
  align-items: flex-start;
}
.kycVerfyForm
  .formControl
  .MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementEnd {
  justify-content: flex-end !important;
  margin-left: 0px;
  color: #878787;
  align-items: flex-start;
}
.kycCerfyHeading {
  color: #ffffff;
  font-weight: 700;
  text-align: center;
  width: 100%;
  font-size: 24px;
  padding: 8px 4px;
  background-color: #171717;
}
