.tabs-container {
  width: 100%;
  position: relative;
  height: 30px;
}

.tabs::-webkit-scrollbar {
  width: 0px !important;
  height: 0px !important;
}
.tabs::-webkit-scrollbar-thumb {
  background-color: rgba(107, 107, 107, 1);
}
.tabs::-webkit-scrollbar-track {
  background: rgb(18 18 18 / 70%);
}

.tabs {
  display: flex;
  width: calc(100% - 140px);
  margin-left: 70px;
  margin-right: 70px;
  height: 30px;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none;
  overflow: hidden !important;
  overflow-x: scroll !important;
}
.tabsText {
  display: -webkit-box;
  min-width: 60px;
  width: 100%;
  max-width: 90px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: #fff;
  opacity: 1;
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.14px;
}

.hcFixed .tabs .TabWrapper:nth-child(3) .tab .close,
.hcFixed .tabs .TabWrapper:nth-child(2) .tab .close,
.hcFixed .tabs .TabWrapper:nth-child(1) .tab .close {
  display: none;
}

.tab {
  min-width: 90px;
  height: 100%;
  max-height: 22px;
  padding: 4px 12px;
  cursor: pointer;
  border-bottom: none;
  color: #ffffff;
  position: relative;
  transition: all 0.6s;
  background-color: #606060;
  display: flex;
  align-items: center;
  gap: 7px;
  font-size: 14px;
  text-transform: capitalize !important;
}
.tab::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 0;
  height: 0;
  border-left: 14px solid transparent;
  border-right: 0px solid transparent;
  border-top: 18px solid #333333;
  clear: both;
}

.tab-icon {
  height: auto;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tab-icon svg {
  height: 22px;
  width: auto;
}
.tab.active {
  background-color: #1c1c1c;
}

.close {
  margin-left: 8px;
  cursor: pointer;
}

.new-tab {
  padding: 2px 12px;
  cursor: pointer;
  border: 1px solid #434343;
  /* border-bottom: none; */
  color: #fff;
}
.tab-content {
  position: relative;
  width: 100%;
  height: calc(100vh - 80px);
  background: #1c1c1c;
  z-index: 1;
}
.hcFixed .tabs .TabWrapper:nth-child(2) .tab::after,
.TabWrapper:first-child .tab::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 0;
  height: 0;
  border-left: 14px solid transparent;
  border-right: 0px solid transparent;
  border-top: 18px solid transparent;
  clear: both;
}
.hcFixed .tabs .TabWrapper:nth-child(2) .tab,
.TabWrapper:first-child .tab {
  border-right: 1px solid #969696;
}

/* .closeTab {
  position: relative !important;
  top: 2px !important;
} */
.closeTab {
  position: relative !important;
  /* height: 18px;
  width: 18px; */
  background-color: #333333;
  border-radius: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
}
.close.closeTab .MuiSvgIcon-root {
  height: 14px;
  width: 14px;
}

.recording-indicator-circle {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  border: 2px solid;
  border-color: red;
  display: flex; /* Center the dot */
  justify-content: center;
  align-items: center;
}

.recording-indicator-dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: red;
  /* animation: blink 1s infinite; */
}

.recording-indicator-dot-blinking {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: red;
  animation: blink 1s infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.recording-indicator-circle title {
  font-family: "Inter ";
  font-size: 30px;
}
