.lp-full-container {
  padding: 0;
  margin: 0;
  /* height: 100%;
  width: 100%; */
  overflow: hidden;
  overflow-y: overlay;
  height: 100%;
  width: 100%;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  width: 0px !important;
  height: 6px !important;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(107, 107, 107, 1);
}
::-webkit-scrollbar-track {
  background: rgb(18 18 18 / 70%);
}
.top-navbar {
  background-color: transparent;
  color: #fff;
  padding: 0.5rem 1rem;
  transition: all 1s;
  position: absolute;
  width: calc(100% - 2rem);
  z-index: 2;
}
.top-navbar-fixed {
  background-color: #000;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  transition: all 1s;
}
.top-navbar-fixed .lpItems img {
  height: 60px;
  width: 60px;
}
.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.signInBtn .loginBtn {
  background-color: #ff9900;
  border-radius: 25px;
  padding: 6px 16px;
  cursor: pointer;
}

.navbar-logoLP {
  font-size: 1.5rem;
  display: none;
}

.menu-toggle {
  background: none;
  border: none;
  cursor: pointer;
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 24px;
  padding: 0;
  gap: 6px;
  transition: transform 0.3s ease;
}

.menu-toggle span:nth-child(2) {
  width: 80%;
}
.menu-toggle span {
  display: block;
  width: 100%;
  height: 2px; /* Adjust line thickness as needed */
  background-color: #333; /* Adjust color as needed */
}

/* Add animation for the 'open' state */
.menu-toggle.open span:nth-child(1) {
  transform: rotate(45deg) translate(6px, 8px);
}

.menu-toggle.open span:nth-child(2) {
  opacity: 0;
}

.menu-toggle.open span:nth-child(3) {
  transform: rotate(-45deg) translate(6px, -8px);
}

.navbar-links {
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 60px;
  width: 100%;
  padding-inline-start: 0px;
  margin: 6px auto;
}
.navbar-links .lpItems {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-size: 20px;
  text-transform: UPPERCASE;
}
.mobNavBtnGrp {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 14px;
}
.signBtnDesktop {
  display: list-item;
}
.signBtnMobile {
  display: none;
}
.signInBtn.signBtnMobile .loginBtn {
  background-color: #ff9900;
  border-radius: 25px;
  padding: 6px 16px;
  font-size: 16px;
  text-transform: uppercase;
  cursor: pointer;
}
.navbar-links.open {
  display: block;
}

.navbar-links li {
  margin-right: 1rem;
}

.navbar-links a {
  color: #fff;
  text-decoration: none;
}

.heroSectionLP {
  position: relative;
  width: 100%;
  height: 100vh; /* Adjust as needed */
  overflow: hidden;
}
.overlayScreen {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #00c4ff;
  z-index: 1;
  opacity: 0.3;
  -webkit-backface-visibility: hidden;
  overflow: hidden;
}

.heroSectionLP video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  transform: translate(-50%, -50%);
}
.appDownloadSec {
  height: 100vh;
  background-color: #1c1c1c;
  padding: 100px 240px 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.leftArea,
.rightArea {
  width: 50%;
}
.adnImgPresent {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.adnImgPresent img {
  max-height: 75vh;
}
.appDownloadInfo {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}
.adnTitle {
  font-size: 38px;
  font-family: Inter;
  color: #fff;
  line-height: 44px;
}
.adnTitle span {
  color: #ffbc00;
}
.adnText {
  font-size: 16px;
  line-height: 22px;
  color: #fff;
  font-family: Inter;
  width: 80%;
}
.adnLinkGrp {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 40px;
}
.adnLinkGrp a {
  text-decoration: none;
  color: #fff;
}
a .appStore {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  padding: 10px;
  border: 2px solid #fff;
  border-radius: 12px;
  width: 240px;
}
.appstoreIcon svg {
  width: auto;
  height: 48px;
}
.appstoreText .subHeadTxt {
  font-size: 16px;
  line-height: 20px;
  font-family: inter;
}
.appstoreText .headTxt {
  font-size: 30px;
  font-family: inter;
  line-height: 36px;
}
.googleStore .appstoreText .subHeadTxt {
  text-transform: uppercase;
}

.adnInfo {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 150px;
}
.adnInfoElement {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}
.adnInfoElement .elmtxt {
  font-size: 16px;
  font-family: Inter;
  color: #fff;
  line-height: 20px;
  font-weight: 500;
}
.adnInfoElement .highlighted {
  font-size: 38px;
  font-family: Inter;
  color: #fff;
  line-height: 50px;
}

.latestNewsSec {
  height: 100vh;
  background-color: #1c1c1c;
  padding: 100px 140px 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.latestNewsSecContainer {
  height: 100%;
  width: 100%;
}
.mapBg {
  background-image: url(../images/newsBG.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top center;
}
.lnSliderContainer {
  margin-top: 150px;
}
.sectionTitle {
  font-size: 38px;
  font-family: Inter;
  color: #fff;
  line-height: 44px;
  margin-top: 10px;
  margin-bottom: 20px;
}
.sectionTitle span {
  color: #ffbc00;
}
.lnSliderContainer .sliderElement {
  display: flex !important;
  align-items: flex-start !important;
  justify-content: flex-start !important;
  padding: 16px !important;
  background-color: #000 !important;
  border: 1px solid #545454 !important;
  margin: 0 10px !important;
  /* width: calc(100% - 32px); */
  gap: 10px !important;
  flex-direction: column !important;
  height: 400px !important;
}
.slidNewsImg {
  height: 100%;
  max-height: 230px;
  width: 100%;
}
.slidNewsImg img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.slidTitle {
  font-size: 24px;
  color: #fff;
  font-family: Inter;
  line-height: 28px;
  text-decoration: none;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 10px;
  margin-bottom: 12px;
}
.slidTxt {
  font-size: 14px;
  color: #fff;
  font-family: Inter;
  line-height: 22px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}
.slidPublishDT {
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
}

.lnSliderContainer .slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 12px;
  height: 12px;
  padding: 5px;
  border-radius: 12px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: #fff;
}
.lnSliderContainer .slick-dots li button:before {
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 12px;
  height: 12px;
  padding: 5px;
  content: none;
  border-radius: 12px;
  text-align: center;
  opacity: 1;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.lnSliderContainer .slick-dots li.slick-active {
  width: 40px;
}
.lnSliderContainer .slick-dots li.slick-active button,
.lnSliderContainer .slick-dots li.slick-active button:before {
  opacity: 1;
  background-color: rgb(255, 255, 255);
  width: 40px !important;
}

.lnSliderContainer .slick-dots li {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.lnSliderContainer .slick-dots {
  position: absolute;
  bottom: -60px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}
@media screen and (max-width: 767.98px) {
  .navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }
  .navbar-logoLP {
    font-size: 1.5rem;
    display: flex;
  }
  .menu-toggle {
    display: flex;
    align-items: center;
    justify-items: center;
  }

  .navbar-links {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #333;
    padding: 1rem 0;
  }

  .navbar-links.open {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    padding: 2rem 0;
    gap: 28px;
    /* width: 100vw; */
    /* height: 100vh; */
    /* left: -1rem; */
    /* top: 55px; */
    /* background-color: #111;*/
  }
  .signBtnMobile {
    display: block;
  }
  .signBtnDesktop,
  .lpItems.centerLogo {
    display: none;
  }
}

/* show funtion css start */
.showFuntionSec {
  height: 100vh;
  background-color: #1c1c1c;
  padding: 100px 40px 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}
.funtionDescribeText {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}
.fdtContainer {
  width: 22vw;
  height: 100%;
  margin-top: 60%;
  /* display: flex; */
  /* align-items: center; */
  /* flex-direction: column; */
}
.fdtContainer .fdtTitle {
  font-size: 38px;
  font-family: inter;
  line-height: 50px;
  color: #ffff;
  margin-bottom: 20px;
}
.fdtSubtitle {
  font-size: 18px;
  font-family: inter;
  line-height: 24px;
  color: #ffff;
}
.showFuntionContainer {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.device-mockup {
  position: relative;
  width: 55vw;
  padding-bottom: 61.775701%;
}
.device-mockup.imac {
  padding-bottom: 67vh; /*this is for laptop **/
  /* padding-bottom: 94vh; this is for desktop * */
}
.mb-small {
  margin-bottom: 20px !important;
}
.device-mockup.imac > .device {
  background-image: url(../images/imac.webp);
}

.device .screen {
  position: absolute;
  pointer-events: none;
  background-size: cover;
}

.device-mockup > .device > .screen {
  /* position: absolute;
  top: 11.0438729%;
  right: 13.364486%;
  bottom: 14.6747352%;
  left: 13.364486%; */
  top: 6.7%;
  right: 13.4%;
  bottom: 14%;
  left: 13.3%;
  /* this is for laptop */
  overflow: hidden;
}

.device-mockup.imac > .device > .screen {
  /* top: 8.20707071%;
  right: 6.61538462%;
  bottom: 31.6919192%;
  left: 6.61538462%; */
  top: 6.7%;
  right: 13.4%;
  bottom: 14%;
  left: 13.3%;
  /* this is for laptop
  */
}

.device-mockup > .device {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.device-mockup > .device > .screen img {
  width: 100%;
}
/* show funtion css end */

/* client css start */
.clientSec {
  height: 100vh;
  background-color: #1c1c1c;
  padding: 100px 140px 50px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
}
.ourClientContainer {
  height: 100%;
  width: 100%;
}
.cleintsDtlsWrap {
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 50px;
}
.clientInfo {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 50px;
  flex-direction: column;
  color: #fff;
}
.ciElemnt {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 2px;
}
.cliNumber {
  font-size: 4rem;
  line-height: 4rem;
  font-family: Inter;
}
.cliText {
  font-size: 14px;
  line-height: 14px;
  font-family: Inter;
}
.clientsLogos {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
/* client css end */

/* start login model */
.logRegFullContainer {
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: #000000;
  height: 100vh;
  width: 100vw;
  z-index: 10;
  overflow: hidden;
  color: #707070;
}

.closelrCon {
  position: absolute;
  right: 10px;
  top: 10px;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.closelrCon .MuiIconButton-root svg {
  color: #fff;
}
.logRegContain {
  height: 100%;
  width: 100%;
}
.lrContainer {
  margin: 0;
  padding: 0 100px;
  height: 100%;
  width: calc(100% - 200px);
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.lrLeftContain {
  width: 50%;
}
.lrRightContain {
  width: 50%;
}

.statLrImg {
  display: flex;
  align-items: center;
  justify-content: center;
}
.statLrImg img {
  height: 500px;
  width: auto;
}
.logRegForm {
  max-width: 550px;
}
.logRegForm .fromGroup {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 12px;
}
.logRegForm .lrHead {
  color: #ff9900;
  font-size: 3rem;
  font-family: Inter;
  margin-bottom: 20px;
}
.actionText {
  font-size: 16px;
  font-weight: 600;
  color: #79d3ff;
  cursor: pointer;
  line-height: 28px;
}
.lrButton.MuiButton-root.MuiButton-contained {
  width: 100%;
  height: 44px;
  font-size: 16px;
  font-family: Inter;
  line-height: 20px;
  color: #fff;
  background-color: #0092d9;
  margin-top: 30px;
}
.LogRegisterUser {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
}
.LogRegisterUser,
.fogetPass {
  margin-top: 4px;
}
.passwordPolocy {
  width: 100%;
  font-size: 16px;
  color: #fff;
  font-family: Inter;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 16px;
}
.meterWithText {
  width: 100%;
}
.meter {
  background-color: #ffffff26;
  border-radius: 5px;
  height: 10px;
  margin-top: 5px;
  width: 100%; /* adjust width as needed */
}

.meter-bar {
  height: 100%;
  border-radius: 5px;
  transition: width 0.6s ease;
}

.meter-text {
  margin-top: 4px;
}

.meter-status {
  font-weight: bold;
}
.passwrdPlcyCheckGrp {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2px;
}
.passwrdPlcyCheckHead {
  margin-bottom: 4px;
}
.passwrdPlcyCheck {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  margin-left: 16px;
}
.passwrdPlcyCheck span {
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.passwrdPlcyCheck .cancelIcon {
  color: #ff0000;
}
.passwrdPlcyCheck .checkIcon {
  color: #00ff33;
}

.lrButton.MuiButton-root.Mui-disabled {
  width: 100%;
  height: 44px;
  font-size: 16px;
  font-family: Inter;
  line-height: 20px;
  color: #fff;
  background-color: #7c7c7c;
  margin-top: 30px;
  cursor: not-allowed;
  pointer-events: painted;
}
/* end login model */

/* form ui css start */

.formControl {
  width: 100%;
  height: auto;
  margin: 2px 0px !important;
}
.formInputField {
  color: #ffffff !important;
  background-color: #292929 !important;
  /* width: 100%;
  height: 38px;
  padding: 10px 18px;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #707070;
  background: #333; */
}
.formControl .MuiOutlinedInput-notchedOutline {
  border: 1px solid #707070 !important;
  color: #ffffff;
}
.formControl .MuiFormLabel-root.MuiInputLabel-root {
  font-size: 14px;
  line-height: 34px;
  font-family: Inter;
  color: rgb(255 255 255 / 60%);
  transform: translate(14px, 4px);
}
.formControl .MuiAutocomplete-root.formAutocompleteField {
  color: #ffffff !important;
  background-color: #292929 !important;
}
.formAutoComInputField input.MuiInputBase-input.MuiOutlinedInput-input {
  padding: 8px 32px 8px 12px;
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  min-height: 1.4375em;
  height: 24px;
}
.formControl .MuiSelect-select.MuiSelect-outlined {
  padding: 8px 32px 8px 12px;
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.formAutocompleteField .MuiInputBase-root.MuiOutlinedInput-root {
  padding: 0px;
}
.formControl .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-shrink {
  background-color: transparent;
  color: #707070;
  transform: translate(14px, -10px) scale(0.75);
}
.formTextAreaField input {
  padding: 8px 32px 8px 12px;
  border-radius: 4px;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #ffffff !important;
  background-color: #292929 !important;
  height: 24px;
  /* border: 1px solid #707070 !important; */
}

.formTextFieldArea input {
  padding: 8px 32px 8px 12px;
  border-radius: 4px;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #ffffff !important;
  background-color: #292929 !important;
  height: 24px;
  /* border: 1px solid #707070 !important; */
}
.MuiSvgIcon-root.MuiSelect-icon {
  color: rgb(255 255 255 / 60%);
}

.crtTaskHeader {
  color: #fff;
  font-family: Inter;
  font-size: 28px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.MuiFormGroup-root.formRadioGroup {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
  padding-left: 10px;
}
.MuiFormControlLabel-root.formRadioField {
  color: #ffffff;
}
.MuiFormControlLabel-root.formRadioField svg {
  color: #b2b2b2;
  height: 20px;
  width: 20px;
}
.formElementGroup {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;
}
.mt40 {
  margin-top: 40px;
}
.comBtnOutline {
  border: 1px solid #707070 !important;
  color: #545454 !important;
}
.formElement {
  width: 100%;
}
.MuiInputBase-root.MuiOutlinedInput-root.formTextFieldArea.areaWbtn.padR0 {
  padding-right: 0px !important;
}
.MuiInputBase-root.MuiOutlinedInput-root.formTextFieldArea.areaWbtn,
.MuiInputBase-root.MuiOutlinedInput-root.formTextFieldArea.areaPassword {
  background-color: #292929;
}
.logRegForm .MuiInputAdornment-root .MuiButtonBase-root svg {
  color: rgb(255 255 255 / 60%);
}
.areaWbtn.padR0 .otpBtn {
  color: rgb(255 255 255 / 60%);
  background-color: #292929;
  padding-left: 10px;
  padding-right: 14px;
}
/* form ui css end */

/* footer css start */
.zoyelFooterContainer {
  padding: 100px 140px 50px;
  background: rgb(48, 48, 48);
  background: linear-gradient(
    180deg,
    rgba(48, 48, 48, 1) 0%,
    rgba(0, 0, 0, 1) 100%
  );
  border-top: 1px solid #707070;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 30px;
}

.zoyelFootElementContainer {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  gap: 100px;
}
.footerElement {
  width: calc(100% / 4);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 26px;
}
.footElmentTitle {
  color: #ff9900;
  font-size: 24px;
  font-family: Inter;
}
.footElmentText {
  font-family: inter;
  font-size: 16px;
  line-height: 22px;
  color: #fff;
}

.footElmentSocialLinks {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
}
.socialIconsBtn {
  cursor: pointer;
  color: #fff;
  transition: all 0.5s;
}
.socialIconsBtn:hover {
  cursor: pointer;
  color: #ff9900;
}
.socialIconsBtn svg {
  height: 24px;
  width: 24px;
}

.footQuickLinks {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 10px;
}
.quickLinksBtn {
  text-decoration: none;
  font-size: 16px;
  color: #fff !important;
  font-family: "Inter";
  cursor: pointer;
  transition: all 0.5s;
}
.quickLinksBtn:hover {
  color: #ff9900 !important;
}

.footElmentAddress p {
  margin: 0px;
  color: #fff;
  font-size: 16px;
  font-family: Inter;
}
.footContPhoneMail {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  flex-direction: column;
}
.footContPhoneMail a {
  text-decoration: none;
  font-size: 16px;
  color: #fff;
  line-height: 24px;
  cursor: pointer;
  transition: all 0.5s;
}
.footContPhoneMail a:hover {
  color: #ff9900;
}
.cpNm {
  font-weight: 600;
}
.footElmentForm {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  flex-direction: column;
}
.gentext {
  font-size: 16px;
  color: #fff;
  line-height: 24px;
  font-family: Inter;
}
.footnewsLetrFrm {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
input[type="email"].newsLetInput {
  width: 70%;
  height: 38px;
  border-radius: 0px;
  background-color: rgb(255 255 255 / 20%);
  color: rgb(255 255 255 / 80%);
  font-size: 16px;
  font-family: inter;
  padding: 4px 12px;
  outline: none;
  border: none;
}
button.newsSubmit {
  width: 30%;
  min-width: 90px;
  padding: 4px 10px;
  height: 46px;
  border-radius: 0px;
  outline: none;
  border: none;
  color: #fff;
  background-color: #ff9900;
  text-transform: uppercase;
  font-size: 16px;
  font-family: "Inter";
}
.zoyelFooterCopyright {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 8px;
}
.footerbrandLogo {
  height: 42px;
  width: 42px;
}
.footerbrandLogo img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.copyrightTxt a,
.copyrightTxt {
  font-size: 16px;
  color: #fff;
  font-family: inter;
  text-decoration: none;
  transition: all 0.5s;
}
.copyrightTxt a:hover {
  color: #ff9900;
}
/* footer css end */
.loginTabs-content,
.loginTabs {
  transition: all 0.6s;
}
.loginTabs-buttonsGrp {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  border-radius: 6px;
  margin-bottom: 20px;
}
.loginTabs-buttonsGrp .loginTabBtn {
  padding: 10px 16px;
  border: 0;
  background-color: #000000;
  width: calc(100% - 4px);
  color: #545454;
  font-size: 18px;
  line-height: 24px;
  font-family: Inter;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
  border: 1px solid #333;
}
.loginTabs-buttonsGrp .loginTabBtn svg {
  font-size: 28px;
  height: 30px;
}
.loginTabs-buttonsGrp .loginTabBtn.active {
  box-shadow: inset 4px 1px 8px rgb(36 36 36 / 60%),
    inset 2px 3px 11px rgb(0 0 0 / 66%),
    inset -3px -3px 4px rgb(101 101 101 / 45%),
    inset -2px -2px 10px rgb(81 81 81 / 45%);
  color: #ffffff;
  background-color: #333;
  opacity: 1;
}
.loginTabs-buttonsGrp .loginTabBtn:first-child {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.loginTabs-buttonsGrp .loginTabBtn:last-child {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.lrNotes {
  font-style: italic;
  font-size: 14px;
  color: #707070;
}
.lrNotes span {
  font-style: normal;
  font-weight: 700;
}
.funtionDtlSec {
  height: calc(100dvh - 180px);
  background-color: #111111;
  padding: 100px 70px 80px;
}
.FuntionSlider-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.FuntionSlider-container
  .slick-slider
  .slick-list
  .slick-track
  .slick-slide
  > div {
  height: calc(100% - 24px);
  margin: 12px;
  transition: all 0.8s;
}

.FuntionSlider-container .slick-slider .slick-list .slick-track {
  height: 100%;
}
.FuntionSlider-container .slick-slider .slick-list,
.FuntionSlider-container .slick-slider {
  width: 100%;
  height: 100%;
}
.indFuntionSlider {
  height: 100%;
  width: 100%;
  background-color: #333;
  border-radius: 26px;
  clip-path: inset(1px 1px 1px 1px round 26px);
}
.FuntionSlider-container
  .slick-slider
  .slick-list
  .slick-track
  .slick-slide:hover
  > div {
  margin: 8px;
  height: calc(100% - 16px);
}
.indFunSlidCtrl {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: calc(100% - 40px);
  gap: 10px;
  margin-right: 40px;
  padding: 20px 0px 0px;
}
.indFunSlidCtrl .MuiButtonBase-root.MuiIconButton-root {
  background-color: #545454;
  color: #fff;
}
.viewallBtncont {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -52px;
}

.MuiButtonBase-root.MuiButton-root.vabtn {
  position: relative;
  padding: 20px 50px;
  color: #fff;
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-size: 18px;
  font-weight: 500;
  border: 2px solid transparent;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
}

.MuiButtonBase-root.MuiButton-root.vabtn:hover {
  border: 2px solid #fff;
}

.MuiButtonBase-root.MuiButton-root.vabtn::before {
  content: "";
  position: absolute;
  inset: 0 8px;
  border-left: 2px solid #fff;
  border-right: 2px solid #fff;
  transition: 1s;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -ms-transition: 1s;
  -o-transition: 1s;
}

.MuiButtonBase-root.MuiButton-root.vabtn::after {
  content: "";
  position: absolute;
  inset: 8px 0;
  border-top: 2px solid #fff;
  border-bottom: 2px solid #fff;
  transition: 1s;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -ms-transition: 1s;
  -o-transition: 1s;
}

.MuiButtonBase-root.MuiButton-root.vabtn:hover {
  border-color: transparent;
}
.MuiButtonBase-root.MuiButton-root.vabtn:hover::before,
.MuiButtonBase-root.MuiButton-root.vabtn:hover::after {
  inset: 0px;
}
.funtionViewPoint {
  height: calc(100% - 32px);
  width: calc(100% - 32px);
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px;
}
.funtionContent {
  width: 100%;
  height: 100%;
  position: relative;
}
.funImgContainer {
  bottom: 0;
  top: -16px;
  left: 50%;
  right: 50%;

  transform: translateX(-50%);
  position: absolute;
  margin: 0;
  padding: 0;
  width: calc(100% + 32px);
  height: calc(100% + 32px);
}
.funImgContainer .funPic {
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
}
.funContainDtl {
  position: relative;
  z-index: 2;
}
.funImgContainer .funPic img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.ipmenu {
  cursor: pointer;
}
.featuresLandingPage {
  height: 100dvh;
  min-height: 100dvh;
  background-color: #111111;
  position: relative;
}
.diagonal-background {
  width: 100%;
  height: 90%;
  background-color: #1c1c1c;
  position: absolute;
  top: 0;
  left: 0;
  clip-path: polygon(0 0, 100% 0, 100% 10%, 0% 100%);
}
.digContnr {
  z-index: 1;
  position: relative;
  height: 100vh;
}
.zoyelHeadingContain {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 20px;
  top: 50%;
  transform: translateY(-50%);
}
.fetursHeading {
  font-size: 32px;
  font-weight: 800;
  line-height: 36px;
  color: #fff;
  letter-spacing: 0.5px;
}
.fetursSubtxt {
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  color: #fff;
  letter-spacing: 0.5px;
}
.funContainDtl {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 6px;
  margin-top: 6px;
}
.funContainDtl.light {
  color: #fff;
}
.funContainDtl.dark {
  color: #000000;
}
.funName {
  font-size: 28px;
  line-height: 30px;
  font-weight: 600;
}
.fundtel {
  font-size: 14px;
  font-weight: 400;
  opacity: 0.9;
}
.featuresDetailsPage {
  padding: 100px 140px 50px;
  display: flex;
  min-height: 100vh;
  background-color: #111111;
}
.featuresDetailsSect {
  padding: 100px;
  height: 75vh;
}
.featuresDetailsSect:nth-child(odd) {
  background-color: #1c1c1c;
}
.col21 {
  background-color: #00ff33;
}
.col31 {
  background-color: #ff9900;
}
div.stickyLeft {
  position: sticky;
  top: 95px;
  padding: 50px 20px;
  font-size: 20px;
  box-shadow: 12px 0px 20px -24px #343434;
  height: calc(100dvh - 200px);
}
.zoyelFeatureList ul {
  list-style-type: none;
  padding-inline-start: 10px;
}
.zoyelFeatureList li {
  padding: 4px;
  margin-bottom: 10px;
}
.zoyelFeatureList li a {
  text-decoration: none;
  color: #fff;
  opacity: 0.6;
  font-size: 18px;
  letter-spacing: 1px;
  font-weight: 400;
  padding-left: 6px;
}
.zoyelFeatureList li.active a {
  font-weight: bold;
  opacity: 1;
}
.zoyelFeatureList li.active {
  position: relative;
  height: 100%;
}
.zoyelFeatureList li.active::before {
  position: absolute;
  content: "";
  left: -6px;
  width: 4px;
  height: 80%;
  top: 50%;
  transform: translateY(-50%);
  background-color: #ff9900;
}
.fethead {
  color: #fff;
  font-size: 32px;
  line-height: 46px;
  font-weight: 700;
  margin-bottom: 30px;
}
.fethead span {
  font-weight: 400;
}
.fetContDtl {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 16px;
}
.fetText {
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  font-weight: 400;
  opacity: 0.8;
}

.fetContImg {
  width: 100%;
  height: 200px;
}
.fetContImg img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  filter: grayscale(100%);
}
.fetTextGroup {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.MuiButtonBase-root.MuiIconButton-root.viewFunDtlBtn {
  background-color: #ff9900;
  color: #fff;
  position: absolute;
  bottom: 0px;
  right: 0px;
  box-shadow: 2px 3px 12px -4px #240f00;
}
.mobile-menu {
  display: none;
}
.fixedOnTop {
  position: fixed;
  top: 0;
  width: calc(100% - 20px);
  left: 0;
  padding: 4px 4px 4px 16px;
  background-color: #272727;
  transition: background-color 0.6s ease;
  box-shadow: 0 2px 10px -8px #000;
}
.newTag {
  position: absolute;
  top: 2px;
  right: 2px;
  padding: 4px 10px;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  text-shadow: 2px 2px 4px #333333;
  border-radius: 4px;
  animation: changeBackgroundColor 8s infinite;
}

@keyframes changeBackgroundColor {
  0% {
    background-color: #001f3f;
  }
  50% {
    background-color: #ff9900;
  }
  100% {
    background-color: #001f3f;
  }
}
.disclamer-container {
  width: 100%;
  height: 40vh;
  overflow: hidden;
  overflow-y: overlay;
}
.disclamer-container::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
}

.disclamer-container::-webkit-scrollbar-thumb {
  background-color: rgba(107, 107, 107, 1);
}
.disclamer-container::-webkit-scrollbar-track {
  background: rgb(18 18 18 / 70%);
}
.disclamer-container p {
  font-size: 14px;
  font-weight: 400;
}
.disclamerFooter {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  justify-content: flex-start;
}
.disclamerCheckbox {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px;
}
.disclamerCheckbox span {
  font-size: 14px;
  font-weight: 600;
  color: #1c1c1c;
}
.navChangeLang {
  position: absolute;
  right: 70px;
}
.langChangeSelect {
  color: #ffffff !important;
  letter-spacing: 0.5px !important;
  background-color: #333333;
}
.top-navbar-fixed .langChangeSelect {
  color: #ffffff !important;
  letter-spacing: 0.5px !important;
  background-color: #333333;
}
.langChangeSelect .MuiSelect-select {
  padding: 8px 14px;
  padding-right: 32px;
}
