@media screen and (max-width: 1024px) and (min-width: 768px) {
  body {
    height: 100%;
    width: 100%;
    position: relative;
    margin: 0;
    padding: 0;
    /* overflow: hidden; */
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  #root {
    height: 100%;
    width: 100%;
    position: relative;
    box-sizing: border-box;
  }
  .lp-full-container {
    padding: 0;
    margin: 0;
    overflow: hidden;
    overflow-y: overlay;
    height: 100%;
    width: 100%;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  header.header {
    position: relative;
    box-sizing: border-box;
    display: block;
  }

  .header .tempContainer {
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-left: 16px !important;
    margin-right: 16px !important;
    width: calc(100% - 32px) !important;
  }

  /* start rasponsive nav */
  .navbar-container {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    position: relative !important;
  }
  .navbar-logoLP {
    font-size: 1.5rem !important;
    display: flex !important;
  }
  .menu-toggle {
    display: flex !important;
    align-items: flex-end !important;
    justify-items: center !important;
  }
  .mobNavBtnGrp .menu-toggle span {
    display: block;
    width: 100%;
    height: 2px;
    background-color: #f1f1f1;
  }

  .navbar-links {
    display: none !important;
    position: absolute !important;
    top: 100% !important;
    left: 0 !important;
    width: 100% !important;
    background-color: #333 !important;
    padding: 1rem 0 !important;
  }

  .navbar-links.open {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-end !important;
    justify-content: flex-start !important;
    padding: 2rem 0 !important;
    gap: 28px !important;
    /* width: 100vw; */
    /* height: 100vh; */
    /* left: -1rem; */
    /* top: 55px; */
    /* background-color: #111;*/
  }
  .signBtnMobile {
    display: block !important;
  }
  .signBtnDesktop,
  .lpItems.centerLogo {
    display: none !important;
  }
  .logRegContain .lrContainer {
    margin: 0;
    padding: 50px;
    height: calc(100% - 100px);
    width: calc(100% - 100px);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    row-gap: 50px;
  }
  .lrContainer .lrLeftContain,
  .lrContainer .lrRightContain {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .lrLeftContain .statLrImg img {
    height: 300px;
    width: auto;
  }
  .lrRightContain .logRegForm {
    max-width: 550px;
    width: 100%;
  }
  /* end responsive nav */
  .MuiGrid-root.MuiGrid-container.homeContainArea
    .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-7 {
    box-sizing: border-box;
    margin: 0;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 100%;
    overflow: hidden;
  }
  .homeContainerArea.MuiBox-root
    .homeContainArea
    .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-5 {
    box-sizing: border-box;
    margin: 0;
    flex-direction: row;
    flex-basis: 100%;
    flex-grow: 0;
    max-width: 100%;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  .tabs-container {
    width: 100%;
    position: relative;
    height: calc(100dvh - 50px) !important;
    overflow: hidden;
  }
  .tabs-container .tabs {
    display: flex;
    width: calc(100% - 32px);
    margin-left: 16px;
    margin-right: 16px;
    height: 30px;
    overflow: hidden;
    overflow-x: scroll;
  }
  .hmTabLayer .hmTabActvIndcator {
    display: none;
    width: 100%;
    height: 2px;
    position: absolute;
    bottom: 0px;
    background: #fff;
    transition: all 0.5s;
  }
  .homeContainArea .hmTabsContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    overflow-x: overlay;
    height: 52px;
  }
  .homeContainerArea .hmContWrkSpc {
    padding: 14px 0px 20px 16px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 20px;
    overflow: hidden;
    height: calc(100vh - 214px);
  }
  .taskCardContainer .contMxWidthLim {
    display: flex;
    padding: 22px 16px 22px 10px;
    align-items: flex-start;
    gap: 14px;
    width: 100%;
    max-width: none;
  }
  .dashboardChatContainer .chatConversationPannel,
  .dashboardTaskContainer .taskViewPannel {
    display: flex;
    flex-direction: row;
    height: 100% !important;
    overflow: hidden;
    position: relative;
    border-left: 0.1px solid #545454;
    background-color: #000;
  }
  .dashboardChatContainer .chatConvoContainer .chatConvstionConArea {
    box-sizing: border-box;
    overflow: hidden;
    overflow-y: hidden;
    flex: 0 1 auto;
    order: 2;
    height: calc(100% - 226px) !important;
  }
  .dashboardChatContainer .chatConversationPannel .chatConvoContainer,
  .taskViewPannel .taskViewContainer {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 80px) !important;
    max-height: calc(100vh - 80px);
    width: 100%;
    background-color: #000000;
    box-sizing: border-box;
    overflow: hidden;
  }
  .taskViewContainer .taskContain {
    order: 2;
    flex: 0 1 auto;
    padding: 15px 25px 10px;
    height: calc(100% - 226px) !important;
    box-sizing: border-box;
    overflow: hidden;
  }
  .taskContain .taskContainDetail {
    overflow: hidden;
    overflow-y: hidden;
    position: relative;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }
  .taskContainDetail .taskContDtlContainer {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 24px;
    padding-bottom: 65px;
    overflow: hidden;
    overflow-y: overlay;
    box-sizing: border-box;
    height: calc(100vh - 261px) !important;
  }

  .taskViewContainer .taskFooterPanel {
    order: 3;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 6px 24px 10px 24px;
    max-height: 65px;
  }
  .taskViewContainer .taskHeaderPanel {
    order: 1;
    display: flex;
    width: auto;
    padding: 16px 24px 16px 24px;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    background-color: #000;
    border-bottom: 1px solid #545454;
    max-height: 56px;
  }
  .tabs-container .tab-content {
    position: relative;
    width: 100%;
    height: calc(100vh - 80px);
    max-height: calc(100vh - 80px);
    background: #1c1c1c;
    z-index: 1;
    overflow: hidden;
    overflow-y: hidden;
  }
  .tab-content .homeContainerArea {
    height: calc(100vh - 80px);
    width: 100%;
    display: block;
    overflow: hidden;
  }
  .homeContainerArea .homeContainArea {
    height: 100%;
    width: 100% !important;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
  .hmContWrkSpc .mnuCompoContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 2px;
    height: 100%;
    overflow: hidden;
  }
  .mnuCompoContainer .mnuCompoIncomingInstanse {
    box-sizing: border-box;
    overflow: hidden;
    overflow-y: overlay;
    position: relative;
    width: 100%;
    height: calc(100vh - 104px);
    padding-bottom: 5px;
  }

  /* start funtion */
  section.showFuntionSec {
    height: auto;
    min-height: 70vh;
    background-color: #1c1c1c;
    padding: 150px 16px 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 100px;
    flex-direction: column;
    position: relative;
  }
  .showFuntionSec .showFuntionContainer {
    height: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .showFuntionSec .funtionDescribeText {
    height: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  .funtionDescribeText .zoomFeatures {
    position: absolute !important;
    left: 50px !important;
    right: auto !important;
    z-index: 1 !important;
    top: 215% !important;
    transition: all 0.6s ease 0s;
    height: 140px;
  }
  .connectWithZoom {
    position: absolute !important;
    right: auto !important;
    left: 25px !important;
    top: auto !important;
    bottom: -145px !important;
    transform: rotate(103deg) !important;
    transition: all 0.6s ease 0s !important;
    z-index: 1 !important;
    height: 130px !important;
  }
  .funtionDescribeText .fdtContainer {
    width: 50vw;
    height: 100%;
    margin-top: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .funtionDescribeText .fdtContainer .fdtTitle {
    font-size: 30px;
    font-family: inter;
    line-height: 34px;
    color: #ffff;
    margin-bottom: 20px;
  }
  .fdtContainer .fdtSubtitle {
    font-size: 16px;
    font-family: inter;
    line-height: 24px;
    color: #ffff;
  }
  .showFuntionContainer .device-mockup {
    position: relative;
    width: 88vw;
    padding-bottom: 61.775701%;
  }
  .showFuntionContainer .device-mockup.imac {
    padding-bottom: 390px;
  }
  /* end funtion */

  /* start app download  */
  section.appDownloadSec {
    height: auto;
    background-color: #1c1c1c;
    padding: 30px 16px 50px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 100px;
    flex-direction: column;
  }
  .appDownloadSec .leftArea,
  .appDownloadSec .rightArea {
    width: 100% !important;
  }

  /* end app download  */

  /* leatest news  start*/
  .lnSliderContainer .slick-slider .slick-prev,
  .lnSliderContainer .slick-slider .slick-next {
    display: none !important;
  }

  section.latestNewsSec {
    height: auto;
    background-color: #1c1c1c;
    padding: 100px 16px 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }
  .latestNewsSec .mapBg {
    background-position: center center;
  }
  .latestNewsSec .lnSliderContainer {
    margin-top: 100px;
  }
  /* leatest news  end*/

  /* start client */
  section.clientSec {
    height: auto;
    background-color: #1c1c1c;
    padding: 100px 16px 100px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 10px;
  }
  .clientSec .cleintsDtlsWrap {
    height: auto;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 50px;
  }
  .cleintsDtlsWrap .clientInfo {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 50px;
    flex-direction: row;
    color: #fff;
  }
  .clientsLogos .hexagon-gallery {
    margin: 0 auto;
    /* margin-top: 50px; */
    /* width: 100%; */
    display: grid;
    /* align-items: flex-start; */
    /* justify-content: flex-start; */
    /* flex-wrap: wrap; */
    grid-template-columns: repeat(13, 0fr);
    grid-auto-rows: 82px;
    grid-gap: 6px;
    padding-bottom: 50px;
  }
  .hexagon-gallery .hex {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 7px;
    width: 90px;
    height: 110px;
    background-color: #ffffff;
    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  }
  .hex .cleintLogo {
    height: 70px;
    width: 70px;
  }
  /* end client */

  /* start footer */
  .lp-full-container .zoyelFooterContainer {
    padding: 70px 16px 50px;
    background: rgb(48, 48, 48);
    background: linear-gradient(
      180deg,
      rgba(48, 48, 48, 1) 0%,
      rgba(0, 0, 0, 1) 100%
    );
    border-top: 1px solid #707070;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 30px;
  }
  .zoyelFooterContainer .zoyelFootElementContainer {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 30px;
    flex-wrap: wrap;
  }
  .zoyelFootElementContainer .footerElement {
    width: calc((100% - 40px) / 2);
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 10px;
  }
  /* end footer */

  /* personal login start */
  .flotingProfilecontainer {
    height: calc(100vh - 80px);
    width: 100vw !important;
    position: absolute;
    bottom: 0px;
    right: 0px;
    background-color: #000000;
    transition: all 0.6s;
    animation: profileFloting 1s ease forwards;
    overflow: hidden;
    z-index: 999;
  }
  .botContContain .botContContainDetails {
    overflow: hidden;
    overflow-y: overlay;
    position: relative;
    width: 100%;
    height: calc(100vh - 235px);
    padding-bottom: 70px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
    box-sizing: border-box;
  }
  .botFlotingContainerArea {
    height: calc(100% - 80px) !important;
    width: 100vw !important;
    position: absolute;
    bottom: 0px;
    right: 0px;
    background-color: #000000;
    z-index: 99;
    overflow: hidden;
  }
  .botFlotingContainer .botContHeader {
    order: 1;
    display: flex;
    width: auto;
    padding: 16px 24px 16px 24px;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    background-color: #000;
    border-bottom: 1px solid #545454;
    max-height: 61px;
  }
  .botFlotingContainer .botContFooter {
    order: 3;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 6px 24px 10px 24px;
    /* border-top: 1px solid #5454548a; */
    max-height: 60px;
  }
  .botFlotingContainerArea .botFlotingPanel {
    display: flex;
    flex-direction: row;
    height: 100%;
    overflow: hidden;
    position: relative;
    border-left: 0.1px solid #545454;
    background-color: #000;
  }
  .botFlotingContainerArea .botContContain {
    order: 2;
    flex: 0 1 auto;
    padding: 15px 25px 10px;
    height: calc(100% - 146px);
    overflow: hidden;
  }

  .ModelBox.contactsModel {
    width: 90vw !important;
    max-height: 80vh;
    overflow: hidden;
    overflow-y: overlay;
    padding: 20px 20px;
  }
  .subcriptionContainerArea .subcriptionContainer {
    padding: 40px 20px 40px 20px;
    width: calc(100% - 40px);
    height: calc(100vh - 160px);
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 10px;
    overflow: hidden;
    overflow-y: overlay;
  }
  .showActivePlan .persoanlPlanContainer {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
    flex-direction: column;
  }
  .persoanlPlanContainer .otherPlanShow,
  .persoanlPlanContainer .personalPlanView {
    width: 100%;
    height: 100%;
  }
  .showActivePlan .profesionalPlanContainer {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
    flex-direction: column;
  }
  .profesionalPlanContainer .profesionalPlanView {
    width: 100%;
    height: 100%;
    order: 1;
  }
  .profesionalPlanContainer .proOtherPlanShow {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100%;
    order: 2;
  }
  /* personal login end */
  .RightFlotingContainerArea {
    height: calc(100vh - 80px);
    width: 100vw !important;
    position: absolute;
    bottom: 0px !important;
    right: 0px;
    background-color: #000000;
    z-index: 99;
  }
  .tab-content .AdminWorkSpace {
    padding: 10px 16px 10px 16px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 12px;
  }
  .tab-content .AnalyticsDashboardContainer {
    padding: 10px 16px 10px 16px;
    width: calc(100% - 32px);
    height: calc(100vh - 100px);
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 10px;
    overflow: hidden;
  }
  .cuContainArea .cuCardList .cardContent {
    width: calc((100% - 24px) / 2);
    max-width: 100%;
    border-radius: 8px;
    background-color: #333333;
    box-shadow: 2px 3px 3px -1px #141414;
    color: #b2b2b2;
    font-family: Inter;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    gap: 2px;
  }
  .AdminRightFlotingContainer {
    height: calc(100vh - 80px);
    width: 100vw !important;
    position: absolute;
    bottom: 0px;
    right: 0px;
    background-color: #000000;
    transition: all 0.6s;
    /* animation: schFloting-appear 1s ease forwards; */
    overflow: hidden;
    z-index: 999;
  }
  .AnalyticsDashboardContainer .anDashCardContainArea {
    height: auto;
    width: 100%;
    overflow-y: overlay;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }
  .anDashCardContainArea .anDashIndCard {
    width: calc((100% - 20px) / 2);
    height: auto;
  }
  .selectTemplateContainerArea .selectTemplateContainer {
    padding: 20px 16px 10px 16px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 20px;
    overflow: hidden;
    height: calc(100vh - 185px);
  }
  .selectTemplateContainer .indTemplateCardGroup {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;
  }
  .indTemplateCardGroup .indTemplateCard {
    width: calc((100% - 110px) / 2);
    overflow: hidden;
    padding: 20px;
    background-color: #000;
    border: 1px solid #545454;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 20px;
  }
  .RightFlotingFormContainerArea {
    height: calc(100vh - 80px);
    width: 100vw !important;
    position: absolute;
    bottom: 0px;
    right: 0px;
    background-color: #000000;
    z-index: 99;
  }
  .schedulerContainerArea .SchduleRightFlotingContainer {
    height: calc(100vh - 80px);
    width: 100vw !important;
    position: absolute;
    bottom: 0px;
    right: -40vw;
    background-color: #000000;
    transition: all 0.6s;
    animation: schFloting-appear 1s ease forwards;
    overflow: hidden;
    z-index: 999;
  }
  .schedulerContainerArea .schedulerContainer {
    padding: 10px 16px 10px 16px;
    width: calc(100% - 32px);
    height: calc(100vh - 100px);
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 10px;
    overflow: hidden;
  }
  .schedulerContainer .schedulerTopBtnGroup {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 10px;
    flex-direction: column;
    width: 100%;
  }
  .selectCalenderGrp {
    display: none !important;
  }
  .schedulerTopBtnGroup .schdulerTopBtnRight {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
  }
  .formDatePick
    .react-datepicker-wrapper
    .react-datepicker__input-container
    input {
    height: 40px;
    outline: none;
    border: none;
    padding-block: 0px;
    padding: 0px 32px 0px 16px;
    border-radius: 4px;
    color: #ffffff;
    background-color: #545454;
    font-size: 16px;
    font-family: Inter;
    width: 100px !important;
  }
  .schdulerTopBtnLeft .formElement {
    width: 100%;
    min-width: 150px;
  }
  .schedulerLeagents:hover .legLabel {
    display: none !important;
  }
  .schedulerContainerArea .SchduleRightFlotingContainerSettings {
    height: calc(100vh - 80px);
    width: 100vw;
    position: absolute;
    bottom: 0px;
    right: -40vw;
    background-color: #000000;
    transition: all 0.6s;
    animation: schFloting-appear 1s ease forwards;
    overflow: hidden;
    z-index: 999;
  }
  .taskContainerArea .taskContainer {
    padding: 10px 16px 10px 16px;
    width: calc(100% - 32px);
    height: calc(100vh - 100px);
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 10px;
    overflow: hidden;
  }

  .taskContainer .taskListTableContainer {
    height: calc(100vh - 275px);
    width: 100%;
    overflow: overlay;
    overflow-y: overlay;
    border-bottom: 1px solid #545454;
  }
  .taskListTableContainer
    table.taskListTable
    .taskListtableHeader
    tr
    th:nth-child(4) {
    min-width: 120px;
  }
  .taskListTableContainer
    table.taskListTable
    .taskListtableHeader
    tr
    th:nth-child(8) {
    min-width: 140px;
  }
  .taskListTableContainer
    table.taskListTable
    .taskListtableHeader
    tr
    th:nth-child(9) {
    min-width: 170px;
  }
  table.taskListTable .scrolableTableBody tr {
    height: 38px;
  }
  .taskContainer .taskListHeader {
    color: #fff;
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    cursor: pointer;
  }
  .chatContainerArea {
    height: 100%;
    width: 100%;
    display: block;
    overflow: hidden;
  }
  .chatContainArea {
    overflow: hidden;
  }
  .lftChatGrid {
    width: 100% !important;
    box-sizing: border-box;
    margin: 0;
    flex-direction: row;
    flex-basis: 100% !important;
    flex-grow: 0;
    max-width: 100% !important;
  }
  .lftChatGrid.showLftgrid {
    display: none !important;
  }
  .rftChatGrid {
    display: none;
    position: absolute;
    width: 100% !important;
    box-sizing: border-box;
    margin: 0;
    flex-direction: row;
    flex-basis: 100% !important;
    flex-grow: 0;
    max-width: 100% !important;
  }
  .rftChatGrid.showRftgrid {
    display: flex !important;
    height: 100%;
    width: 100%;
    overflow: hidden;
  }
  .chatConvoUserInfo .mobChatBack {
    display: flex;
  }
  .chatConvstionConArea .chatConvoMsg {
    height: calc(100% - 20px);
    width: 100%;
    overflow: hidden;
    overflow-y: overlay;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 10px 16px 10px 16px;
  }
  .chatContactPanel .chatLftContHead {
    display: flex;
    width: auto;
    padding: 10px 16px 10px 16px;
    align-items: center;
    gap: 24px;
    background: #1c1c1c;
  }
  .contactListGroup .chatContactList {
    display: flex;
    width: calc(100% - 16px);
    padding: 0px 0px 0px 16px;
    align-items: center;
    gap: 15px;
    cursor: pointer;
    transition: all 0.5s;
    position: relative;
  }
  .createGroupCont .crtBtnGrp {
    position: relative;
    display: flex;
    flex: none;
    align-items: center;
    height: 72px;
    padding-bottom: 1px;
    color: #fff;
    transition: all 0.5s;
    padding: 0 0 0 16px;
    gap: 15px;
  }
  .chatCnctLstCont .chtsrchUsr {
    position: relative;
    z-index: 100;
    box-sizing: border-box;
    display: flex;
    flex: none;
    align-items: center;
    height: 50px;
    padding: 0 16px 0 16px;
  }
  .srchUsrIpG .searchUser {
    display: flex;
    width: calc(100% - 18px);
    padding: 5px 9px;
    align-items: center;
    gap: 20px;
    border-radius: 8px;
    background: #272727;
  }
  .cNwGrpImgNmContener .cNwGrpImgNmArea {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    margin: 20px 16px 5px 16px;
    flex: none;
    flex-grow: 1;
    flex-basis: auto;
    flex-shrink: 0;
    gap: 30px;
  }
  .rftChatGrid .chatConversationPannel {
    display: flex;
    flex-direction: row;
    height: calc(100% - 96px);
    overflow: hidden;
    position: relative;
    border-left: 0.1px solid #545454;
    background-color: #1c1c1c;
    width: 100%;
  }
  .rftChatGrid .chatConvoContainer.hideWhenUseInDashboard {
    display: none;
  }
  .chatFrwUsrLstContainer,
  .chatGrpDtlContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    max-width: 100% !important;
    background-color: #000000;
    border-left: 0.2px solid #545454;
  }
  .chatConvoContainer .chatConvoHeader {
    display: flex;
    width: auto;
    padding: 10px 16px 10px 16px;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    background: #1c1c1c;
    order: 1;
    max-height: 66px;
  }
  .upldFlSmalPerview .dropToUupledImg {
    display: none;
  }
  .chatConvoContainer {
    display: flex;
    flex-direction: column;
    height: 100% !important;
    width: 100%;
    background-color: #000000;
  }
  .chatConvoContainer .chatConvstionConArea {
    height: calc(100% - 126px) !important;
    box-sizing: border-box;
    overflow: hidden;
    overflow-y: hidden;
    flex: 0 1 auto;
  }
  .dashboardChatContainer .chatConvoFooter {
    background-color: #000;
    border-top: 1px solid #545454;
    max-height: 61px;
  }
  .chatConvoContainer .chatConvoFooter {
    display: inline-flex;
    padding: 5px 24px 5px 16px;
    align-items: center;
    gap: 16px;
    order: 3;
    height: 50px;
  }
  /* .chatConvoContainer .chatConvoFooter {
    position: absolute;
    width: calc(100% - 86px);
    bottom: 0px;
    z-index: 1;
  } */
  .zoeyBotInc {
    z-index: 1024;
    position: fixed;
    bottom: 140px !important;
    height: 64px;
    width: 64px;
    border-radius: 32px;
    background-color: #fff;
    right: 20px;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  /* mail responsive start */
  .emailContainer .mobLftEmail {
    display: none;
  }
  .emailContainer .rightEmailContain.mobRgtEmail {
    padding-right: 0px;
  }
  .rightMailTopbar .emailMenuButton {
    display: block;
  }
  .emailMenuButton .MuiButtonBase-root.MuiIconButton-root {
    height: 36px;
    width: 36px;
    background-color: #323232;
  }
  .emailMenuButton .MuiButtonBase-root.MuiIconButton-root svg {
    color: #b2b2b2;
    font-size: 20px;
  }
  .rightEmailContain .rightMailTopbar {
    padding: 10px;
    height: 36px;
    border-left: 0.5px solid #545454;
    border-bottom: 0.5px solid #545454;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2px;
  }
  .paginationMail {
    width: 40%;
  }
  .emailContainer .rightEmailContain.mobRgtEmail.hideMobRgtEmail {
    display: block !important;
  }
  .emailContainer .leftEmailMenuBar.mobLftEmail.showMobLftEmail {
    display: flex;
    width: 50vw;
    position: absolute;
    height: 100%;
    background-color: #1c1c1c;
    z-index: 1;
    box-shadow: 3px 0px 20px -2px #1c1c1c;
  }
  .showMobLftEmail .leftEmailMenu {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: 100%;
    max-width: 100%;
    width: 100%;
    background-color: #121212;
    padding-left: 0px;
  }
  .showMobLftEmail .menuTypeMail,
  .showMobLftEmail .menuComposeMail {
    padding: 10px;
    padding-left: 6px;
  }
  .showMobLftEmail .mailType {
    display: flex;
    font-size: 14px;
    padding: 5px 16px;
    height: 28px;
    align-items: center;
    border-radius: 4px;
    transition: all 0.5s;
    cursor: pointer;
    opacity: 0.7;
    margin-bottom: 1px;
  }
  .emailContainerArea .composeMailArea {
    visibility: hidden;
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    top: auto;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 9999;
  }
  .composrMailPesition .composeMailStart {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: auto;
  }
  .composeMailArea .composeMailContainer {
    width: 100vw;
    height: 100vh;
    order: 1;
    z-index: 1000;
    float: right;
  }
  .composeMailContainer .composrMailPesition {
    width: 100vw;
    position: relative;
    height: 100vh;
    min-height: 1px;
    float: right;
  }
  .composrMailPesition .react-multi-email [data-tag] {
    vertical-align: baseline;
    margin: 0.1em;
    background-color: #f3f3f3;
    background-image: none;
    padding: 2px 6px;
    color: rgba(0, 0, 0, 0.6);
    text-transform: none;
    font-weight: 600;
    border: 0 solid transparent;
    border-radius: 0.2rem;
    transition: background 0.1s ease;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 100%;
    color: #000;
  }
  .compMailSubject input[type="text"] {
    color: #000;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
  }
  .compMailSubject input::placeholder {
    color: #333;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
  }
  .composrMailPesition .react-multi-email > span[data-placeholder] {
    display: block;
    position: absolute;
    left: 0px;
    font-size: 12px;
    top: 12px;
    padding: 0em;
    line-height: 10px;
    color: #000000;
  }
  .viewEmlAtchFile .atfilNm {
    width: 80px !important;
  }
  .menuComposeMail {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .MuiButtonBase-root.MuiIconButton-root.mobBackToMailList {
    display: inline-flex !important;
  }
  .MuiButtonBase-root.MuiIconButton-root.mobBackToMailList svg {
    color: #b2b2b2;
  }
  .mailListGroup .mailListInd {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 0.5px solid #545454;
    padding: 14px 4px;
    cursor: pointer;
    gap: 4px;
  }

  .mobRgtEmail .mailIndIconsGroup {
    display: flex;
    width: 80px;
    flex-basis: 80px;
    max-width: 80px;
    order: 1;
  }
  .mobRgtEmail .mailUserName {
    flex-basis: calc(80vw - 110px);
    min-width: calc(80vw - 110px);
    max-width: calc(80vw - 110px);
    order: 2;
    padding: 5px 0px;
  }
  .mobRgtEmail .mailTextInfo {
    margin-left: 88px;
    display: flex;
    flex: 1 1 auto;
    height: auto;
    flex-basis: 85vw;
    width: 85vw;
    max-width: 85vw;
    order: 5;
    min-width: 100px;
  }
  .mobRgtEmail .mailTime {
    letter-spacing: normal;
    flex-basis: 13vw;
    width: 13vw;
    max-width: 13vw;
    justify-content: flex-end;
    padding-left: 0;
    padding-right: 1px;
    order: 3;
  }
  .mobRgtEmail .mailDltIcon {
    order: 4;
    opacity: 0.7;
    transition: all 0.6s;
    flex-basis: 5vw;
    width: 5vw;
    max-width: 5vw;
  }
  .emailContainer .rightEmailViewContain.mobRgtEmail.showMobRgtEmail {
    padding-right: 0px !important;
    overflow-y: overlay;
  }
  .rightEmailViewContain .emailViewContainer {
    padding: 0 0 0 0px;

    height: 100%;
    width: 100%;
  }
  .rightEmailViewContain .emailViewContainer .emailViewArea {
    max-height: inherit;
    overflow: unset;
    overflow-y: unset;
    height: auto;
    width: 100%;
  }
  .rightEmailViewContain .mlbistr {
    display: flex;
    flex-direction: column;
  }
  .rightEmailViewContain .sndtd {
    text-align: right;
    white-space: nowrap;
    vertical-align: top;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-height: 20px;
    font-size: 12px;
  }
  .rightEmailViewContain .emailTitle {
    display: flex;
    display: -webkit-flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
  }
  .rightEmailViewContain .emailSubject {
    font-size: 18px;
    line-height: 28px;
    font-weight: 500 !important;
    margin-left: 6px;
  }
  .emailTitle .emailmarker .MuiButtonBase-root.MuiCheckbox-root {
    padding: 2px 8px;
  }
  .emailViewArea .mlinsdDtlwa {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin: 0px 0px 20px 6px;
  }
  .emailViewArea .MuiCollapse-wrapperInner .MuiAccordionDetails-root {
    padding: 8px 10px 10px;
  }
  .emailViewArea .emailReplayArea {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 16px;
    gap: 4px;
    width: 100%;
  }
  .emailViewArea .emailReplayArea .emlrlpyBtn {
    padding: 4px 8px;
  }
  .emlRplyContainer .mlbiImg {
    display: none;
  }
  .emlRplyContainer .showMlSummry {
    padding: 0px;
    min-height: 46px !important;
  }
  .emlRplyContainer .mlreplyBdy .mlinsdDtlwa {
    background: #fff;
    padding: 10px;
    padding-top: 10px;
    box-shadow: 0px 2px 7px -3px #00000069;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    margin: 0px 0px 20px 0px;
  }

  .mlinsdDtlwa .mlshmlt {
    display: none;
  }
  .composeMailStart .composeMailBody {
    min-height: 350px;
  }
  .composeMailBody .textAreaAutosize {
    height: 40vh !important;
    width: calc(100% - 8px);
    border: 1px solid rgba(34, 36, 38, 0.15);
    outline: none !important;
    font-family: Inter;
    font-size: 14px;
    padding: 4px;
  }
  /* mail responsive end */

  .cuContainArea .TableContainer {
    height: 100%;
    width: 100%;
    padding: 0px;
    overflow: overlay;
  }
  .driveContainArea .driveContainer {
    padding: 0px 16px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 10px;
    overflow: hidden;
    height: calc(100vh - 80px);
  }

  /* meeting start */
  .meetingsTabContainerArea .meetingsShowAreaContain {
    padding: 10px 16px 10px 16px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    height: calc(100vh - 100px);
    width: calc(100% - 32px);
    overflow: hidden;
  }
  .meetingControlBtnGroup .meetingTimer {
    position: fixed;
    left: 6px;
    bottom: -2px;
    z-index: 1024;
  }

  .meetingsTabContainArea
    .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-4.fullWidth100,
  .meetingsTabContainArea
    .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-6.fullWidth100 {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 100%;
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
    z-index: 1025;
  }
  .MeetingTranscriptionContainer .Meeting_Transcription {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    overflow: hidden;
    overflow-y: hidden;
    padding: 10px 16px 10px 16px;
    box-sizing: border-box;
    height: 100%;
  }
  .Meeting_Transcription .transcriptionContainArea {
    width: 100%;
    color: #ffffff;
    opacity: 0.8;
    font-size: 14px;
    font-family: Inter;
    font-weight: 400;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 24px;
    padding-bottom: 65px;
    overflow: hidden;
    overflow-y: overlay;
    box-sizing: border-box;
    height: calc(100vh - 261px) !important;
  }
  .metRightInfoContainer .meetRightHeader {
    order: 1;
    display: flex;
    width: auto;
    padding: 16px 24px 16px 24px;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    background-color: #000;
    border-bottom: 1px solid #545454;
    max-height: 56px;
  }
  .metRightInfoContainer .MeetingTranscriptionContainer {
    order: 2;
    flex: 0 1 auto;
    padding: 15px 25px 10px;
    height: calc(100% - 226px) !important;
    box-sizing: border-box;
    overflow: hidden;
  }
  .Meeting_Transcription iframe {
    height: 90vh !important;
    width: 100vw !important;
  }
  .metRightInfoContainer .MeetRightFooter {
    order: 3;
    display: inline-flex;
    padding: 10px 16px 10px 16px;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    background-color: #000;
    border-top: 1px solid #545454;
    max-height: 65px;
  }

  .e-header-row .cell_Date_Days {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    flex-direction: column;
    padding-left: 10px;
    height: 56px;
  }
  .schedule-overview.e-schedule .date-text {
    font-size: 12px;
    display: -webkit-box;
    max-width: 82px;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    color: #fff;
    opacity: 0.6;
    text-overflow: ellipsis;
  }
  .schedulerContainer .SchedulerHeader {
    color: #fff;
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    cursor: pointer;
  }
  /* meeting end */
  .eventAlertWrapper {
    z-index: 1;
    position: absolute;
    top: -100%;
    left: 50%;
    opacity: 0;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 10px 14px;
    border-radius: 8px;
    gap: 6px;
    animation: smooth-appear 1s ease forwards;
    transition: all 2s;
    width: max-content;
  }
  .AnalyticsDashboardContainer .AnalyticsDashboardHeader {
    color: #fff;
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    cursor: pointer;
  }

  .driveContainer .ReactTable .rt-tbody {
    flex: 99999 1 auto;
    display: flex;
    flex-direction: column;
    overflow: auto;
    overflow-y: overlay;
    min-height: calc(100vh - 340px);
    max-height: calc(100vh - 340px);
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  .driveContainer .ReactTable .rt-tbody .rt-tr-group {
    border-bottom: 1px solid #545454;
    height: 48px;
    transition: all 0.4s;
    max-height: 48px;
  }
  .driveContainer .drvfileActionBtnGrp {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    opacity: 0.5;
    transition: all 0.4s;
  }
  /* .driveContainer .ReactTable .rt-tbody .rt-td:last-child {
    border-right: 0;
    flex: 100 0 auto !important;
    width: 100px !important;
    max-width: 100px !important;
  }
  .driveContainer .ReactTable .rt-thead .rt-th:first-child {
    flex: 200 0 auto !important;
    width: 200px !important;
    max-width: 200px !important;
  } */
  .bottomMnuPopover {
    bottom: 110px !important;
  }
  .meetingsShowAreaContain .fullscreenmeeting {
    height: calc(100vh - 200px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 10px;
  }
  .meetingTabRightPanel .metRightInfoContainer {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 80px);
    max-height: calc(100vh - 80px);
    width: 100%;
    background-color: #000000;
    box-sizing: border-box;
    overflow: hidden;
  }
  .AdminRightFlotingContainer .flotingAreaClose {
    border-bottom: 1px solid #545454;
    padding-bottom: 10px;
    order: 1;
    max-height: 56px;
    flex: 0;
  }
  .AdminRightFlotingContainer .flotingAreaContainer .elementFormContainer {
    overflow: hidden;
    overflow-y: overlay;
    height: calc(100vh - 284px);
    max-height: calc(100vh - 284px);
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 14px;
    padding: 0px 16px 0px 16px !important;
    order: 2;
    flex: 0 1 auto;
    box-sizing: border-box;
  }
  .flotingAreaClose .facHeader {
    color: #fff;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.24px;
    padding: 0px 16px 0px 16px;
  }
  .AdminRightFlotingContainer .elementFooter {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 30px;
    border-top: 1px solid #545454;
    width: calc(100% - 60px);
    gap: 10px;
    flex-direction: row;
    max-height: 65px;
    order: 3;
  }
  .flotingAreaContainer {
    width: 100% !important;
    height: calc(100vh - 175px) !important;
    flex-shrink: 0 !important;
    display: flex !important;
    align-items: flex-start !important;
    justify-content: flex-start !important;
    flex-direction: column !important;
    gap: 10px !important;
    overflow: hidden !important;
    padding: 0px !important;
    box-sizing: border-box;
  }
  .cuContainArea .CriticalRightsContainer {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 60px;
    flex-direction: column;
  }
  .critiaclElement.wthP60,
  .critiaclElement.wthP40 {
    width: 100%;
    /* max-height: 50vh; */
  }
  .critContain {
    width: 100%;
    height: 100%;
  }
  .chatCnctLstCont .contactListGroup {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: hidden;
    overflow-y: overlay;
    position: relative;
    width: 100%;
    height: calc(100vh - 235px);
    padding-bottom: 10px;
    margin-top: 0px;
    box-sizing: border-box;
  }
  .metRightInfoContainer .ReactTable .rt-tbody {
    flex: 99999 1 auto;
    display: flex;
    flex-direction: column;
    overflow: auto;
    overflow-y: overlay;
    max-height: calc(100vh - 327px);
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  .chatConversationPannel .chatFrwUsrLstArea,
  .chatConversationPannel .chatGrpDtlArea {
    flex: 1 1;
    order: 2;
    position: relative;
    overflow: hidden;
    overflow-y: hidden;
    scrollbar-width: none;
    -ms-overflow-style: none;
    width: 100%;
    height: calc(100% - 106px);
    box-sizing: border-box;
  }
  .chatConversationPannel .frwContLstSlct,
  .chatConversationPannel .addGrpParticpent {
    flex: 1 1;
    position: relative;
    overflow: hidden;
    overflow-y: overlay;
    scrollbar-width: none;
    -ms-overflow-style: none;
    width: 100%;
    height: calc(100vh - 292px);
    padding-bottom: 10px;
    margin-top: 0px;
    box-sizing: border-box;
  }
  .chatConversationPannel .grpCretFooter {
    min-height: 60px;
    background-color: #323232;
    display: flex;
    align-items: center;
    justify-content: center;
    order: 3;
  }
  .chatFrwUsrLstHeader,
  .chatGrpDtlHeader {
    display: flex;
    width: auto;
    padding: 10px 16px;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    background: #1c1c1c;
    min-height: 46px;
    max-height: 66px;
    order: 1;
  }

  section.pageNotFoundContainer {
    height: 100% !important;
    background-color: #1c1c1c !important;
    padding: 100px 30px 100px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    gap: 10px !important;
    flex-direction: column !important;
  }
  .pageNotFoundContainer .rightArea,
  .pageNotFoundContainer .leftArea {
    width: 100% !important;
  }
  .pageNotFoundContainer .adnText {
    width: 100%;
  }
  .pageNotFoundContainer .adnImgPresent img {
    max-height: 40vh;
    width: auto;
  }
  .rightShowDtlPanel {
    height: 100%;
    width: 100%;
    z-index: 1;
    position: relative;
  }
}
