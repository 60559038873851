.bottomMnuPopoverContainerMain .MuiPopover-paper {
  background-color: #323232;
  border-radius: 20px;
}
.bottomMnuPopoverContainer {
  display: flex;
  background-color: #323232;
  justify-content: space-between;
  color: white;
  min-width: 370px;
  padding: 37px 37px 10px 37px;
  max-width: 370px;
  /* min-height: 200px; */
  /* position: absolute; */
  /* bottom: 55px; */
  /* color: white !important; */
}

.apps,
.quick-actions {
  padding: 5px;
  border-radius: 5px;
  width: 48%;
}

.apps h2,
.quick-actions h2 {
  margin-top: 0;
}

.apps ul,
.quick-actions ul {
  list-style-type: none;
  padding: 0;
}

.apps li,
.quick-actions li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
}

.apps li img,
.quick-actions li img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.apps li .btmMenuAppsIcon,
.quick-actions li .btmMenuAppsIcon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.slideColor {
  color: #f44336;
}
.wordColor {
  color: #0078d7;
}
.sheetColor {
  color: #009688;
}

.bottomAppSearchOption {
  display: flex;
  align-items: center;
}

.bottomAppSearchOptionIcon {
  width: 20px; /* Adjust the width as needed */
  height: auto; /* This will maintain the aspect ratio */
  margin-right: 10px; /* Adds some spacing between the image and label */
}

.bottomAppSearchOptionTitle {
}
.autocompleteSearchField {
  /* background-color: #323232; */
}

.custom-autocomplete-paper::-webkit-scrollbar {
  width: 0px !important;
  height: 0px !important;
}

.custom-autocomplete-paper::-webkit-scrollbar-thumb {
  background-color: rgba(107, 107, 107, 1);
}

.custom-autocomplete-paper::-webkit-scrollbar-track {
  background: rgb(18 18 18 / 70%);
}
.custom-autocomplete-paper {
  max-height: 300px;
  overflow: auto;
}
