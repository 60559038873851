.ModelBox.contactsModel {
  width: 60vw;
  max-height: 80vh;
  overflow: hidden;
  overflow-y: overlay;
  padding: 20px 32px;
}
.MuiButtonBase-root.MuiIconButton-root.closeContactModelBtn {
  position: absolute !important;
  right: 10px;
  top: 10px;
  height: 36px;
  width: 36px;
  background-color: #ededed !important;
  color: #1c1c1c !important;
}
.addContactGroup {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
  width: 100%;
  margin-top: 20px;
}
.syncGoogleCont .googleContactsGrid {
  width: 128px;
  border-radius: 4px !important;
  border: 1px solid #b2b2b2 !important;
  color: #333 !important;
  box-shadow: none !important;
}
.syncGoogleCont .googleContactsGrid div:first-child {
  border-radius: 4px !important;
  padding: 4px !important;
  margin-left: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.addContactElementGrp {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  flex: 80%;
  order: 1;
}
.syncGoogleCont {
  order: 2;
  flex: 20% 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.dfultPrimaryBtn.primaryBtn.contactsBtn {
  min-width: 160px !important;
  max-width: 165px !important;
}
.formElement.with240 {
  max-width: 240px;
  width: 100%;
}

.contacts .ReactTable {
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid #54545400 !important;
  width: 100%;
  font-family: Inter;
}
.contacts .ReactTable .rt-tbody {
  height: 100% !important;
  min-height: 200px !important;
  max-height: calc(100vh - 60vh) !important;
}
.contacts .ReactTable .rt-thead .rt-tr {
  text-align: left;
}
.contacts .primaryBtn.inviteBtn {
  padding: 4px 12px !important;
  margin-left: 10px;
  border: 1px solid #b2b2b2;
  color: #606060;
  transition: all 0.6s;
}
.contacts .primaryBtn.inviteBtn:hover {
  background-color: #00adef;
  border: 1px solid #00adef;
  color: #ffffff;
}
.contacts .ReactTable .rt-tbody .rt-tr-group {
  border-bottom: 1px solid #e1e1e1 !important;
  height: 48px !important;
  transition: all 0.4s !important;
}
.contacts .ReactTable .rt-tbody .rt-tr-group:hover {
  background-color: #ffffff !important;
}
.contacts .rt-thead .rt-tr {
  border-bottom: 1px solid #9d9d9d;
}
.contacts .ReactTable .addedRTG {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
.AddContactMobBtn {
  display: none !important;
}
.contacts .ReactTable .addedRTG svg {
  font-size: 22px;
  color: #338528;
}
.contacts .ReactTable .rt-thead .rt-th.-sort-asc,
.contacts .ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: inset 0 3px 0 0 rgb(255 255 255);
}
.contacts .ReactTable .rt-thead .rt-th.-sort-desc,
.contacts .ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: inset 0 -3px 0 0 rgb(255 255 255);
}

.contacts .ReactTable .rt-thead .rt-th,
.contacts .ReactTable .rt-thead .rt-td {
  padding: 14px 10px;
  line-height: normal;
  position: relative;
  border-right: 2px solid rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  box-shadow: inset 0 0 0 0 transparent;
}

.contacts .ReactTable .rt-thead .rt-resizable-header-content {
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  font-family: "Inter";
  color: black !important;
}
.contacts .ReactTable .rt-tbody .rt-td {
  border-right: 1px solid rgba(0, 0, 0, 0.02);
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.contacts .ReactTable .-pagination .-btn {
  appearance: none;
  display: block;
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 8px;
  padding: 6px;
  font-size: 14px;
  color: black !important;
  background: white !important;
  transition: all 0.1s ease;
  cursor: pointer;
  outline-width: 0;
}
.contacts .ReactTable .rt-thead.-header {
  background-color: #dddddd;
}
.contacts .ReactTable .trashIconBtn svg {
  color: #ed3237;
}
.contacts .ReactTable .-pagination input,
.contacts .ReactTable .-pagination select {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: white !important;
  color: black !important;
  padding: 5px 7px;
  font-size: inherit;
  border-radius: 8px;
  font-weight: normal;
  outline-width: 0;
}
.contacts .contactsBtn {
}

.contacts .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
  color: rgba(0, 0, 0, 0.6);
  font-family: "Inter";
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  padding: 0;
  position: relative;
  display: block;
  transform-origin: top left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(133% - 32px);
  position: absolute;
  left: 0;
  top: 0;
  /* -webkit-transform: translate(14px, -9px) scale(0.75);
    -moz-transform: translate(14px, -9px) scale(0.75);
    -ms-transform: translate(14px, -9px) scale(0.75);
    transform: translate(14px, -9px) scale(0.75);
    -webkit-transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
      -webkit-transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
      max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
      transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
      max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms; */
  z-index: 1;
  pointer-events: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.contacts input {
  border: 1px solid #b2b2b2 !important;
  border-radius: 4px !important;
  height: 36px;
}
