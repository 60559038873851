@media only screen and (min-device-width: 320px) and (max-device-width: 1366px) and (orientation: landscape) {
  .landscapeContainer {
    display: flex;
    position: fixed;
    height: 100%;
    width: 100%;
    background: #1c1c1c1c;
    z-index: 9999;
    top: 0px;
    left: 0px;
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    overflow: hidden;
  }
  .lcContain {
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    max-width: 80vw;
    height: 60px;
    gap: 20px;
    padding: 10px 50px;
    background-color: #3d3d3d;
    border-radius: 8px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #f6f6f6;
    animation: lcContain-appear 1s ease forwards;
    overflow: hidden;
  }
  .lcContain.disappear {
    animation: lcContain-disappear 1s forwards;
  }
  .MuiButtonBase-root.MuiButton-root.gotitBtn {
    color: #d1d1d1;
    border-radius: 8px;
    border: 1px solid #6d6d6d;
    min-height: 20px;
    padding: 6px 20px;
    min-width: 106px !important;
    background-color: #6d6d6d;
  }
  .warningText {
    font-size: 14px;
    font-weight: 600;
  }
  /* @keyframes lcContain-appear {
    from {
      left: 50%;
      top: 100%;
      transform: translate(-50%, 0%);
      opacity: 0;
    }
    to {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 1;
    }
  } */

  @keyframes lcContain-appear {
    from {
      left: 50%;
      top: 100%;
      transform: translate(-50%, 0%);
      opacity: 0;
    }
    to {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 1;
    }
  }

  @keyframes lcContain-disappear {
    from {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 1;
    }
    to {
      left: 50%;
      top: 100%;
      transform: translate(-50%, 0%);
      opacity: 0;
    }
  }
}
