/* @import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap"); */

.logo2 {
  height: 70px;
}

body {
  background-color: #1c1c1c;
}

/*TC css Start*/
.tcmaindiv {
  display: flex;
  padding: 0px 140px 0px 140px;
  position: relative;
  top: 160px !important;
}

.tcsubtextarea {
  padding: 0px 140px 0px 140px;
  position: relative;
  top: 120px;
}

.trmcndimage {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.tcheading {
  color: #ffffff;
  font-size: 48px;
  font-weight: 300;
  text-align: left;
  font-family: "inter", sans-serif;
  letter-spacing: 0px;
  line-height: 64px;
  margin: 20px 0px 15px 0px;
}

.tcleft {
  position: relative;
  width: 50%;
}

.tcright {
  position: relative;
  width: 50%;
  /*
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
*/
}

.cndtn {
  color: #ff9900;
}

.tctext {
  position: relative;
}

.tcsubtext {
  color: #ffffff;
  font-family: "inter", sans-serif;
  letter-spacing: 0px;
  font-weight: 300;
  font-size: 18px;
  text-align: left;
  line-height: 33px;
}

.zoyellink {
  color: #99cfff;
  text-decoration: none;
}

.tctextarea {
  position: relative;
  margin-left: 40px;
}

.navbar-brand {
  position: relative;
  text-align: center;
  display: block;
}

.tcsubheading {
  color: #ffffff;
  font-family: "inter", sans-serif;
  letter-spacing: 0px;
  font-weight: 400;
  font-size: 32px;
  text-align: left;
  line-height: 46px;
  margin: 25px 0px 20px 0px;
}

.tcsecondsubhdng {
  color: #ffffff;
  font-family: "inter", sans-serif;
  letter-spacing: 0px;
  font-weight: 400;
  font-size: 28px;
  text-align: left;
  line-height: 46px;
  margin: 25px 0px 20px 0px;
}

.tcsubheadingtxt {
  color: #ffffff;
  font-family: "inter", sans-serif;
  letter-spacing: 0px;
  font-weight: 400;
  font-size: 32px;
  text-align: left;
  line-height: 39px;
  margin: 0px 0px 15px 0px;
}

.tcsublinehdng {
  color: #ffffff;
  font-family: "inter", sans-serif;
  letter-spacing: 0px;
  font-weight: 400;
  font-size: 18px;
  text-align: left;
  line-height: 21px;
  margin: 0px 0px 10px 0px;
}

.tcabout {
  color: #ffffff;
  font-family: "inter", sans-serif;
  letter-spacing: 0px;
  font-weight: 200;
  font-size: 14px;
  text-align: left;
  line-height: 22px;
  margin: 10px 0px;
  /*        background: #0b0c0c1c;*/
}
.tcaboutt {
  color: #ffffff;
  font-family: "inter", sans-serif;
  letter-spacing: 0px;
  font-weight: 200;
  font-size: 14px;
  text-align: left;
  margin: 10px 0px;
  /*        background: #0b0c0c1c;*/
}

.tcsubline {
  color: #ffffff;
  font-family: "inter", sans-serif;
  letter-spacing: 0px;
  font-weight: 400;
  font-size: 24px;
  text-align: left;
  line-height: 29px;
  margin: 0px 0px 15px 0px;
}

.tcsublinetxt {
  color: #ffffff;
  font-family: "inter", sans-serif;
  letter-spacing: 0px;
  font-weight: 400;
  font-size: 24px;
  text-align: left;
  line-height: 29px;
  margin: 0px 0px 15px 0px;
}

.semitcabout {
  color: #ffffff;
  font-family: "inter", sans-serif;
  letter-spacing: 0px;
  font-weight: 400;
  font-size: 20px;
  text-align: left;
  line-height: 29px;
  margin: 0px 0px 15px 0px;
}

.tcul {
  list-style-type: disc;
  color: #ffffff;
  font-size: 18px;
  text-align: left;
}

.tcmainheading {
  position: relative;
  margin-bottom: 46px;
}

.subheadingtc {
  position: relative;
  margin-bottom: 30px;
}

.subtcheading {
  position: relative;
  margin-bottom: 25px;
}

/*TC css End*/
/*TC responsive css*/
@media only screen and (max-width: 480px) {
  .tcmaindiv {
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
    padding: 0 !important;
    position: relative;
    top: 80px !important;
  }
  .tcsecondsubhdng {
    font-size: 24px !important;
  }

  .tctext {
    width: auto !important;
  }

  .tcsubtextarea {
    padding: 0 !important;
  }

  .tcleft {
    width: auto !important;
  }

  .tcright {
    width: auto !important;
  }

  .tctextarea {
    margin: 0 !important;
  }

  .tcheading {
    font-size: 28px !important;
    line-height: normal !important;
  }

  .tcmainheading {
    margin: 0 !important;
  }

  .tcsubtext {
    font-size: 15px !important;
    line-height: 20px !important;
  }

  .tcsubheading {
    font-size: 25px !important;
    margin: 0 !important;
  }

  .tcabout {
    font-size: 15px !important;
    line-height: 20px !important;
  }

  .tcsubheadingtxt {
    font-size: 22px !important;
  }

  .tcsubline {
    font-size: 20px !important;
    line-height: 10px !important;
  }

  .tcsublinetxt {
    font-size: 20px !important;
  }
}

@media only screen and (max-width: 768px) {
  .tcmaindiv {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 10px 0px 10px !important;
  }

  .tcsubtextarea {
    padding: 0 !important;
  }

  .tctext {
    width: auto;
  }
}

.bold-privacy-policy{
  font-weight: bolder !important;
}
