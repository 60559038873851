/* css for small phone max width 576px */
@media screen and (max-width: 767.98px) {
  body {
    height: 100%;
    width: 100%;
    position: relative;
    margin: 0;
    padding: 0;
    /* overflow: hidden; */
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  #root {
    height: 100%;
    width: 100%;
    position: relative;
    box-sizing: border-box;
  }
  /* landing page css start */
  .lp-full-container .showFuntionSec {
    height: auto;
    background-color: #1c1c1c;
    padding: 100px 20px 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    flex-direction: column;
  }
  .showFuntionSec .showFuntionContainer {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .showFuntionSec .funtionDescribeText {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  .funtionDescribeText .zoomFeatures {
    position: absolute !important;
    left: -42px !important;
    right: auto !important;
    z-index: 1 !important;
    top: 140% !important;
    transition: all 0.6s ease 0s;
    height: 100px;
  }
  .funtionDescribeText .fdtContainer .fdtTitle {
    font-size: 30px;
    font-family: inter;
    line-height: 34px;
    color: #ffff;
    margin-bottom: 20px;
  }
  .fdtContainer .fdtSubtitle {
    font-size: 16px;
    font-family: inter;
    line-height: 24px;
    color: #ffff;
  }
  .lp-full-container .appDownloadSec {
    height: auto;
    background-color: #1c1c1c;
    padding: 100px 20px 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 70px;
    flex-direction: column;
  }
  .appDownloadSec .leftArea,
  .appDownloadSec .rightArea {
    width: 100%;
  }
  .appDownloadSec .appDownloadInfo {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
  }
  .appDownloadSec .adnLinkGrp {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    flex-direction: column;
  }
  .appDownloadInfo .adnInfo {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 14px;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
  }

  .showFuntionContainer .device-mockup {
    position: relative;
    width: 100%;
    padding-bottom: 57.775701%;
  }
  .showFuntionContainer .device-mockup.imac {
    padding-bottom: 186px;
  }
  .funtionDescribeText .fdtContainer {
    width: 100%;
    height: 100%;
    margin-top: 5%;
    /* display: flex; */
    /* align-items: center; */
    /* flex-direction: column; */
  }
  .lp-full-container .latestNewsSec {
    height: auto;
    background-color: #1c1c1c;
    padding: 20px 20px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }

  .lp-full-container .zoyelFooterContainer {
    padding: 60px 20px 40px;
    background: rgb(48, 48, 48);
    background: linear-gradient(
      180deg,
      rgba(48, 48, 48, 1) 0%,
      rgba(0, 0, 0, 1) 100%
    );
    border-top: 1px solid #707070;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 40px;
  }
  .zoyelFooterContainer .zoyelFootElementContainer {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    gap: 20px;
    flex-direction: column;
  }
  .zoyelFootElementContainer .footerElement {
    width: calc(100% / 1);
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 16px;
  }

  .lnSliderContainer .sliderElement {
    display: flex !important;
    align-items: flex-start !important;
    justify-content: flex-start !important;
    padding: 16px;
    background-color: #000;
    border: 1px solid #545454;
    margin: 0 10px;
    width: calc(100% - 56px);
    gap: 10px !important;
    flex-direction: column !important;
    height: auto;
  }
  .latestNewsSecContainer .lnSliderContainer {
    margin-top: 10px;
  }
  .connectWithZoom {
    position: absolute !important;
    right: auto !important;
    left: -105px !important;
    top: auto !important;
    bottom: -75px !important;
    transform: rotate(78deg) !important;
    transition: all 0.6s ease 0s !important;
    z-index: 1 !important;
    height: 65px !important;
  }
  .appDownloadInfo .adnTitle {
    font-size: 30px;
    font-family: Inter;
    color: #fff;
    line-height: 38px;
  }
  .top-navbar-fixed .menu-toggle span {
    background-color: #b2b2b2;
  }
  button.slick-arrow.slick-prev,
  button.slick-arrow.slick-next {
    display: none !important;
  }
  .lp-full-container .clientSec {
    height: auto;
    background-color: #1c1c1c;
    padding: 100px 20px 50px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 10px;
  }
  .hex .cleintLogo {
    height: 50px;
    width: 50px;
  }
  .ourClientContainer .cleintsDtlsWrap {
    height: auto;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
    flex-direction: column;
  }
  .ourClientContainer .clientInfo {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 50px;
    flex-direction: row;
    color: #fff;
  }
  .clientsLogos .hex {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 7px;
    width: 70px;
    height: 85px;
    background-color: #ffffff;
    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  }
  .clientsLogos .hexagon-gallery {
    margin: 0 auto;
    /* margin-top: 50px; */
    /* width: 100%; */
    display: flex;
    grid-template-columns: repeat(13, 0fr);
    grid-auto-rows: 62px;
    grid-gap: 9px;
    padding-bottom: 50px;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  .hex.hex-hide {
    display: none !important;
  }
  .hex.hex-noImg {
    display: none !important;
  }
  .logRegFullContainer .lrContainer {
    margin: auto;
    padding: 80px 20px;
    height: calc(100% - 160px);
    width: calc(100% - 40px);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 30px;
    overflow-y: overlay;
  }
  .lrContainer .passwordPolocy {
    width: 100%;
    font-size: 14px;
    color: #fff;
    font-family: Inter;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 16px;
  }
  .logRegForm .lrHead {
    color: #ff9900;
    font-size: 2rem !important;
    font-family: Inter;
    margin-bottom: 20px;
  }
  .logRegFullContainer {
    position: fixed;
    top: 0px;
    left: 0px;
    background-color: #000000;
    min-height: 100vh;
    height: auto;
    width: 100vw;
    z-index: 10;
    overflow: hidden;
    overflow-y: overlay;
    color: #707070;
  }
  .lrLeftContain .statLrImg img {
    height: 150px;
    max-height: 200px;
    width: auto;
  }
  .logRegContain .lrLeftContain,
  .logRegContain .lrRightContain {
    width: 100%;
  }
  /* landing page css end */
  /* ================================================================================================================ */
  /* home page css start */
  .coustomModel .modelBox {
    align-items: flex-start;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: flex-start;
    padding: 20px;
    width: calc(100% - 50px) !important;
  }
  .header .tempContainer {
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin-left: 6px !important;
    margin-right: 6px !important;
    width: calc(100% - 12px) !important;
  }
  .tabs-container {
    height: calc(100dvh - 50px) !important;
    overflow: hidden;
    position: relative;
    width: 100%;
  }

  .tabs-container .tabs {
    display: flex;
    width: calc(100% - 12px);
    margin-left: 6px;
    margin-right: 6px;
    height: 30px;
    overflow: hidden;
    overflow-x: scroll !important;
  }

  .TabWrapper .tab {
    min-width: auto;
    height: 100%;
    max-height: 22px;
    padding: 4px 6px;
    cursor: pointer;
    border-bottom: none;
    color: #ffffff;
    position: relative;
    transition: all 0.6s;
    background-color: #606060;
    display: flex;
    align-items: center;
    gap: 7px;
    font-size: 14px;
    text-transform: capitalize !important;
  }
  .tab .tabsText {
    display: -webkit-box;
    min-width: auto;
    width: 100%;
    max-width: 85px;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    color: #fff;
    opacity: 1;
    text-overflow: ellipsis;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.14px;
  }
  .tabs-container .tab-content {
    background: #1c1c1c;
    height: calc(100vh - 80px);
    max-height: calc(100vh - 80px);
    overflow: hidden;
    overflow-y: hidden;
    position: relative;
    width: 100%;
    z-index: 1;
  }
  .tab-content .homeContainerArea {
    display: block;
    height: calc(100dvh - 80px);
    overflow: hidden;
    width: 100%;
  }
  .homeContainerArea .homeContainArea {
    height: 100%;
    margin: 0;
    overflow: hidden;
    padding: 0;
    width: 100% !important;
  }
  .homeContainerArea .hmContWrkSpc {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: calc(100% - 4px);
    justify-content: flex-start;
    overflow: hidden;
    padding: 6px 2px 20px 6px;
  }
  .homeContainArea .hmTabsContainer {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    height: 50px;
    overflow: hidden;
    overflow-x: overlay;
    width: 100%;
  }
  .hmContWrkSpc .mnuCompoContainer {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 2px;
    height: 100%;
    justify-content: flex-start;
    overflow: hidden;
    width: 100%;
  }
  .mnuCompoContainer .mnuCompoIncomingInstanse {
    box-sizing: border-box;
    height: calc(100% - 6px);
    overflow: hidden;
    overflow-y: overlay;
    padding-bottom: 5px;
    position: relative;
    width: 100%;
  }

  .tab-content .homeContainerArea .homeContainArea .MuiGrid-grid-xs-7 {
    box-sizing: border-box;
    margin: 0;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .homeContainArea .hmTabActvIndcator {
    display: none;
    width: 100%;
    height: 2px;
    position: absolute;
    bottom: 6px;
    background: #fff;
    transition: all 0.5s;
  }
  .hmTabsBtn {
    display: flex;
    height: 46px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    flex: 1 0;
    background: transparent;
    outline: none;
    border: none;
    cursor: pointer;
  }

  .hmTabContnt {
    display: flex;
    /* height: 48px; */
    padding: 12px 0px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    /* gap: 12px; */
  }
  .hmTabsContainer::-webkit-scrollbar {
    width: 0px !important;
    height: 1px !important;
  }
  .hmTabsContainer ::-webkit-scrollbar-thumb {
    background-color: rgba(107, 107, 107, 1);
  }
  .hmTabsContainer::-webkit-scrollbar-track {
    background: #333333;
  }
  .taskCardContainer .contMxWidthLim {
    display: flex;
    padding: 15px 0px 15px 6px;
    align-items: flex-start;
    gap: 10px;
    width: calc(100% - 10px);
    max-width: calc(100% - 10px);
  }
  .meetingCardContainer .contMxWidthLim {
    display: flex;
    padding: 10px 0px 8px 6px;
    align-items: flex-start;
    gap: 10px;
    width: calc(100% - 10px);
    max-width: calc(100% - 10px);
  }
  .contMxWidthLim .meetInOutIoc {
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .contMxWidthLim .tskInfoDtl {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    flex: 1 1;
    width: 100%;
  }
  .meetInfoDtl .meetHead {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }
  .tskInfoDtl .tskNmPriot {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
  }

  .meetingVEContainer .metngVEHeaderPnl {
    display: flex;
    width: auto;
    padding: 10px 10px 10px 10px;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    background-color: #000;
    border-bottom: 1px solid #545454;
  }

  .meetingVEContainer .meetVEContainDtl {
    order: 2;
    flex: 1 1;
    padding: 10px 10px 10px;
    height: 100%;
    overflow: hidden;
    overflow-y: hidden;
  }
  body textarea,
  body input {
    font-size: 16px !important;
  }

  .rightShowDtlPanel .meetingsEditedView,
  .rightShowDtlPanel .meetingsRedOnlyView {
    overflow: hidden;
    overflow-y: hidden;
    position: relative;
    width: 100%;
    height: 100%;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  .rightShowDtlPanel .dashboardMeetingsContainer .meetROVContainer {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 20px;
    overflow: hidden;
    overflow-y: overlay;
    height: 100%;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .dashboardMeetingsContainer .meetingsViewEditPannel {
    display: flex;
    flex-direction: row;
    height: calc(100dvh - 80px);
    overflow: hidden;
    position: relative;
    border-left: 0.1px solid #545454;
    background-color: #000;
  }
  .homeContainerArea.MuiBox-root
    .homeContainArea
    .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-5 {
    box-sizing: border-box;
    margin: 0;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 41.666667%;
    flex-basis: 100%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 100%;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .botFlotingContainerArea,
  .SchduleRightFlotingContainer {
    width: 100vw !important;
  }
  .eventAlertWrapper {
    z-index: 1;
    position: fixed;
    top: -100%;
    width: calc(100% - 28px);
    left: 50%;
    opacity: 0;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 10px 14px;
    border-radius: 8px;
    gap: 6px;
    animation: smooth-appear 1s ease forwards;
    transition: all 2s;
  }
  .zoeyBotInc {
    z-index: 1024;
    position: fixed;
    bottom: 136px !important;
    height: 36px !important;
    width: 42px !important;
    border-radius: 4px !important;
    background-color: #fff;
    right: 1px !important;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0px 6px 12px -4px #000;
  }
  .botFlotingContainerArea .botContHeader {
    order: 1;
    display: flex;
    width: auto;
    padding: 16px 16px 16px 16px;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    background-color: #000;
    border-bottom: 1px solid #545454;
  }
  .botFlotingContainerArea .botContContain {
    order: 2;
    flex: 1 1;
    padding: 15px 16px 10px;
    height: calc(100vh - 235px);
  }
  .botContContain .botContContainDetails {
    overflow: hidden;
    overflow-y: overlay;
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
  }
  .botContContain .msgBodyTxt {
    color: rgb(255 255 255 / 80%);
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
  }
  .meetingsTabContainerArea .meetingsTabContainArea .meetingsShowAreaContain {
    padding: 10px 0px 0px 0px !important;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    height: calc(100dvh - 90px);
    width: calc(100% - 0px) !important;
    overflow: hidden;
  }
  .meetingsShowAreaContain .fullscreenmeeting {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    gap: 10px;
  }
  .meetingsShowAreaContain .meetingControlBtnGroup {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 10px;
    border-radius: 0px;
    background-color: #333333;
    row-gap: 2px;
    column-gap: 4px;
    flex-wrap: wrap;
  }
  .drawer-portal.notification-portal #notifications-container,
  .notification-enter-done {
    z-index: 9999;
  }
  .tab-content .bottomMnuPopover {
    min-width: calc(100% - 30px);
    padding: 20px 10px;
    max-width: calc(100% - 50px);
    min-height: 200px;
    position: absolute;
    bottom: 120px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #323232;
    z-index: 9999;
    border-radius: 8px;
  }

  .bottomMnuPopover .bottomMnuPopoverContainer {
    display: flex;
    background-color: #323232;
    justify-content: space-between;
    color: white;
    min-width: 100%;
    padding: 1px;
    max-width: 100%;
    flex-direction: column;
    /* min-height: 200px; */
    /* position: absolute; */
    /* bottom: 55px; */
    /* color: white !important; */
  }

  .MuiDialog-paperWidthSm {
    min-width: auto !important;
  }
  .inputRumID .joinMetRmIdInputBox {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: #272727;
    outline: none;
    border: none;
    box-shadow: none;
    padding: 4px 20px;
    width: calc(100% - 40px);
    max-width: 320px;
    min-width: 100px;
    font-size: 16px;
    line-height: 28px;
    height: 36px;
    color: #fff;
    border-radius: 8px;
    font-family: Inter;
  }
  .taskContainerArea.MuiBox-root .taskContainArea .taskContainer {
    padding: 10px 6px 10px 6px;
    width: calc(100% - 12px);
    height: calc(100vh - 100px);
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 10px;
    overflow: hidden;
  }
  .taskListTopBtnLeft .searchTasklist {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    border: 1px solid #545454;
    background-color: #333333;
    border-radius: 8px;
    height: 38px;
  }
  .taskContainer .taskListTableContainer {
    height: calc(100vh - 195px);
    width: 100%;
    overflow: hidden;
    overflow-y: overlay;
    overflow-x: overlay;
    border-bottom: 1px solid #545454;
  }
  .pRelative .RightFlotingContainerArea {
    width: 100vw;
    height: 100%;
  }
  .RightFlotingContainerArea .rightFlotingPanel {
    display: flex;
    flex-direction: row;
    height: calc(100dvh - 80px);
    overflow: hidden;
    position: relative;
    border-left: 0.1px solid #545454;
    background-color: #000;
  }
  .rightFlotingContainer .rfContHeader {
    order: 1;
    display: flex;
    width: auto;
    padding: 10px;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    background-color: #000;
    border-bottom: 1px solid #545454;
  }
  .rightFlotingContainer .rfContFooter {
    order: 3;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    border-top: 1px solid #5454548a;
  }
  .rightFlotingContainer .rfContContain {
    order: 2;
    flex: 1 1;
    padding: 15px 25px 10px;
    height: calc(100% - 235px);
  }
  .rightFlotingContainer .addNewPosition {
    width: calc((100% - 12px) / 1) !important;
  }
  .taskContainer .taskListTableContainer::-webkit-scrollbar {
    width: 0px !important;
    height: 1px !important;
  }
  .createTaskContainerArea .cerateTaskContainer {
    width: calc(100% - 12px);
    height: calc(100dvh - 100px);
    flex-shrink: 0;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 10px;
    overflow: hidden;
    padding: 10px 6px 10px 6px;
  }
  .createTaskContainerArea .RightFlotingFormContainerArea {
    width: 100vw;
  }
  .elementFormContainer .ModelBox.MuiBox-root {
    width: calc(100% - 64px);
  }
  .flotHeaderPanel .flotHedCon {
    font-family: Inter;
    font-size: 18px;
    font-weight: 600;
    color: #ffffff;
  }
  .RightFlotingFormContainerArea .multiFormGroup .formElement {
    width: 100%;
    min-width: 160px;
    border-bottom: none;
  }
  .formElementGroup .width13 {
    width: 100% !important;
  }
  .schedulerContainer .SchedulerHeader {
    display: none;
  }
  .formAutoComInputField .MuiInputBase-sizeSmall.MuiAutocomplete-inputRoot {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    padding-left: 6px;
  }
  .cerateTaskContainer .formBtnElement {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
    flex-wrap: wrap;
  }
  .driveContainerArea .driveContainer {
    padding: 0px 6px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 10px;
    overflow: hidden;
    height: calc(100dvh - 80px);
  }
  .driveContainer .driveHeadArea {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    padding: 14px 0px 6px;
    height: auto;
    width: 100%;
    gap: 8px;
  }
  .driveHeadArea .driveHedLft {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    flex: 1 1;
    width: 100%;
  }
  .driveHeadArea .driveHedRight {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 30px;
    width: 100%;
  }
  .ReactTable .rt-tbody {
    flex: 99999 1 auto !important;
    display: flex !important;
    flex-direction: column !important;
    overflow: hidden !important;
    overflow-y: overlay !important;
    max-height: calc(100dvh - 325px) !important;
  }
  .ReactTable .-pagination .-previous,
  .ReactTable .-pagination .-next {
    flex: 0 0 !important;
    text-align: center;
    min-width: 70px;
  }
  .pagination-bottom .-next button,
  .pagination-bottom .-previous button {
    width: 80px;
  }
  .taskViewPannel .ModelBox.MuiBox-root {
    width: calc(100% - 50px);
    padding: 18px;
  }
  .AnalyticsDashboardContainer .AnalyticsDashboardHeader,
  .cerateTaskContainer .crtTaskHeader,
  .taskContainer .taskListHeader {
    color: #fff;
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    cursor: pointer;
  }
  .AnalyticsDashboardContainerArea .AnalyticsDashboardContainer {
    padding: 10px 6px 10px 6px;
    width: calc(100% - 12px);
    height: calc(100dvh - 100px);
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 10px;
    overflow: hidden;
  }
  .AnalyticsDashboardContainer .anDashCardContainArea {
    height: 100%;
    width: 100%;

    overflow: hidden;
    overflow-y: overlay;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }
  .RightFlotingContainerArea .ModelBox.MuiBox-root {
    width: calc(100% - 32px);
    padding: 20px 12px;
  }
  .AnalyticsDashboardContainer .cuContainArea {
    height: 100%;
    width: 100%;
    overflow: hidden;
    overflow-y: hidden;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  .anDashCardContainArea .anDashIndCard.manageWidth50,
  .anDashCardContainArea .anDashIndCard {
    width: 100%;
    height: auto;
  }
  .anDashIndCard .anDashCardArea {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 20px;
    gap: 8px;
    background-color: #292929;
    border: 1px solid #444444;
    border-radius: 12px;
    width: calc(100% - 42px);
    height: auto;
    min-height: 150px;
  }
  .andHeader .andCrdNM {
    font-size: 18px;
    font-family: Inter;
    font-weight: 400;
    color: #fff;
  }
  .rfContContainDetails .elementFormContainer .formElementGrp {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 6px;
    flex-wrap: wrap;
    width: 100%;
  }
  .formElementGrp .mxW-100 {
    max-width: 100% !important;
  }
  .formElementGrp .formElement.width13 {
    width: 100% !important;
  }

  .apps li,
  .quick-actions li {
    display: flex;
    align-items: flex-start;
    margin-bottom: 6px;
    cursor: pointer;
  }
  .bottomMnuPopoverContainer .apps,
  .bottomMnuPopoverContainer .quick-actions {
    padding: 5px;
    border-radius: 5px;
    width: 100%;
  }
  .bottomMnuPopoverContainer .apps ul {
    list-style-type: none;
    padding: 0;
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
  }
  .bottomMnuPopoverContainer .quick-actions ul {
    list-style-type: none;
    padding: 0;
    columns: 1;
    -webkit-columns: 1;
    -moz-columns: 1;
  }
  .ReactTable .rt-tbody::-webkit-scrollbar,
  .ReactTable .rt-table::-webkit-scrollbar {
    width: 0px !important;
    height: 1px !important;
  }
  .ReactTable .rt-tbody::-webkit-scrollbar-thumb,
  .ReactTable .rt-table::-webkit-scrollbar-thumb {
    background-color: rgba(107, 107, 107, 1);
  }
  .ReactTable .rt-tbody::-webkit-scrollbar-thumb,
  .ReactTable .rt-table::-webkit-scrollbar-track {
    background: rgb(18 18 18 / 70%);
  }
  .rt-td .drvfileActionBtnGrp {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    opacity: 1;
    transition: all 0.4s;
  }
  .tab-content iframe {
    height: calc(100vh - 88px) !important;
  }
  .tab-content .fullscreenmeeting iframe {
    height: 100% !important;
  }
  .adminContainArea .AdminWorkSpace {
    padding: 14px 6px 20px 6px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 12px;
    height: calc(100% - 34px);
  }
  .cuHeaderGrp .cuhederTxt {
    font-size: 18px;
    line-height: 32px;
    color: #ffffff;
    font-family: Inter;
  }
  .AdminWorkSpace .createUnitsArea {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
    height: calc(100dvh - 160px);
  }
  .cuCardList .cardContent {
    width: 100% !important;
    max-width: 100% !important;
    border-radius: 8px;
    background-color: #333333;
    box-shadow: 2px 3px 3px -1px #141414;
    color: #b2b2b2;
    font-family: Inter;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    gap: 2px;
  }
  .createUnitsArea .cuCardList .cardContent.widthfalf {
    width: 100% !important;
    max-width: 100% !important;
  }
  .orgContainerView .cuCheckItem {
    width: 100%;
    border-bottom: 1px dashed #292929;
    padding-bottom: 2px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    cursor: pointer;
    transition: all 0.8s;
    flex-wrap: wrap;
  }
  .orgContainerView .cuCheckItem span {
    color: #fff;
    font-weight: 600;
  }
  .emptyRowMessage .copyPositionRow {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .organizationPosition .emptyRowMessage {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding: 50px 10px;
    gap: 10px;
    font-size: 16px;
    color: rgb(255 255 255 / 30%);
  }
  .cuContainArea .cuCardList {
    width: 100%;
  }
  .cuCardList .cardContent .MuiCardContent-root {
    padding-bottom: 6px;
    width: calc(100% - 32px) !important;
  }
  .adminContainerArea .AdminRightFlotingContainer {
    width: 100vw;
  }
  .cuContainArea .CriticalRightsContainer,
  .cuContainArea .expenditureContainer {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 60px;
  }
  .CriticalRightsContainer .critiaclElement,
  .expenditureContainer .expenElement {
    width: 100% !important;
    height: auto;
  }
  .cuContainArea .TableContainer {
    height: 100%;
    width: auto;
    padding: 0px;
    overflow-x: overlay;
  }
  .TableContainer .taskListtableHeader {
    background-color: #262626;
    height: 48px;
    box-shadow: inset 0 -1px 0 #474747;
  }
  .TableContainer .taskListtableHeader tr th {
    font-size: 14px;
    font-family: Inter;
    font-weight: 600;
    color: #ffffff;
    text-align: left;
    padding-left: 10px;
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: #262626;
    min-width: 225px;
    height: 36px;
  }
  .MuiDialog-root .MuiDialog-container .MuiPaper-root.MuiPaper-elevation {
    max-width: 100% !important;
    width: calc(100% - 20px) !important;
    min-width: calc(100% - 20px) !important;
  }
  .schedulerContainerArea .schedulerContainer {
    padding: 10px 6px 10px 6px;
    width: calc(100% - 12px);
    height: calc(100dvh - 100px);
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 10px;
    overflow: hidden;
  }
  .schdulerTopBtnLeft .formBtnGroup {
    display: none;
  }
  .schedulerContainer .SchedulerHeader {
    color: #fff;
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    cursor: pointer;
  }
  .formDatePick
    .react-datepicker-wrapper
    .react-datepicker__input-container
    input {
    height: 40px;
    outline: none;
    border: none;
    padding-block: 0px;
    padding: 0px 16px 0px 16px;
    border-radius: 4px;
    color: #ffffff;
    background-color: #545454;
    font-size: 16px;
    font-family: Inter;
    width: 145px;
  }
  .schdulerTopBtnLeft .schedulerLeagents {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    cursor: pointer;
    flex-wrap: wrap;
    margin-left: 20px;
    order: 2;
  }
  .schedulerContainer .schedulerTopBtnGroup {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
    flex-direction: column;
  }
  .schedulerTopBtnGroup .MuiButtonGroup-root.MuiButtonGroup-contained {
    background-color: #545454;
    height: 40px;
  }
  .schedulerTopBtnGroup .schdulerTopBtnLeft {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    flex: 1 1;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
  }
  .schdulerTopBtnLeft .schedulerLeagents {
    display: none;
  }
  .schdulerTopBtnLeft
    .formDatePick
    .react-datepicker-wrapper
    .react-datepicker__input-container
    input {
    height: 40px;
    outline: none;
    border: none;
    padding-block: 0px;
    padding: 0px 16px 0px 16px;
    border-radius: 4px;
    color: #ffffff;
    background-color: #545454;
    font-size: 16px;
    font-family: Inter;
    width: 110px;
  }
  .schdulerTopBtnLeft .formElement {
    max-width: calc((100% - 10px) / 2) !important;
    order: 1;
  }
  .schdulerTopBtnLeft .formBtnGroup {
    order: 3;
  }
  .schdulerTopBtnLeft .formDatePick {
    order: 4;
  }
  .schedulerLeagents .legLabel {
    display: none !important;
  }
  .e-table-container .leftRightCalHeight {
    height: calc(100vh - 370px);
    overflow-y: auto;
  }
  .schedulerContainerArea .SchduleRightFlotingContainerSettings {
    width: 100vw;
  }
  .flotingAreaContainer .right-panel {
    margin-top: 20px;
    margin-left: 10px;
    height: calc(100vh - 158px);
    overflow: hidden;
    overflow-y: overlay;
    width: calc(100% - 20px);
  }
  .schdulerTopBtnLeft .formSingBtn {
    display: none;
  }
  .CalendarBtnViewGroup
    .MuiButtonGroup-root.MuiButtonGroup-contained
    .MuiButtonBase-root.MuiButton-root {
    padding: 6px 10px;
  }
  .SchduleRightFlotingContainerSettings .col-right {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 1rem;
  }
  .SchduleRightFlotingContainerSettings .col-right .MuiFormControl-root {
    min-width: 100%;
    width: 100% !important;
  }
  .schedulerTopBtnGroup .schdulerTopBtnRight {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 6px;
    width: 100%;
  }
  .schedulerTopBtnGroup .schdulerTopBtnRight .selectCalenderGrp {
    display: none;
  }
  .schedulerLeagents:hover .schLeagendsInd {
    margin-left: -20px !important;
  }
  .flotingAreaClose .facHeader {
    color: #fff;
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.24px;
    padding: 0px 10px 0px 6px;
  }
  .flotingAreaContainer .elementFormContainer {
    overflow: hidden;
    overflow-y: overlay;
    height: calc(100dvh - 200px) !important;
    width: calc(100% - 12px) !important;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 14px;
    padding: 0px 30px 0px 6px !important;
  }
  .meetGustDtlElement .MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon {
    width: calc(100vw - 12px);
  }
  .adminContainerArea .AdminRightFlotingContainer {
    height: 100% !important;
    width: 100vw;
    position: absolute;
    bottom: 0px;
    right: 0px;
    background-color: #000000;
    transition: all 0.6s;
    /* animation: schFloting-appear 1s ease forwards; */
    overflow: hidden;
    z-index: 999;
  }
  .AdminRightFlotingContainer .flotingAreaContainer .elementFormContainer {
    overflow: hidden;
    overflow-y: overlay;
    height: calc(100dvh - 210px);
    /* height: 100%; */
    width: calc(100% - 12px) !important;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 14px;
    padding: 0px 30px 0px 6px !important;
  }
  .elementFormContainer .fixedButtonsContainer {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    border-top: 1px solid #545454;
    width: 100%;
    gap: 10px;
    flex-direction: row;
    bottom: 0;
    background-color: #000;
  }
  .SchduleRightFlotingContainer .formElement .medDateTime {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    gap: 4px;
    flex-direction: column;
    width: 100%;
  }
  .SchduleRightFlotingContainer .MuiFormGroup-root.formRadioGroup {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    gap: 2px;
    padding-left: 2px;
  }
  .SchduleRightFlotingContainer .flotingAreaClose {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 12px);
    padding: 10px 6px 6px 6px;
    border-bottom: 1px solid #545454;
  }
  .recurDTW100 .toTimeInput,
  .recurDTW100 .frmTimeInput {
    width: 100%;
  }
  .recurDTW100 .toTimeInput .MuiInputBase-root.MuiOutlinedInput-root,
  .recurDTW100 .frmTimeInput .MuiInputBase-root.MuiOutlinedInput-root {
    max-width: 100% !important;
    width: 100% !important;
  }
  .meetGustDtlElement .MuiAutocomplete-root svg {
    color: #9d9d9d;
  }
  .SchduleRightFlotingContainer
    .formControl
    .MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementEnd {
    justify-content: flex-end !important;
    margin-left: 0px;
    color: #fff;
    margin-right: 4px !important;
  }
  .SchduleRightFlotingContainer .formElement .toDateTime,
  .SchduleRightFlotingContainer .formElement .frmDateTime {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    width: 100%;
  }
  .medDateTime .toDateInput,
  .medDateTime .frmDateInput {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 6px;
    border-radius: 4px;
    background-color: #333;
    gap: 4px;
    transition: all 0.8s;
    border: 1px solid #545454;
    flex: 1;
  }
  .cell_Date_Days .cell_Date {
    font-size: 16px;
    line-height: 16px;
    font-family: Inter;
    color: #fff;
    margin-bottom: 4px;
  }
  .cell_Date_Days .todayDD {
    background-color: #ff9900;
    padding: 2px 2px;
    border-radius: 34px;
    height: 26px;
    width: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .e-schedule .e-vertical-view .e-left-indent {
    width: 65px !important;
    border-width: 0 1px 1px 0px;
    border-color: #545454;
    border-style: solid;
  }
  .e-schedule-table .e-time-slots {
    width: auto;
  }
  .e-header-cells .cell_Date_Days {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    padding-left: 2px;
  }
  .e-schedule .e-agenda-view .e-content-wrap table td:first-child,
  .e-schedule .e-agenda-view .e-date-column {
    vertical-align: top;
    width: 90px !important;
    border: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #545454;
  }
  .e-schedule .e-agenda-view .e-appointment {
    border: none !important;
    border-right: 0 !important;
    cursor: default !important;
    padding: 0 4px !important;
    background: transparent !important;
    position: relative !important;
    height: auto !important;
    display: flex !important;
    align-items: center !important;
    justify-content: flex-start !important;
    gap: 8px !important;
    width: 100% !important;
  }
  .e-schedule .e-agenda-view .e-subject {
    color: #ffffff;
    font-size: 14px;
    font-family: Inter;
    line-height: 18px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    max-width: 150px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }
  .e-month-view .monthCalHeight {
    height: calc(100vh - 326px);
  }
  .eventAlertWrapper .evnDtlWTime {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 4px;
    font-family: Inter;
    font-size: 14px;
    line-height: 16px;
    flex-direction: column;
  }
  .eventAlertWrapper .eveAltBtn {
    margin-left: 24px;
    display: flex;
    gap: 4px;
    align-items: stretch;
    flex-direction: column;
  }
  /* home page css end */
  /* chat start */
  .lftChatGrid {
    width: 100% !important;
    box-sizing: border-box;
    margin: 0;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-basis: 100% !important;
    -ms-flex-preferred-size: 100% !important;
    flex-basis: 100% !important;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 100% !important;
    height: 100%;
  }
  .lftChatGrid.showLftgrid {
    display: none !important;
  }
  .lftChatGrid .chatLftContHead {
    display: flex;
    width: auto;
    padding: 6px 6px 6px 6px;
    align-items: center;
    gap: 6px;
    background: #1c1c1c;
  }
  .contactListGroup .chatContactList {
    display: flex;
    width: calc(100% - 6px);
    padding: 0px 0px 0px 6px;
    align-items: center;
    gap: 15px;
    cursor: pointer;
    transition: all 0.5s;
    position: relative;
  }
  .lftChatGrid .userDtlWlMsg {
    display: flex;
    width: calc(100% - 70px);
    padding: 12px 8px 12px 0px;
    flex-direction: column;
    align-items: flex-start;
    flex-shrink: 0;
    border-bottom: 0.5px solid #545454;
  }
  .chatContainArea .rftChatGrid {
    display: none;
    box-sizing: border-box;
    margin: 0;
    flex-direction: row;
    flex-basis: 100%;
    flex-grow: 0;
    max-width: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 100%;
  }
  .tab-content .chatContainerArea {
    height: calc(100dvh - 80px);
    width: 100%;
    display: block;
    overflow: hidden;
  }
  .chatContainerArea .chatContainArea {
    height: 100%;
    margin: 0;
    overflow: hidden;
    padding: 0;
    width: 100% !important;
  }
  .rftChatGrid.showRftgrid {
    display: flex;
    top: 0px;
  }
  .lftChatGrid .chtsrchUsr {
    position: relative;
    z-index: 100;
    box-sizing: border-box;
    display: flex;
    flex: none;
    align-items: center;
    height: 50px;
    padding: 0 6px 0 6px;
  }
  .lftChatGrid .crtBtnGrp {
    position: relative;
    display: flex;
    flex: none;
    align-items: center;
    height: 72px;
    padding-bottom: 1px;
    color: #fff;
    transition: all 0.5s;
    padding: 0 0 0 6px;
    gap: 15px;
  }
  .lftChatGrid .txtSeprt p {
    padding: 20px 0 10px 70px;
    box-sizing: border-box;
    height: 56px;
    font-size: 16px;
    color: #606060;
    text-transform: uppercase;
    margin: 0;
  }
  .lftChatGrid .cNwGrpImgNmArea {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    margin: 20px 6px 5px 6px;
    flex: none;
    flex-grow: 1;
    flex-basis: auto;
    flex-shrink: 0;
    gap: 30px;
  }
  .lftChatGrid .unRdMsgCunt {
    background-color: #00a884;
    border-radius: 1rem;
    padding: 0.3rem 0.6rem;
    display: inline-block;
    font-size: 13px;
    line-height: 13px;
    font-weight: 600;
  }
  .rftChatGrid .chatConvoHeader {
    display: flex;
    padding: 6px 6px 6px 6px;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    background: #1c1c1c;
    width: calc(100% - 12px);
    order: 1;
    flex: 0 0 auto;
    height: 46px;
  }
  .rftChatGrid .chatConvoMsg {
    height: calc(100% - 20px);
    width: calc(100% - 12px);
    overflow: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    padding: 10px 6px 10px 6px;
  }
  .rftChatGrid .ctCnvUDeg {
    color: #c0c0c0;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    display: -webkit-box;
    width: auto;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .rftChatGrid .chatConvoUsrDtl {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 2px;
  }
  .rftChatGrid .chatConvoContainer.hideWhenUseInDashboard {
    display: none;
  }
  .rftChatGrid .chatConversationPannel {
    width: 100%;
    z-index: 1;
    position: relative;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: calc(100dvh - 80px);
    justify-content: flex-start;
    overflow: hidden;
  }
  .chatConversationPannel .chatConvoContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow: hidden;
    background-color: #000000;
  }
  .rftChatGrid .chtMsg .msgMedia {
    height: auto;
    max-width: 100%;
    width: 100%;
  }
  .rftChatGrid .chtMsg .msg {
    position: relative;
    box-shadow: 0 1px 0.5px rgba(0, 0, 0, 0.13);
    max-width: 85%;
    display: inline-flex;
    padding: 6px 10px;
    flex-direction: column;
    align-items: flex-end;
    word-break: break-all;
  }
  .rftChatGrid .chatConvoFooter {
    display: inline-flex;
    padding: 5px 6px 5px 6px;
    align-items: center;
    gap: 16px;
    width: calc(100% - 12px);
  }
  .rftChatGrid .chatRegularFoot {
    gap: 10px;
  }
  .rftChatGrid .chatInputBox input {
    border-radius: 8px;
    background: #272727;
    height: 30px;
    width: -webkit-fill-available;
    border: none;
    padding: 4px 20px;
    color: #ffffff;
    font-size: 14px;
    letter-spacing: 0.8px;
    width: -webkit-fill-available;
  }
  .rftChatGrid .chtsrchUsr {
    position: relative;
    z-index: 100;
    box-sizing: border-box;
    display: flex;
    flex: none;
    align-items: center;
    height: 50px;
    padding: 0 6px 0 6px;
  }
  .rftChatGrid .selectedPartList {
    min-height: 20px;
    max-height: 200px;
    overflow: hidden;
    overflow-y: auto;
    position: relative;
    padding: 0;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 4px 0px 4px 6px;
    margin-top: 10px;
    flex: none;
  }
  .rftChatGrid .chatAtchImgContainer {
    display: flex;
    flex: 1 1;
    min-width: 100%;
    height: 100%;
    min-height: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: nowrap;
    align-self: auto;
    order: 0;
  }
  .rftChatGrid .imgComment {
    padding: 8px 4px;
    position: relative;
    min-height: 56px;
    flex-grow: 0;
    flex-direction: row;
    display: flex;
    flex-shrink: 1;
    flex-basis: auto;
    order: 0;
    width: calc(100% - 8px);
    align-items: center;
    justify-content: center;
  }
  .rftChatGrid .chatAtchImgActionGrp {
    padding: 8px 0;
    margin: 0 6px;
    border-top: 1px solid #8696a026;
    display: flex;
    flex: 0 0 82px;
    align-items: center;
    justify-content: space-between;
  }
  .rftChatGrid .upldFlSmalPerview {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 0;
    min-width: 0;
    order: 0;
    align-self: auto;
    flex-wrap: wrap;
    justify-content: center;
    margin: 4px 16px 0;
    box-sizing: border-box;
    flex: 1 1 auto;
  }
  .rftChatGrid .dropToUupledImg {
    display: none;
  }
  /* chat end */

  .createUnitsArea .cuHeaderGrp {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 3px;
  }
  .cuHeaderGrp .strogeSearchSpace {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 6px;
    width: 100%;
    flex-direction: column;
  }
  .strogeSearchSpace .searchTasklist {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    border: 1px solid #545454;
    background-color: #333333;
    border-radius: 8px;
    height: 38px;
  }
  .strogeSearchSpace .storeTimeline {
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
  }
  .elementFormContainer .curntStorCont {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
  }
  .curntStorCont .CurntStrData {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .CurntStrData .curntStrgTxtlight {
    font-size: 16px !important;
    font-weight: 400 !important;
  }
  .curntStrgTxtlight br {
    display: none;
  }
  .curntStorCont .curntDaImg {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
    margin-right: 0;
    width: 100%;
  }

  .selectTemplateContainArea .selectTemplateContainer {
    padding: 0px 6px 10px 6px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 10px;
    overflow: hidden;
    height: calc(100dvh - 170px);
  }
  .indTemplateCardGroup .indTemplateCard {
    width: calc((100% - 40px) / 1);
    overflow: hidden;
    padding: 20px;
    background-color: #000;
    border: 1px solid #545454;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 20px;
  }
  .AdminWorkSpace .selectTemplateContainerArea {
    height: 100%;
    width: 100%;
  }
  .selectTemplateContainerArea .MuiGrid-root.MuiGrid-item {
    height: 100%;
    width: 100%;
    max-height: 100% !important;
  }
  .emailContainer .mobLftEmail {
    display: none;
  }
  .emailContainer .rightEmailContain.mobRgtEmail {
    padding-right: 0px;
  }
  .rightMailTopbar .emailMenuButton {
    display: block;
  }
  .emailMenuButton .MuiButtonBase-root.MuiIconButton-root {
    height: 36px;
    width: 36px;
    background-color: #323232;
  }
  .emailMenuButton .MuiButtonBase-root.MuiIconButton-root svg {
    color: #b2b2b2;
    font-size: 20px;
  }
  .rightEmailContain .rightMailTopbar {
    padding: 10px;
    height: 36px;
    border-left: 0.5px solid #545454;
    border-bottom: 0.5px solid #545454;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2px;
  }
  .paginationMail {
    width: 40%;
  }
  .emailContainer .rightEmailContain.mobRgtEmail.hideMobRgtEmail {
    display: none !important;
  }
  .emailContainer .leftEmailMenuBar.mobLftEmail.showMobLftEmail {
    display: flex;
    width: 100%;
  }
  .showMobLftEmail .leftEmailMenu {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    height: 100%;
    max-width: 100%;
    width: 100%;
    background-color: #121212;
    padding-left: 0px;
  }
  .showMobLftEmail .menuTypeMail,
  .showMobLftEmail .menuComposeMail {
    padding: 10px;
    padding-left: 6px;
  }
  .showMobLftEmail .mailType {
    display: flex;
    font-size: 14px;
    padding: 5px 16px;
    height: 28px;
    align-items: center;
    border-radius: 4px;
    transition: all 0.5s;
    cursor: pointer;
    opacity: 0.7;
    margin-bottom: 1px;
  }
  .emailContainerArea .composeMailArea {
    visibility: hidden;
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 9999;
  }
  .composeMailArea .composeMailContainer {
    width: 100vw;
    height: 100vh;
    order: 1;
    z-index: 1000;
    float: right;
  }
  .composeMailContainer .composrMailPesition {
    width: 100vw;
    position: relative;
    height: 100vh;
    min-height: 1px;
    float: left;
  }
  .composrMailPesition .react-multi-email [data-tag] {
    vertical-align: baseline;
    margin: 0.1em;
    background-color: #f3f3f3;
    background-image: none;
    padding: 2px 6px;
    color: rgba(0, 0, 0, 0.6);
    text-transform: none;
    font-weight: 600;
    border: 0 solid transparent;
    border-radius: 0.2rem;
    transition: background 0.1s ease;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    max-width: 100%;
    color: #000;
  }
  .compMailSubject input[type="text"] {
    color: #000;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
  }
  .compMailSubject input::placeholder {
    color: #333;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
  }
  .composrMailPesition .react-multi-email > span[data-placeholder] {
    display: block;
    position: absolute;
    left: 0px;
    font-size: 12px;
    top: 12px;
    padding: 0em;
    line-height: 10px;
    color: #000000;
  }
  .viewEmlAtchFile .atfilNm {
    width: 80px !important;
  }
  .menuComposeMail {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .MuiButtonBase-root.MuiIconButton-root.mobBackToMailList {
    display: inline-flex !important;
  }
  .MuiButtonBase-root.MuiIconButton-root.mobBackToMailList svg {
    color: #b2b2b2;
  }
  .mailListGroup .mailListInd {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 0.5px solid #545454;
    padding: 14px 4px;
    cursor: pointer;
    gap: 4px;
  }

  .mobRgtEmail .mailIndIconsGroup {
    display: flex;
    width: 15vw;
    flex-basis: 15vw;
    max-width: 15vw;
    order: 1;
  }
  .mobRgtEmail .mailUserName {
    flex-basis: 50vw;
    min-width: 50vw;
    max-width: 50vw;
    order: 2;
    padding: 5px 0px;
  }
  .mobRgtEmail .mailTextInfo {
    margin-left: 16vw;
    display: flex;
    flex: 1 1 auto;
    height: auto;
    flex-basis: 95vw;
    width: 95vw;
    max-width: 95vw;
    order: 5;
    min-width: 100px;
  }
  .mobRgtEmail .mailTime {
    letter-spacing: normal;
    flex-basis: 23vw;
    width: 23vw;
    max-width: 23vw;
    justify-content: flex-end;
    padding-left: 0;
    padding-right: 1px;
    order: 3;
  }
  .mobRgtEmail .mailDltIcon {
    order: 4;
    opacity: 0.7;
    transition: all 0.6s;
    flex-basis: 5vw;
    width: 5vw;
    max-width: 5vw;
  }
  .emailContainer .rightEmailViewContain.mobRgtEmail.showMobRgtEmail {
    padding-right: 0px !important;
    overflow-y: overlay;
  }
  .rightEmailViewContain .emailViewContainer {
    padding: 0 0 0 0px;

    height: 100%;
    width: 100%;
  }
  .rightEmailViewContain .emailViewContainer .emailViewArea {
    max-height: inherit;
    overflow: unset;
    overflow-y: unset;
    height: auto;
    width: 100%;
  }
  .rightEmailViewContain .mlbistr {
    display: flex;
    flex-direction: column;
  }
  .rightEmailViewContain .sndtd {
    text-align: right;
    white-space: nowrap;
    vertical-align: top;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-height: 20px;
    font-size: 12px;
  }
  .rightEmailViewContain .emailTitle {
    display: flex;
    display: -webkit-flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
  }
  .rightEmailViewContain .emailSubject {
    font-size: 18px;
    line-height: 28px;
    font-weight: 500 !important;
    margin-left: 6px;
  }
  .emailTitle .emailmarker .MuiButtonBase-root.MuiCheckbox-root {
    padding: 2px 8px;
  }
  .emailViewArea .mlinsdDtlwa {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin: 0px 0px 20px 6px;
  }
  .emailViewArea .MuiCollapse-wrapperInner .MuiAccordionDetails-root {
    padding: 8px 10px 10px;
  }
  .emailViewArea .emailReplayArea {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    gap: 4px;
    width: 100%;
  }
  .emailViewArea .emailReplayArea .emlrlpyBtn {
    padding: 4px 8px;
  }
  .emlRplyContainer .mlbiImg {
    display: none;
  }
  .emlRplyContainer .showMlSummry {
    padding: 0px;
    min-height: 46px !important;
  }
  .emlRplyContainer .mlreplyBdy .mlinsdDtlwa {
    background: #fff;
    padding: 10px;
    padding-top: 10px;
    box-shadow: 0px 2px 7px -3px #00000069;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    margin: 0px 0px 20px 0px;
  }

  .mlinsdDtlwa .mlshmlt {
    display: none;
  }
  .bottomNavConatiner {
    display: none !important;
  }
  .taskViewContainer .taskHeaderPanel {
    overflow-x: overlay;
    padding: 10px 10px 10px 10px;
  }
  .taskHeaderPanel::-webkit-scrollbar {
    width: 0px !important;
    height: 2px !important;
  }
  .taskHeaderPanel::-webkit-scrollbar-thumb {
    background-color: rgb(46, 46, 46);
  }
  .taskHeaderPanel::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.7);
  }
  .meetingsTabContainArea .MuiGrid-root.MuiGrid-item.metConfFullWidth {
    box-sizing: border-box;
    margin: 0;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 100%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 100%;
  }
  .meetingsTabContainerArea.MuiBox-root
    .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-4.fullWidth100,
  .meetingsTabContainerArea.MuiBox-root
    .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-6.fullWidth100 {
    box-sizing: border-box;
    margin: 0;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -webkit-box-flex: 0;
    -webkit-flex-grow: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    max-width: 100%;
    position: absolute;
    width: 100%;
    top: 0px;
    z-index: 1026 !important;
  }
  .fullWidth100 .MeetRightFooter {
    order: 3;
    display: inline-flex;
    padding: 10px 6px 10px 6px;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    background-color: #000;
    border-top: 1px solid #545454;
  }
  .fullWidth100 .meetRightHeader {
    order: 1;
    display: flex;
    width: auto;
    padding: 10px 6px 10px 6px;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    background: #000000;
    border-bottom: 1px solid #545454;
  }
  .fullWidth100 .Meeting_Transcription {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 10px;
    height: calc(100% - 20px);
    width: calc(100% - 12px);
    overflow: hidden;
    overflow-y: auto;
    padding: 10px 6px 10px 6px;
  }
  .Meeting_Transcription iframe {
    width: 100vw !important;
  }

  .anDashCardContainArea .fullContainArea {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
    height: calc(100vh - 136px);
  }
  .e-attendLib .attenadTableLRHeight {
    height: calc(100vh - 312px);
    overflow-y: auto;
  }
  .attendanceTable .tdWith180 {
    width: 150px;
  }
  .attendanceTable .mt-sm-2 h6 {
    margin: 6px 0px;
    font-weight: 400;
    font-size: 14px;
    font-family: "Inter";
    display: -webkit-box;
    width: 110px;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    color: #fff;
    text-overflow: ellipsis;
  }
  .anAttendance .cuHeaderGrp {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    gap: 6px;
  }
  .anAttendance .dashHeadereleGrp {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 4px;
  }
  .anAttendance .searchTasklist {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    border: 1px solid #545454;
    background-color: #333333;
    border-radius: 8px;
    height: 38px;
  }
  .mobChatBack {
    display: flex !important;
  }

  .flotingProfilecontainer {
    height: calc(100dvh - 80px) !important;
    width: 100vw !important;
    position: absolute;
    bottom: 0px;
    right: 0px;
    background-color: #000000;
    transition: all 0.6s;
    animation: profileFloting 1s ease forwards;
    overflow: hidden;
    z-index: 999;
  }
  .ModelBox {
    width: calc(100% - 50px) !important;
    padding: 20px !important;
  }
  .profileFromContainer {
    margin-bottom: 50px;
  }
  .flotingProfilecontainer .profileheader {
    order: 1;
    display: flex;
    width: auto;
    padding: 10px 6px 10px 6px;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    background-color: #000;
    border-bottom: 1px solid #545454;
  }
  .flotingProfilecontainer .profileDtlContainer {
    order: 2;
    flex: 1 1;
    padding: 10px 6px 10px;
    height: calc(100vh - 235px);
  }
  .proImgwthControl .proUserImg {
    height: 100px;
    width: 100px;
    border-radius: 80px;
  }
  .proImgwthControl .changeUserImg {
    height: 36px;
    width: 100px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: 75px;
    border-bottom-right-radius: 75px;
    background: #16161638;
    -webkit-backdrop-filter: blur(1px);
    backdrop-filter: blur(1px);
  }
  .profileDtlContainer .proUserInfo {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;
    width: 100%;
  }
  .proUserDtlStat .proUser_status {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    position: relative;
  }
  .proUserDtlStat .proCompStat {
    position: absolute;
    right: 1px;
    top: 1px;
    background-color: black;
    border-radius: 30px;
  }
  .fromWithEdtUpldBtn .proUserName {
    font-family: Inter;
    font-size: 18px;
    line-height: 24px;
    color: #fff;
  }
  .fromWithEdtUpldBtn .ProUsrDesignation {
    font-family: Inter;
    font-size: 14px;
    line-height: 18px;
    color: #fff;
  }
  .profileDtlContainer .profileFromContainer .formElement {
    width: calc(100% - 42px);
    max-width: calc(100% - 42px);
    min-width: calc(100% - 42px);
  }
  .profileFromContainer .resetPasswordChange {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 14px;
    flex-direction: column;
  }
  .resetPasswordChange {
    width: 100%;
  }
  .profileFromContainer .resetPasswordContainer {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    gap: 14px;
    flex-direction: column;
  }
  .profileheader .proheadNm {
    color: #fff;
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.24px;
  }
  .ModelBox.contactsModel {
    width: calc(100vw - 30px) !important;
    max-height: 80vh !important;
    overflow: hidden !important;
    overflow-y: overlay !important;
    padding: 10px 10px !important;
  }
  .contactsModel .addContactGroup {
    flex-direction: column;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 4px;
    width: 100%;
    margin-top: 20px;
  }
  .contactsModel .addContactElementGrp {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 4px;
    flex: 1 1;
    order: 1;
    width: 100%;
  }
  .contactsModel .formElement.with240 {
    max-width: calc((100% - 8px) / 2);
    width: 100%;
  }
  .contactsModel .AddContactMobBtn {
    display: inline-flex !important;
    border: 1px solid #00adef;
    color: #00adef;
    border-radius: 4px;
  }
  .contactsModel .hideMobile {
    display: none;
  }
  .contactsModel .syncGoogleCont {
    order: 2;
    flex: 100% 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .contacts .ReactTable .rt-thead.-filters,
  .contacts .ReactTable .rt-thead.-header,
  .contacts .ReactTable .rt-tbody {
    min-width: 1000px !important;
  }
  .loginTabs-buttonsGrp .loginTabBtn {
    font-size: 16px !important;
  }
  .meetingControlBtnGroup .meetingTimer {
    position: relative;
    left: 0;
    bottom: 10px;
    z-index: 1;
    height: 65px;
  }

  section.pageNotFoundContainer {
    height: auto !important;
    background-color: #1c1c1c !important;
    padding: 70px 20px 70px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    flex-direction: column !important;
    gap: 10px !important;
  }
  .pageNotFoundContainer .rightArea,
  .pageNotFoundContainer .leftArea {
    width: 100% !important;
  }
  .pageNotFoundContainer .adnText {
    width: 100%;
  }
  .pageNotFoundContainer .adnImgPresent img {
    max-height: 35vh;
    width: auto;
    margin: 0 auto;
  }
  .chatConvoUsrDtl .ctCnvUDeg {
    color: #c0c0c0;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .homeContainerArea .chatConvoHeader {
    display: flex;
    width: auto;
    padding: 8px 6px 8px 6px;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    background: #1c1c1c;
    max-height: 62px;
    border-bottom: 1px solid #1c1c1c;
  }
  .homeContainerArea .chatConvoMsg {
    height: calc(100% - 20px);
    width: calc(100% - 12px);
    overflow: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    padding: 10px 6px 10px 6px;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  .homeContainerArea .chatConvoFooter {
    display: inline-flex;
    padding: 5px 12px 5px 12px;
    align-items: center;
    gap: 16px;
  }
  .homeContainerArea .rightShowDtlPanel {
    width: 100%;
    z-index: 1;
    position: relative;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: calc(100% - 4px);
    justify-content: flex-start;
    overflow: hidden;
  }
  .homeContainerArea .rightShowDtlPanel .dashboardMeetingsContainer {
    width: 100%;
    height: 100%;
  }
  .modelTitle {
    color: #000;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.24px;
  }
  .medtTitleDateTime .medDateTime {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    gap: 4px;
    flex-direction: column;
    width: 100%;
  }
  .medtTitleDateTime .toDateTime,
  .medtTitleDateTime .frmDateTime {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    width: 100%;
  }
  .meetGustDtlElement .MuiAutocomplete-root {
    background-color: #333;
    border-radius: 4px;
    color: white;
    border: 1px solid #545454;
    width: 100%;
  }
  .dashboardTaskContainer {
    height: 100%;
    overflow: hidden;
  }
  .dashboardTaskContainer .RightFlotingContainerArea {
    width: 100vw !important;
    height: calc(100dvh - 80px) !important;
  }
  .taskViewPannel {
    display: flex;
    flex-direction: row;
    height: calc(100dvh - 80px) !important;
    overflow: hidden;
    position: relative;
    border-left: 0.1px solid #545454;
    background-color: #000;
  }
  .taskViewContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow: hidden;
    background-color: #000000;
  }
  .taskViewContainer .taskContain {
    order: 2;
    flex: 1 1;
    padding: 10px 10px 10px;
    height: 100%;
    overflow: hidden;
  }
  .taskContain .taskContainDetail {
    overflow: hidden;
    overflow-y: hidden;
    position: relative;
    scrollbar-width: none;
    -ms-overflow-style: none;
    width: 100%;
    height: 100%;
  }
  .taskContainDetail .taskContDtlContainer {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 24px;
    padding-bottom: 10px;
    height: 100% !important;
    min-height: calc(100% - 110px);
    position: relative;
    overflow: hidden;
    overflow-y: auto;
    box-sizing: border-box;
  }
  .chatContainerArea .chatContactPanel {
    display: flex;
    flex-direction: column;
    height: calc(100dvh - 80px);
  }
  .chatContactPanel .chatCnctLstCont {
    position: relative;
    flex-grow: 1;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    background-color: #121212;
    width: 100%;
    height: calc(100% - 62px);
  }
  .chatConvstionConArea {
    height: calc(100% - 127px);
    width: 100%;
    overflow: hidden;
    overflow-y: hidden !important;
  }

  .newChatGrpPanel {
    display: flex;
    flex-direction: column;
    height: calc(100dvh - 80px) !important;
  }
  .chatCnctLstCont .selectedPartList {
    min-height: 20px;
    max-height: 160px;
    overflow: hidden;
    overflow-y: auto;
    position: relative;
    padding: 0;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 4px 6px 4px 6px;
    margin-top: 10px;
    flex: none;
  }
  .scuRmv {
    height: 18px;
    width: 18px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s;
    color: #a9a9a9;
    background-color: #333333;
  }
  .dashboardChatContainer .chatConversationPannel {
    height: calc(100dvh - 80px) !important;
  }

  .chatConvoMsg .chtMsg .msg {
    position: relative;
    box-shadow: 0 1px 0.5px rgba(0, 0, 0, 0.13);
    max-width: 90%;
    display: inline-flex;
    padding: 6px 10px;
    flex-direction: column;
    align-items: flex-end;
    word-break: break-word;
  }
  .rfContContainDetails .indUsrAttend {
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 10px;
  }
  .rfContContainDetails .dnPayShilp {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 4px;
    flex-direction: row;
    width: 100%;
  }
  .tab-content .modelContainer {
    height: 100%;
    width: 100%;
    background-color: #000000c9;
    position: fixed;
    top: 0;
    left: 0;
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: none;
    z-index: 1024;
  }
  .previewDocument.modelContainer .modelContain.fullwidthModel {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -45%);
    width: 96vw;
    min-height: 20vh;
    height: auto;
    max-height: 80dvh;
    overflow: hidden;
    overflow-y: hidden;
    animation-name: fadeInUp;
    -webkit-animation-name: fadeInUp;
    z-index: 1025;
  }
  .previewDocument .fwModelContainArea {
    background-color: #fff;
    height: 100%;
    max-height: calc(80dvh - 60px);
    min-height: 20vh;
    width: calc(100% - 60px);
    border-radius: 20px;
    padding: 30px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 26px;
    position: relative;
    overflow: overlay;
  }
  .previewDocument .loiFooter {
    margin-bottom: 50px;
  }
  .previewDocument .modActionBtnGrp {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    order: 3;
    gap: 10px;
    flex-direction: row;
    position: fixed;
    left: 0;
    bottom: 0;
    background-color: #fff;
    padding: 10px;
    width: calc(100% - 20px) !important;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  .previewDocument table.salaryTable {
    width: 100%;
    height: 100%;
    border-collapse: collapse;
    border: 1px solid #b2b2b2;
    margin-bottom: 70px;
  }
  .bgOfset .ModelBox {
    width: calc(100% - 70px);
  }
  .bgOfset .width800 {
    min-width: calc(100% - 70px);
    width: calc(100% - 70px);
  }
  .taskCardContainer .tskUser {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 16px !important;
  }
  .chatAtchImgContainer {
    min-width: 100% !important;
  }
  .chatAtchImgContainer .chatAtchImgActionGrp {
    padding: 8px 0;
    margin: 0 10px;
    border-top: 1px solid #8696a026;
    display: flex;
    flex: 0 0 14px;
    align-items: center;
    justify-content: space-between;
  }
  .chatAtchImgActionGrp .upldFlSmalPerview {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 0;
    min-width: 0;
    order: 0;
    align-self: auto;
    flex-wrap: wrap;
    justify-content: center;
    margin: 4px 10px 0;
    box-sizing: border-box;
    flex: 1 1 auto;
  }
  .dropToUupledImg {
    display: none !important;
  }
  .subcriptionContainerArea .subcriptionContainer {
    padding: 20px 16px 20px 16px;
    width: calc(100% - 32px);
    height: calc(100dvh - 120px);
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 10px;
    overflow: hidden;
  }
  .showActivePlan .profesionalPlanContainer,
  .showActivePlan .persoanlPlanContainer {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
    flex-direction: column;
    overflow: hidden;
    overflow-y: overlay;
  }
  .persoanlPlanContainer .otherPlanShow,
  .persoanlPlanContainer .personalPlanView {
    width: 100%;
    height: auto;
    animation: none !important;
  }
  .planContainer .planDetails {
    padding: 20px;
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 30px;
    overflow: hidden;
    overflow-y: overlay;
  }
  .planHeader .planName {
    font-family: "Russo One", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 20px;
    line-height: 20px;
    text-transform: uppercase;
  }
  .planHeader .planPrice {
    font-family: "Russo One", sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 32px;
    line-height: 32px;
    letter-spacing: 1px;
  }
  .planContainer .planHeader {
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #ffffff;
    padding: 8px 16px;
    width: calc(100% - 32px);
    cursor: pointer;
    flex-wrap: wrap;
  }
  .otherPlanShow .professionalPlanOverView {
    height: 100%;
  }
  .planContainer .planDetailsShow {
    padding: 0px;
    display: flex;
    flex-direction: column;
    height: auto;
    gap: 10px;
    overflow: hidden;
    overflow-y: overlay;
    align-items: normal;
    justify-content: space-between;
    padding-bottom: 10px;
  }
  .proInfoBtnGrp .proBtnInfo.MuiButtonBase-root.MuiButton-root {
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    font-family: Inter;
    color: #fff;
    text-transform: uppercase;
    border: 1px solid #fff;
    border-radius: 0px;
    background-color: #f39c12;
    padding: 8px 18px;
    height: 40px;
    transition: all 0.4s;
  }
  .modelContainer .modelContain.fullwidthModel {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100vw - 10px);
    min-height: 20vh;
    height: auto;
    max-height: 90vh;
    overflow: hidden;
    overflow-y: overlay;
    animation-name: fadeInUp;
    -webkit-animation-name: fadeInUp;
  }
  .fwModelContainArea .fwmHeader {
    font-size: 20px;
    font-family: Inter;
    font-weight: 700;
    text-transform: capitalize;
    position: sticky;
    top: 4px;
    padding: 20px 0px 10px;
    background-color: #fff;
    height: 36px;
    z-index: 1024;
  }
  .modelContain .fwModelContainArea {
    background-color: #fff;
    height: 100%;
    min-height: 20vh;
    width: calc(100% - 60px);
    border-radius: 20px;
    padding: 30px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 26px;
  }
  .fwModelContainArea .modActionBtnGrp.actionsCreateOrg {
    position: sticky;
    bottom: 0;
    background-color: #fff;
    height: 50px;
    z-index: 1024;
  }
  .showActivePlan .planContainer {
    height: 100%;
    width: calc(100% - 2px);
    border: 1px solid #777777;
    background-color: #000000;
  }
  .profesionalPlanContainer .proOtherPlanShow {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100%;
    order: 2;
  }
  .profesionalPlanContainer .profesionalPlanView {
    width: 100%;
    height: 100%;
    animation: none !important;
  }
  .profesionalPlanView .planContainer .planHeader {
    height: auto;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    color: #ffffff;
    padding: 8px 16px;
    width: calc(100% - 32px);
    cursor: pointer;
    flex-wrap: wrap;
    gap: 10px;
  }
  .profesionalPlanView .plNm_Price {
    display: flex;
    align-items: flex-start;

    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
  }
  .proPlanServGrid .gridColumn {
    flex: 100% !important;
    max-width: 100% !important;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
    border-right: 1px solid #545454;
  }
  .proInfoBtnFloat {
    display: none !important;
  }
  .profesionalPlanView .proPlanDetails {
    padding: 0px;
    display: flex;
    flex-direction: column;
    height: calc(100% - 145px);
    overflow: hidden;
    overflow-y: overlay;
  }
  .elementFormContainer .elementWithPostTextGrp {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 6px;
    flex-wrap: wrap;
  }
  .modelContainer .modelContain {
    animation-name: fadeInUp;
    -webkit-animation-name: fadeInUp;
    height: auto;
    left: 50%;
    max-height: 90vh;
    overflow: hidden;
    overflow-y: overlay;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }

  /* start landing page */
  .lp-full-container .funtionDtlSec {
    height: calc(100dvh - 60px);
    background-color: #111111;
    padding: 30px 20px 30px;
  }
  .lp-full-container .viewallBtncont {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }
  .lp-full-container .MuiButtonBase-root.MuiButton-root.vabtn {
    position: relative;
    padding: 14px 50px;
    color: #fff;
    text-decoration: none;
    display: inline-block;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    font-size: 16px;
    font-weight: 500;
    border: 2px solid transparent;
    transition: 0.5s;
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -ms-transition: 0.5s;
    -o-transition: 0.5s;
  }
  .featuresLandingPage .digContnr {
    z-index: 1;
    position: relative;
    height: 100vh;
    margin-top: 60px;
  }
  .featuresLandingPage .fetursHeading {
    font-size: 22px;
    font-weight: 800;
    line-height: 26px;
    color: #fff;
    letter-spacing: 0.5px;
  }
  .featuresLandingPage .fetursSubtxt {
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    color: #fff;
    letter-spacing: 0.5px;
  }
  .lp-full-container .featuresDetailsPage {
    padding: 50px 20px 50px;
    display: flex;
    min-height: 100vh;
    background-color: #111111;
  }
  .lp-full-container .featuresDetailsSect {
    padding: 50px 16px;
    height: auto;
    position: relative;
    z-index: 1;
  }
  .lp-full-container .zoyelFeatureList.stickyLeft {
    height: auto;
    padding: 2px;
    top: 20px;
  }
  .fetContainDtl .fethead {
    color: #fff;
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    margin-bottom: 16px;
  }
  .fetures-menu-toggle {
    height: 46px;
    width: 46px;
    border-radius: 46px;
    background-color: #545454;
    color: #fff;
    border: none;
    font-size: 24px;
    font-weight: 500;
  }
  .zoyelFeatureListDesktopView {
    display: none;
  }
  .fetContainDtl .fetText {
    font-size: 14px;
    line-height: 18px;
    color: #fff;
    font-weight: 400;
    opacity: 0.8;
  }
  .mobile-menu {
    position: relative;
    z-index: 10;
    display: flex !important;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
  }
  .feturesMenuTxt {
    font-size: 16px;
    font-weight: 400;
    font-style: italic;
    color: #b2b2b2;
  }
  .mob-menu-list {
    position: absolute;
    width: calc(100% - 32px);
    background-color: #232323;
    padding: 16px;
    top: 30px;
    z-index: 99;
  }
  .zIndex10 {
    z-index: 10;
  }
  .featuresLandingPage .zoyelHeadingContain {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 20px;
    top: 50%;
    transform: translateY(-50%);
    width: calc(100% - 32px);
  }
  /* end landing page */
  .supportOption .support-button {
    display: none;
  }
  .lightMode
    .supportOption
    .MuiButtonBase-root.MuiIconButton-root.support-button-mob {
    color: #545454;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .supportOption .MuiButtonBase-root.MuiIconButton-root.support-button-mob {
    color: #cccccc;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .mxW-100.supportOption {
    max-width: 40px !important;
    margin-right: 4px;
  }
  .formElement.mxW-100.docOnOffOption {
    max-width: 46px !important;
    margin-right: 4px;
  }
  .formElement.docOnOffOption .MuiFormControlLabel-root .MuiTypography-root {
    display: none;
  }
  .formElement.mxW-100.langOption {
    max-width: 90px !important;
  }
  .MuiToolbar-root .MuiTypography-root.MuiTypography-h5.orgNameTypography {
    opacity: 0;
  }
  .usercardGrp.MuiBox-root .userInfo .userName {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    color: #fff;
    display: -webkit-box;
    font-family: Inter;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px;
  }
  .RightFlotingContainerArea {
    height: calc(100vh - 80px);
    width: 100vw !important;
    position: absolute;
    bottom: 0px;
    right: 0px;
    background-color: #000000;
    z-index: 99;
  }
  .botMsg {
    width: 100% !important;
  }
  .tskTitleGrpWthCenter .tskTitle {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .tskTitleGrpWthCenter .tskTitle .pInfoGrp {
    margin-left: 0px;
    font-size: 12px;
  }
  .lightMode .usercardGrp.MuiBox-root .userInfo .userName {
    color: #1c1c1c;
  }
  .bmiGroup {
    display: flex !important;
    align-items: flex-start !important;
    gap: 10px !important;
    width: 100% !important;
    margin-top: 1rem !important;
  }
  .c-bmi__groups input:checked + label::before {
    background-color: #fff;
    clip-path: polygon(0% 0%, 0% 100%, 75% 50%);
    content: "";
    display: inline-block;
    height: 1rem;
    left: 0.5rem;
    position: absolute;
    top: 0rem;
    width: 1rem;
  }
  .addNewformGrp {
    flex-wrap: wrap !important;
  }
  .addNewformGrp .mxW-150 {
    max-width: 115px !important;
  }
  .meetRightContainDtl {
    height: calc(100dvh - 80px) !important;
  }
  .MeetingTranscriptionContainer {
    overflow-y: scroll !important;
  }
  .MeetRightFooter .meetRightFootBtnGrp {
    flex-wrap: wrap;
  }
  .feedBackContainer {
    padding: 0px 10px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    overflow: hidden;
    height: calc(100dvh - 80px);
  }
  .checkWeekControngrp {
    flex-direction: column !important;
  }
  .checkDaysSettings {
    flex-direction: column;
    align-items: flex-start !important;
  }
  .aboutContain {
    padding: 50px 10px 50px;
  }
  .navChangeLang {
    right: 152px !important;
  }
}
