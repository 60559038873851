.supportContainer {
  /* padding: 10px 20px 10px 20px; */
  color: white !important;
}

.supportContainer .supportHeader {
  padding: 10px 20px 10px 20px;
  border-bottom: 1px solid gray;
  display: flex;
  justify-content: space-between;
}

.supportContainer .supportTitle {
  font-size: 20px;
  display: flex;
}

.supportContainer .supportClose .MuiIconButton-root {
  color: #808080 !important ;
}

.supportContainer .supportBody {
  padding: 10px 20px 10px 20px;
  list-style: none;
  overflow-y: scroll !important;
  overflow-x: hidden !important;
  flex-grow: 1;
  border-radius: 4px;
  height: calc(100vh - 220px);
}

.supportContainer .supportBodyTitle {
  font-size: 16px;
}

.supportContainer .css-vqmohf-MuiButtonBase-root-MuiRadio-root {
  color: white !important;
}

.supportContainer .supportFooter {
  order: 3;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 6px 24px 10px 24px;
  border-top: 1px solid #5454548a;
}

.showFFcontainGrp .showBugsContainer[aria-hidden="true"],
.showFFcontainGrp .showSuggetionContainer[aria-hidden="true"] {
  display: none;
}

.showFFcontainGrp .coustomDDFF {
  margin: 10px 0px 6px;
}
.showFFcontainGrp .coustomDDFF .MuiInputBase-root {
  border-radius: 8px;
  background: #fff;
}

.showFFcontainGrp
  .coustomDDFF
  .MuiInputBase-root
  .MuiOutlinedInput-notchedOutline {
  border-color: #c8cdd3;
  border-width: 1px;
}
.showFFcontainGrp .coustomTextArea {
  background-color: #fff;
  border: 1px solid #c8cdd3;
  width: 100%;
  border-radius: 8px;
  padding: 8px;
  font-size: 12px;
  line-height: 16px;
  color: #3e3e3e;
  outline: none;
}
.showFFcontainGrp .attachSrcBtn {
  background-color: #d9d9d9;
  color: #3e3e3e;
  width: 100%;
  font-size: 12px;
  margin: 10px 0px 20px;
  border-radius: 20px;
  transform: all 0.6s;
}
.showFFcontainGrp .attachSrcBtn:hover {
  background-color: #9e9e9e;
  color: #000000;
}
.showFFcontainGrp .attachSrcBtn svg {
  font-size: 14px;
}

.supportFooter .sendFeedBtn {
  color: #ffffff;
  width: 30%;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  transform: all 0.6s;
}
.supportFooter .sendFeedBtn:hover {
  background-color: #000000;
  color: #ffffff;
}
.supportFooter .sendFeedBtn svg {
  font-size: 16px;
}

.support-button {
  background-color: transparent !important;
  color: #fff;
  border-radius: 20px;
  padding: 8px 16px;
  text-transform: none;
  font-size: 14px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.support-button:hover {
  background-color: #e68a00;
}

.support-button .MuiButton-startIcon {
  margin-right: 8px;
}

.supportIdLink {
  text-decoration: underline;
  color: #a7a7ff !important;
}

.supportIdLink:hover {
  color: #6363ff !important;
}

/* .supportPriorityLow {
  color: limegreen !important;
}

.supportPriorityMedium {
  color: yellow !important;
}

.supportPriorityHigh {
  color: red !important;
} */

.supportPriorityHigh {
  border-left: 5px solid #f90000;
}

.supportPriorityMedium {
  border-left: 5px solid #edb900;
}

.supportPriorityLow {
  border-left: 5px solid #03ab00;
}

.supportPriorityChange.request {
  border-left: 5px solid #970eff;
}
.supportMWSearchBy {
  max-width: 150px !important;
}

.searchTicketSupport {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* width: 320px; */
  border: 1px solid #707070;
  background-color: #333333;
  border-radius: 4px;
  height: 38px;
  max-width: 500px !important;
}
.seacrhIconSupport {
  color: white !important;
}

/* Container to hold the text and the star */
.star-text-support {
  position: relative;
  display: inline-block;
}

/* Blinking red star */
.star-text-support::before {
  content: "★";
  color: red;
  position: absolute;
  top: -6px;
  left: -10px;
  font-size: 14px;
  animation: blink 1s infinite;
}
.plr-10 {
  padding: 0 10px !important;
}
/* Keyframes for the blinking animation */
@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.hide-right-support-form {
  display: none !important;
}

.supportSendMessage {
  flex: 1 1;
  border: none;
  outline: none;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background-color: transparent;
  padding: 10px 10px 6px 22px;
  height: 28px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
}
.imageBotReply {
  max-width: 485px !important;
  padding: 10px;
  border: 2px solid black;
  border-radius: 10px;
  margin-top: 10px;
}
.supportOption .support-button-mob {
  display: none;
}
.trashSupTicketFiles {
  position: relative !important;
  right: 11px !important;
  color: red !important;
  cursor: pointer !important;
}
.feedbackFormSupport {
  width: 44vw !important;
}
.supportFeedbackContainer {
  padding: 0px 20px !important;
}

.supportTitle .MuiButton-contained {
  text-transform: capitalize;
  font-weight: 500;
  font-size: 14px;
  background: #4c4c4c;
  padding: 6px 10px;
  border-radius: 4px;
  border: 1px solid #4c4c4c;
  display: flex;
  align-items: center;
  color: #a2a2a2;
  max-height: 32px;
  margin-left: 10px !important;
}

.supportTitle .MuiButton-contained.Mui-disabled {
  background: #4c4c4c;
  color: #fff;
}
.supportTitle .MuiButton-contained .MuiButton-startIcon {
  margin-left: 0px;
}

.supportTitle .MuiButton-outlined .MuiButton-startIcon {
  margin-left: 0px;
}
.supportTitle .MuiButton-contained:hover {
  color: #fff;
  background: #4c4c4c;
}
.supportTitle .MuiButton-outlined:hover {
  border: 1px solid #4c4c4c;
  color: #fff;
}
.supportTitle .MuiButton-outlined {
  text-transform: capitalize;
  font-weight: 500;
  font-size: 14px;
  background: transparent;
  padding: 6px 10px;
  border-radius: 4px;
  border: 1px solid #4c4c4c;
  display: flex;
  align-items: center;
  color: #a2a2a2;
  max-height: 32px;
}

.supportTicketWithStat{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}
.tickStatGrp .MuiButtonBase-root.MuiCheckbox-root{
padding: 4px;
color: #eeeeee;
}
.tickStatGrp .MuiFormControlLabel-root .MuiTypography-root.MuiTypography-body1{
color: #eeeeee !important;
}
.tickStatGrp .MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementEnd{
  margin-right: 40px;
}