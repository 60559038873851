.meetingsTabContainerArea {
  height: 100%;
  width: 100%;
}
.meetingsViewEditPannel {
  display: flex;
  flex-direction: row;
  height: calc(100vh - 80px);
  overflow: hidden;
  position: relative;
  border-left: 0.1px solid #545454;
  background-color: #000;
}
.meetingsTabContainArea {
  height: 100%;
  width: 100%;
}
.meetingsShowAreaContain {
  padding: 10px 70px 10px 70px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  height: calc(100vh - 100px);
  width: calc(100% - 140px);
  overflow: hidden;
}
.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-8 .meetingsShowAreaContain {
  padding: 10px 10px 10px 70px;
  width: calc(100% - 80px);
}
.meetingVEContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: #000000;
}
.metngVEHeaderPnl {
  display: flex;
  width: auto;
  padding: 16px 24px 16px 24px;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  background-color: #000;
  border-bottom: 1px solid #545454;
}
.metVEControlBtnGrp {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
.metVEActionBtnGrp {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}
.metVEContBtn .MuiButton-outlined {
  text-transform: capitalize;
  font-weight: 500;
  font-size: 14px;
  background: transparent;
  padding: 6px 10px;
  border-radius: 4px;
  border: 1px solid #4c4c4c;
  display: flex;
  align-items: center;
  color: #a2a2a2;
  max-height: 36px;
}
.metVEContBtn .MuiButton-contained {
  text-transform: capitalize;
  font-weight: 500;
  font-size: 14px;
  background: #4c4c4c;
  padding: 6px 10px;
  border-radius: 4px;
  border: 1px solid #4c4c4c;
  display: flex;
  align-items: center;
  color: #a2a2a2;
  max-height: 36px;
}
.metVEContBtn .MuiButton-contained .MuiButton-startIcon {
  margin-left: 0px;
}
.metVEContBtn .MuiButton-outlined:hover {
  border: 1px solid #4c4c4c;
  color: #fff;
}
.metVEContBtn .MuiButton-contained:hover {
  color: #fff;
  background: #4c4c4c;
}

.metVEActBtn .MuiIconButton-root {
  color: #808080;
  height: 36px;
  width: 36px;
}
.metVEActBtn .MuiIconButton-root:hover {
  color: #ffffff;
}
.closeBtnD10 {
  margin-left: 10px;
}
.meetVEContainDtl {
  order: 2;
  flex: 1;
  padding: 15px 25px 10px;
  height: calc(100vh - 235px);
}
.meetTitle_Time {
  display: inline-flex;
  padding: var(--numberLength, 0px);
  flex-direction: column;
  align-items: flex-start;
  gap: 7px;
}
.meetTitle_Time .meetTitle {
  color: #fff;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.meetTitle_Time .meetDTime {
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.meetAgendaElement {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}
.metAgendaElementTitle {
  color: #fff;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  opacity: 0.7;
}
.metAgendaElementDtil {
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.meetGustDtlElement,
.meetDtlElement {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3px;
  width: 100%;
}
.metGustDtlElementTitle,
.metDtlElementTitle {
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  /* letter-spacing: -0.14px; */
  opacity: 0.7;
}
.metDtlElementDtil {
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.meetROVContainer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 20px;
}
.meetROVContainer .meetLnkBtnGrp {
  gap: 3px;
}
.meetROVContainer .copyLink svg {
  color: #707070;
  transition: all 0.5s;
}

.meetROVContainer .copyLink svg:hover {
  color: #ffffff;
}
.metGustLstGrp {
  margin-top: 9px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  gap: 14px;
  width: 100%;
}
.metGustLstInd {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  cursor: pointer;
  transition: all 0.6s;
  width: 100%;
}
.metGstImg {
  height: 26px;
  width: 26px;
  border-radius: 16px;
  border: 2px solid #fff;
  position: relative;
}
.metConVerd {
  position: absolute;
  bottom: -3px;
  right: -6px;
  height: 14px;
  width: 14px;
  /* display: none; */
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: #ffffff;
}
.metConVerd svg {
  color: #00af1c;
  height: 18px;
  width: 18px;
}

.metConRej {
  position: absolute;
  bottom: -3px;
  right: -6px;
  height: 14px;
  width: 14px;
  /* display: none; */
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: #ffffff;
}
.metConRej svg {
  color: #ff0000;
  height: 18px;
  width: 18px;
  position: relative;
  bottom: 2px;
  right: 2px;
}

.metConNS {
  position: absolute;
  bottom: -3px;
  right: -6px;
  height: 14px;
  width: 14px;
  /* display: none; */
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: #ffffff;
}
.metConNS svg {
  color: #ffae00;
  height: 18px;
  width: 18px;
}

.metGstImg img {
  height: 100%;
  width: 100%;
  border-radius: 16px;
  object-fit: cover;
}
.metGstName {
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  display: flex;
  align-items: center;
}
.metGstName span.metConvenor {
  color: #a0a0a0;
  margin-left: 6px;
  /* display: none; */
}
.gustAsConvenor .metConVerd,
.gustAsConvenor .medConRej,
.gustAsConvenor .medConNS,
.gustAsConvenor .metGstName span.metConvenor {
  display: flex;
}
.meetingsEditedView,
.meetingsRedOnlyView {
  overflow: hidden;
  overflow-y: overlay;
  position: relative;
  width: 100%;
  height: 100%;
}

.meetingsEditedView::-webkit-scrollbar,
.meetingsRedOnlyView::-webkit-scrollbar {
  width: 0px !important;
  height: 6px !important;
}
.meetingsEditedView::-webkit-scrollbar-thumb,
.meetingsRedOnlyView::-webkit-scrollbar-thumb {
  background-color: rgba(107, 107, 107, 1);
}
.meetingsEditedView::-webkit-scrollbar-track,
.meetingsRedOnlyView::-webkit-scrollbar-track {
  background: rgb(18 18 18 / 70%);
}
.meetMOMBtn .MuiButton-root,
.meetAddNoteBTn .MuiButton-root {
  text-transform: capitalize;
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.medtTitleDateTime {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 14px;
  width: 100%;
}
.meetEditContainer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 10px;
}
.medtTitle {
  width: 100%;
}
.mtTextField {
  background-color: #333333;
  border-radius: 8px;
  font-size: 24px;
  font-family: Inter;
  color: #ffffff;
  padding: 10px;
  border: none;
  outline: none;
  transition: all 0.8s;
  min-width: 200px;
  max-width: calc(100% - 20px);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-bottom: 1px solid #333333;
}
.mtTextField:hover {
  background-color: #606060;
  width: calc(100% - 20px) !important;
  cursor: pointer;
}
.mtTextField:focus-visible {
  width: calc(100% - 20px) !important;
  border-bottom: 1px solid #fff;
  background-color: #606060;
  cursor: text;
}
.mtTextField::placeholder {
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 20px;
}
.slctFrmTime {
  background-color: #4c4c4c;
}
.slctFrmTime .MuiSvgIcon-root {
  color: #ffffff;
}
.toDateInput,
.frmDateInput {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 8px 6px;
  border-radius: 4px;
  background-color: #333;
  gap: 4px;
  transition: all 0.8s;
  border: 1px solid #545454;
}
.toDateInput:hover,
.frmDateInput:hover {
  background-color: #606060;
}
.frmDaysPick {
  font-size: 14px;
  font-weight: 400;
  font-family: Inter;
  line-height: 14px;
  color: #fff;
}
.frmdatePick {
  font-size: 14px;
  font-weight: 400;
  font-family: Inter;
  line-height: 14px;
  border: none;
  outline: none;
  color: #fff;
  background-color: transparent;
  max-width: 92px;
  padding: 0;
  cursor: pointer;
}
.toDateTime,
.frmDateTime {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}
.medDateTime {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  gap: 4px;
}
.slctFrmTime {
  padding: 8px 6px !important;
  border-radius: 4px !important;
  background-color: #333333 !important;
  color: #ffff !important;
  font-family: Inter !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  outline: none !important;
  border: 1px solid #545454 !important;
  min-width: 94px;
  max-width: 111px;
  transition: all 0.8s;
}
.slctFrmTime:hover {
  background-color: #606060 !important;
}
.slctFrmTime .MuiSelect-select {
  padding: 0px;
  padding-right: 20px !important;
}
.MuiOutlinedInput-notchedOutline {
  border: none !important;
}
/* .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
  max-height: 180px !important;
} */

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded::-webkit-scrollbar-thumb {
  background-color: rgba(107, 107, 107, 1);
}
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded::-webkit-scrollbar-track {
  background: rgb(18 18 18 / 70%);
}
.metAgendaListed {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
.metAgendaName {
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  background: #333;
  transition: all 0.6s;
}
.metAgendaName:hover {
  background-color: #606060 !important;
}
.metAgendaNM {
  display: -webkit-box;
  max-width: 220px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: #fff;
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}
.metAgenFilIoc svg {
  color: #707070;
  transition: all 0.6s;
}
.metAgenFilNm {
  display: -webkit-box;
  width: 64px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: #fff;
  opacity: 0.6;
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.14px;
}
.metFileTolTip {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 5px;
}
.metAgndFil {
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #707070;
  width: fit-content;
  transition: all 0.6s;
}
.metAgndFil:hover {
  background-color: #606060 !important;
}
.metAgndFil:hover .metAgenFilIoc svg {
  color: #fff;
}
.metAgenFilIoc {
  height: 24px;
  width: 24px;
  display: flex;
}
.metAgndFilsgrp {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}
.metAgendaMrFileBtn {
  padding: 2px !important;
  border-radius: 4px !important;
  height: 34px !important;
  width: 34px !important;
  min-width: 34px !important;
  border: 1px solid #707070 !important;
  transition: all 0.6s;
}

.metAgendaMrFileBtn svg {
  color: #707070 !important;
  height: 30px !important;
  width: 30px !important;
  font-size: 30px !important;
  transition: all 0.6s;
}
.metAgendaMrFileBtn:hover {
  border: 1px solid #ffffff !important;
}
.metAgendaMrFileBtn:hover svg {
  color: #fff !important;
}
.metAgendaElementEditedGrp {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 12px;
  flex-direction: column;
}
.meetEdAgendaElement {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}
.metAddMRAgendaBtn .MuiButtonBase-root.MuiButton-root {
  color: #707070;
  border-color: #707070;
  padding: 6px 20px;
  text-transform: capitalize;
  font-size: 14px;
  line-height: normal;
  transition: all 0.6s;
}
.metAddMRAgendaBtn .MuiButtonBase-root.MuiButton-root:hover {
  color: #000000;
  border-color: #ffffff;
  background-color: #ffffff;
}

.meetEDDesElement {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;
  flex-direction: column;
  width: 100%;
}
.metEDDtlElementDtil {
  background-color: #333333;
  border-radius: 8px;
  padding: 10px;
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  width: calc(100% - 20px);
}
.metGustLstInd:hover .mkAsConvenorBtnGrp {
  display: inline-flex;
}
.gustAsConvenor.metGustLstInd:hover .mkAsConvenorBtnGrp {
  display: none !important;
}
.mkAsConvenorBtnGrp {
  display: none;
  transition: all 0.8s;
}
.mkAsConvenorBtnGrp button {
  display: inline-flex;
  padding: 8px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background-color: #333;
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  text-transform: capitalize;
  transition: all 0.6s;
}
.mkAsConvenorBtnGrp button:hover {
  background-color: #545454;
}
.metEDAddGuestBTN {
  margin: 10px 0px 6px;
}
.metEDAddGuestBTN button {
  padding: 8px 24px;
  font-family: Inter;
  font-size: 16px;
  line-height: 18px;
  background-color: #333333;
  border-radius: 8px;
  color: #fff;
  text-transform: capitalize;
  transition: all 0.8s;
}
.metEDAddGuestBTN button:hover {
  background-color: #545454;
}
.metngVEFooterPnl {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  border-top: 1px solid #545454;
  order: 3;
  gap: 10px;
  flex-direction: row;
}

.dfultPrimaryBtn {
  padding: 6px 16px !important;
  font-size: 14px !important;
  border-radius: 4px !important;
  background-color: #00adef !important;
  color: #fff !important;
  font-family: Inter !important;
  transition: all 0.8s !important;
  border: 1.6px solid #00adef !important;
  text-transform: capitalize !important;
  font-weight: 500;
  min-width: 106px !important;
}

.dfultPrimaryBtn:hover {
  background-color: #1976d2 !important;
  border: 1.6px solid #1976d2 !important;
}

.dfultSecondaryBtn {
  padding: 6px 16px !important;
  font-size: 14px !important;
  border-radius: 4px !important;
  background-color: transparent !important;
  border: 1.6px solid #fff !important;
  color: #fff !important;
  font-family: Inter !important;
  transition: all 0.8s !important;
  text-transform: capitalize !important;
  font-weight: 500;
  min-width: 106px !important;
}

.dfultSecondaryBtn:hover {
  background-color: #ffffff !important;
  color: #000 !important;
}

.addMoreAgendaModel {
  display: flex;
  padding: 30px;
  flex-direction: column;
  align-items: flex-start;
  gap: 22px;
  border-radius: 8px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  width: 60%;
  height: auto;
}
.modelTitle {
  color: #000;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 100% */
  letter-spacing: -0.24px;
}
.modInputFild {
  width: 100%;
}
.modInputFild input {
  display: flex;
  width: calc(100% - 16px);
  padding: 8px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid #eee;
  color: #000000;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.14px;
  outline: none;
}
.modAtchFileBtn {
  border-radius: 4px;
  border: 1px solid #00adef;
  display: flex;
  height: 28px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  color: #00adef;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
  letter-spacing: -0.14px;
  cursor: pointer;
  min-width: 106px !important;
}
.modAtchFileBtn .btn-up {
  cursor: pointer;
  padding: 3px 17px;
}
.dfultDarkSecondaryBtn {
  padding: 6px 16px !important;
  font-size: 14px !important;
  border-radius: 4px !important;
  background-color: transparent !important;
  border: 1.6px solid #00adef !important;
  color: #00adef !important;
  font-family: Inter !important;
  transition: all 0.8s !important;
  text-transform: capitalize !important;
  font-weight: 500;
  transition: all 0.6s;
  min-width: 106px !important;
}
.dfultDarkSecondaryBtn:hover {
  background-color: #00adef !important;
  color: #fff !important;
}
.modActionBtnGrp {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  order: 3;
  gap: 10px;
  flex-direction: row;
}
.ModelBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: #fff;
  border-radius: 8px;
  z-index: 1;
}
.ModelBox .addMoreAgendaModel {
  position: relative;
  top: auto;
  left: auto;
  transform: translate(0px, 0px);
  width: 100%;
  padding: 0px;
  box-shadow: none;
  border-radius: 0px;
}
.metAddMRAgendaBtn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}

.fullscreenmeeting {
  height: calc(100vh - 100px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 10px;
}

.joinMeetInstanceRoomidContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  width: 100%;
}
.joinMeetInstanceRoomid {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.joinMetWthRmIDGrp {
  display: flex;
  align-items: stretch;
  justify-content: center;
  padding: 0px;
  background-color: #272727;
  border-radius: 8px;
}
.joinMetRmIdInputBox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #272727;
  outline: none;
  border: none;
  box-shadow: none;
  padding: 4px 20px;
  width: calc(100% - 40px);
  max-width: 320px;
  min-width: 300px;
  font-size: 16px;
  line-height: 28px;
  height: 36px;
  color: #fff;
  border-radius: 8px;
  font-family: Inter;
}
.joinRumIDBtn button {
  width: 100px !important;
  height: 100%;
  border-radius: 0px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  font-size: 16px;
  text-transform: capitalize;
  background-color: #000;
  box-shadow: none;
  font-family: Inter;
}
.joinRumIDBtn button:hover {
  background-color: #00adef;
}
.joinMeetingBtnGroup .joinInsMetBtn {
  border-radius: 8px;
  font-size: 16px;
  font-family: Inter;
  text-transform: capitalize;
  background-color: #00adef;
  color: #ffffff;
  box-shadow: none;
  padding: 6px 28px;
}
.joinMeetingBtnGroup .joinInsMetBtn:hover {
  background-color: #000000;
}
.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-6
  .meetingControlBtnGroup
  .meetingTimer {
  bottom: 0;
  left: 2px;
  position: relative;
  z-index: 1024;
}
.meetingControlBtnGroup {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 10px;
  border-radius: 66px;
  background-color: #333333;
  gap: 12px;
  position: relative;
}
.meetContBtn button {
  background-color: #575556;
  color: #fff;
  padding: 8px;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  transition: all 0.5s;
}
.meetContBtn button svg {
  font-size: 24px;
  line-height: 22px;
}
.meetContBtn button:hover {
  background-color: #212121;
}
.meetContBtn.callEnd button {
  background-color: #e12b2d;
  color: #fff;
  padding: 8px;
  height: 40px;
  width: 80px;
  border-radius: 20px;
}
.meetContBtn.callEnd button:hover {
  background-color: #b50c0e;
}
.ml5 {
  margin-left: 3rem;
}
.or {
  color: #fff;
}
.meetContBtn.metBtnActive button {
  background-color: #ffffff;
  /* color: #00adef; */
  color: #ff9900;
}
.meetContBtn.metBtnActive button:hover {
  background-color: #212121;
}

.moreMenuOptionView .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
  max-height: 70vh !important;
  margin-bottom: 20px;
}

.meetMrMnuBtn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
}

.meetMrMnuBtn svg {
  height: 22px;
  width: 22px;
  color: #575556;
}
.meetMrMnuBtn span {
  font-size: 14px;
  font-family: Inter;
}

.meetMrMnuBtn .MuiBadge-colorPrimary {
  background-color: #ff9900;
}

.meetingTabRightPanel {
  height: 100%;
  width: 100%;
}

.meetRightContainer {
  background-color: #000;
}

.meetRightContainDtl {
  display: flex;
  flex-direction: row;
  height: calc(100vh - 80px);
  overflow: hidden;
  position: relative;
  border-left: 0.1px solid #545454;
  background-color: #1c1c1c;
}

.metRightInfoContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: #000000;
}

.meetRightHeader {
  order: 1;
  display: flex;
  width: auto;
  padding: 10px 70px 10px 16px;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  background: #000000;
  border-bottom: 1px solid #545454;
}
.metRtTitle {
  color: #fff;
  font-size: 16px;
  font-family: "Inter";
}
.metRtAction .MuiButtonBase-root.MuiIconButton-root {
  color: #ffffff;
}

.MeetingTranscriptionContainer {
  flex: 1 1;
  order: 2;
  position: relative;
  overflow: hidden !important;
  overflow-y: overlay !important;
}
.Meeting_Transcription {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 10px;
  height: calc(100% - 20px);
  width: calc(100% - 86px);
  overflow: hidden !important;
  overflow-y: overlay !important;

  padding: 10px 70px 10px 16px;
}
.transcripHeader {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  width: 100%;
}
.transcripAutoComplete {
  order: 1;
  flex: 1 1;
  min-width: 200px;
  border-radius: 8px;
  background: #272727;
  outline: none;
  border: none;
  padding: 0;
}
.tranLangSrchInput {
  order: 1;
  flex: 1 1;
  min-width: 200px;
  border-radius: 8px;
  background: #272727;
  outline: none;
  border: none;
  padding: 8px 16px;
  color: #ffffff;
  height: 24px;
  font-size: 14px;
  line-height: 24px;
}

.transcripAutoComplete .MuiInputBase-input.MuiOutlinedInput-input {
  color: #fff;
}

.transcripAutoComplete
  .MuiAutocomplete-endAdornment
  .MuiButtonBase-root.MuiIconButton-root {
  color: rgb(255 255 255 / 54%);
}
.transcripAutoComplete svg {
  color: rgb(255 255 255 / 54%);
}

.transAllBtn {
  order: 2;
  display: flex !important;
  padding: 6px 18px !important;
  align-items: center !important;
  gap: 6px !important;
  border-radius: 4px !important;
  background: #00adef !important;
  color: #fff !important;
  font-family: Inter !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 24px !important;
  letter-spacing: 0.14px !important;
  text-transform: capitalize !important;
}
.transcripAutoComplete .MuiInputBase-root.MuiOutlinedInput-root {
  /* padding: 2px 9px !important; */
  /* padding-right: 39px !important; */
}

.transcriptionContainArea {
  min-height: 200px;
  height: auto;
  width: 100%;
  color: #ffffff;
  opacity: 0.8;
  font-size: 14px;
  font-family: Inter;
  font-weight: 400;
  position: relative;
}
.elementHeading {
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  opacity: 0.7;
}

.meetingTabRightPanel .noDataCard {
  color: #606060;
  font-size: 16px;
  font-weight: 600;
  font-family: Inter;
  text-align: center;
  width: 100%;
  max-width: 750px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.meetLanguagesListContain::-webkit-scrollbar,
.Meeting_Transcription::-webkit-scrollbar {
  width: 2px !important;
  height: 6px !important;
}
.meetLanguagesListContain::-webkit-scrollbar-thumb,
.Meeting_Transcription::-webkit-scrollbar-thumb {
  background-color: rgba(107, 107, 107, 1);
}
.meetLanguagesListContain::-webkit-scrollbar-track,
.Meeting_Transcription::-webkit-scrollbar-track {
  background: rgb(18 18 18 / 70%);
}
.MeetRightFooter {
  order: 3;
  display: inline-flex;
  padding: 10px 70px 10px 16px;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  background-color: #000;
  border-top: 1px solid #545454;
}
.meetRightFootBtnGrp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.meetRightFootBtnGrp .downloadTranscriptionBtn {
  display: flex !important;
  padding: 6px 18px !important;
  align-items: center !important;
  gap: 6px !important;
  border-radius: 4px !important;
  background: #00adef !important;
  color: #fff !important;
  font-family: Inter !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 24px !important;
  letter-spacing: 0.14px !important;
  text-transform: capitalize !important;
  max-height: 36px;
}

.meetRightFootBtnGrp .downloadTranscriptionBtn:disabled {
  display: flex !important;
  padding: 6px 18px !important;
  align-items: center !important;
  gap: 6px !important;
  border-radius: 4px !important;
  background: gray !important;
  color: #fff !important;
  font-family: Inter !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 24px !important;
  letter-spacing: 0.14px !important;
  text-transform: capitalize !important;
  max-height: 36px;
}

.cancleTransBtn.MuiButton-outlined {
  text-transform: capitalize;
  font-weight: 500;
  font-size: 14px;
  background: transparent;
  padding: 6px 10px;
  border-radius: 4px;
  border: 1px solid #4c4c4c;
  display: flex;
  align-items: center;
  color: #a2a2a2;
  max-height: 36px;
}
.cancleTransBtn.MuiButton-outlined:hover {
  color: #fff;
  border: 1px solid #ffffff;
}

.MeetingSelectLangContainer {
  flex: 1 1;
  order: 2;
  position: relative;
  overflow: hidden;
  overflow-y: overlay;
}
.meetLanguagesListContain {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 10px;
  height: calc(100% - 20px);
  /* width: calc(100% - 86px); */
  width: 100%;
  overflow: hidden;
  overflow-y: auto;

  /* padding: 10px 70px 10px 16px; */
}
.meetlangListGrp {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
}
.meetLangIndBtn {
  /* background-color: #333333; */
  border: 1px solid #adadad;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 10px;
  text-wrap: wrap;
  border-radius: 6px;
  width: 100px;
  height: 48px;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  font-family: "Inter";
  cursor: pointer;
  opacity: 0.6;
  transition: all 0.6s;
  position: relative;
}
.meetLangIndBtn:hover {
  opacity: 1;
  background-color: #333333;
}
.meetLangIndBtn.meetLangSelected {
  background-color: #ffffff;
  color: #000000;
  opacity: 1;
}
.meetLangIndBtn span {
  text-align: center;
}

.selectedLang {
  display: none;
  position: absolute;
  top: 4px;
  left: 4px;
}
.selectedLang svg {
  height: 16px;
  width: 16px;
  color: #00af1c;
}
.meetLangIndBtn.meetLangSelected .selectedLang {
  display: flex;
  align-items: center;
  justify-content: center;
}
.saveMeetRightBtn {
  display: flex !important;
  padding: 6px 18px !important;
  align-items: center !important;
  gap: 6px !important;
  border-radius: 4px !important;
  background: #00adef !important;
  color: #fff !important;
  font-family: Inter !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 24px !important;
  letter-spacing: 0.14px !important;
  text-transform: capitalize !important;
}

.liGrp {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.spokenLangDiv {
  display: flex;
  flex-wrap: wrap;
  gap: 28px; /* Adjust the gap between boxes as needed */
}

.spokenLangBox {
  margin: 24px; /* Adjust the margin as needed */
}

.spokenLang {
  /* Your styling for unselected language */
  background-color: #333; /* Example background color */
  color: #fff; /* Example text color */
  padding: 28px; /* Adjust padding for the box */
  border-radius: 24px; /* Optional: Add border-radius for rounded corners */
}

.spokenLangSelected {
  /* Your styling for selected language */
  cursor: pointer;
  background-color: #3498db; /* Example background color */
  color: #fff; /* Example text color */
  padding: 28px; /* Adjust padding for the box */
  border-radius: 24px; /* Optional: Add border-radius for rounded corners */
}

.metGustLstInd:hover .mkAsConvenorBtnGrpInv {
  display: inline-flex;
}
.gustAsConvenor.metGustLstInd:hover .mkAsConvenorBtnGrpInv {
  /* display: none !important; */
}
.mkAsConvenorBtnGrpInv {
  /* display: none; */
  transition: all 0.8s;
}
.mkAsConvenorBtnGrpInv button {
  display: inline-flex;
  padding: 8px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background-color: #333;
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  text-transform: capitalize;
  transition: all 0.6s;
}
.mkAsConvenorBtnGrpInv button:hover {
  background-color: #545454;
}

.brParticipants {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.reschedule-reason {
  font-size: 14px !important;
}
#participantSelect {
  /* height: 42px !important; */
  color: white;
}
.meetingText {
  color: white;
}

.addParticipantBtn {
  /* display: none; */
  transition: all 0.8s;
}
.addParticipantBtn button {
  display: inline-flex;
  padding: 8px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background-color: #333;
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  text-transform: capitalize;
  transition: all 0.6s;
  margin-left: 11px;
  height: 42px;
  width: 40px;
  top: 8px;
}
.addParticipantBtn button:hover {
  background-color: #545454;
}

.additionalEvents {
  color: white !important;
  padding: 8px 10px 8px 30px !important;
  border-bottom: 0.5px solid #0d7d67;
  border-top: 0.1px solid #3ec1a7;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  height: auto !important;
}

.additionalEventsTitle {
  font-size: 18px;
}
.additionalEventsDate {
  font-size: 14px;
  /* font-weight: 600; */
}

.flotingAreaContainer .elementFormContainerAdditionalEvents {
  overflow: hidden;
  overflow-y: overlay;
  height: calc(100vh - 200px);
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 20px;
  /* padding: 0px 70px 0px 30px; */
}
.pRelative {
  position: relative;
}

.flotingAreaContainer .elementFormContainerAdditionalEvents::-webkit-scrollbar {
  width: 0px !important;
  height: 0px !important;
}

.flotingAreaContainer
  .elementFormContainerAdditionalEvents::-webkit-scrollbar-thumb {
  background-color: rgba(107, 107, 107, 1);
}

.flotingAreaContainer
  .elementFormContainerAdditionalEvents::-webkit-scrollbar-track {
  background: rgb(18 18 18 / 70%);
}

.deleteAdditionalEvent {
  color: #fff !important;
  padding: 4px !important;
}
.dashboardMeetingsContainer {
  width: 100%;
}

.MuiButtonBase-root.deleteAdditionalEvent {
  color: #0c6d5a !important;
  transition: all 0.4s;
}

.additionalEvents:hover .MuiButtonBase-root.deleteAdditionalEvent {
  color: #ffffff !important;
}
.additionalEvents {
  transition: all 0.4s;
}
.additionalEvents.myCalendar:hover {
  background-color: #138972 !important;
}
/* .audio-preview {
  display: none !important;
} */
.convTimeCheckbix {
  color: white;
  display: flex;
  gap: 3px;
  cursor: pointer !important;
}

.convTimeCheckbix input[type="checkbox"] {
  height: 15px;
  width: 15px;
}

.hideRightForm {
  display: none !important;
}

.meetGustDtlElement
  .MuiAutocomplete-root
  .MuiInputBase-root.MuiOutlinedInput-root {
  padding: 1px 8px;
}

.meetGustDtlElement .MuiAutocomplete-root {
  background-color: #333;
  border-radius: 4px;
  color: white;
  border: 1px solid #545454;
}

.tdCellsSelectable {
  /* cursor: alias !important; */
  cursor: url(../images/arrow.png), alias !important;
}
.conView250 {
  width: 250px !important;
}
.overflowAuto {
  overflow: auto !important;
  overflow-x: hidden !important;
}

.conv3240 {
  width: 3244px !important;
}

.meetingHeadings {
  font-size: 16px !important;
}

.selectionDisabled {
  background-color: #414141 !important;
}

.googleCalTxt {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.inviteUser {
  margin-left: 10px !important;
}

/* RadialProgressBar.css start */
.meetingTimer {
  position: fixed;
  left: 100px;
  bottom: -2px;
  z-index: 1024;
}
.radial-progress-timer {
  transform: rotate(-90deg);
}

.radial-progress-bg-timer {
  fill: none;
  stroke: #434343;
  stroke-width: 4;
}

.radial-progress-bar-timer {
  fill: none;
  stroke: #3498db;
  stroke-width: 4;
  stroke-linecap: round;
  transition: stroke-dashoffset 0.5s;
}
.radial-progress-timer text {
  fill: #e0e0e0;
  transform: rotateZ(90deg) translateX(0px) translateY(-50px);
  font-size: 12px;
}

/* RadialProgressBar.css end */

.fullscreen-meeting {
  width: 100vw;
  height: 100vh;
  position: fixed; /* Cover the entire viewport */
  top: 0;
  left: 0;
  /* Add other styles for background, etc. as desired */
}

.joinInsMetBtn:disabled {
  cursor: not-allowed !important;
  background-color: gray;
}

.joinBtnRoomNo {
  background-color: #00adef !important;
}

.joinBtnRoomNo:hover {
  background-color: #000000 !important;
}

.editModeOn {
  color: #f90 !important;
}

.participantsRT {
  color: white !important;
}

.grtModBtn {
  color: white !important;
  background-color: #00adef !important;
  cursor: pointer !important;
}

.grtModBtn:disabled {
  cursor: not-allowed !important;
  background-color: gray !important;
}

.participantsRT .rt-tbody .rt-td {
  /* padding: 10px !important; */
}

.participantsRT
  .rt-th.rt-resizable-header:last-child
  .rt-resizable-header-content {
  text-align: left;
}

.closeDilogMeetPart {
  position: absolute;
  right: 5px;
  top: 5px;
  font-weight: 600 !important;
  font-size: 15px;
  color: white;
  z-index: 99;
}

.unaTooltip {
  font-size: 16px !important;
}

.dialogContentFaceLiveness .MuiButton-sizeMedium {
  /* background-color: #00adef !important;
  border: 1.6px solid #00adef !important;
  border-radius: 4px !important;
  color: #fff !important;
  font-family: Inter !important;
  font-size: 14px !important;
  font-weight: 500;
  min-width: 106px !important;
  padding: 6px 16px !important;
  text-transform: capitalize !important;
  transition: all 0.8s !important; */
}
.meetContBtn .MuiBadge-standard {
  background-color: #f90 !important;
  top: -4px !important;
  right: -5px !important;
  min-width: 16px !important;
  min-height: 16px !important;
  font-family: "Inter";
  color: black;
}

.jitsi-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: #333333;
  border-radius: 10px !important;
  flex-direction: column;
  gap: 25px;
  color: white;
  font-family: "Inter";
  font-size: 16px;
}

.jitsi-loader .MuiCircularProgress-root {
  width: 60px !important;
  height: 60px !important;
}

.jitsi-loader-main-container {
  height: 100%;
  width: 100%;
  background-color: "#333333";
  font-family: "Inter";
  font-size: 16px;
  border-radius: 10px !important;
}

.hrActivityGroupTable {
  border-collapse: collapse;
  width: 100%;
}

.hrActivityGroupTable th,
.hrActivityGroupTable td {
  border: 1px solid #8d8d8d;
  padding: 8px;
}

.hrActivityGroupTable th {
  background-color: #f2f2f2;
}

.MuiPaper-root
  .MuiDialogActions-root.MuiDialogActions-spacing
  .MuiButtonBase-root.MuiButton-root:first-child {
  background-color: #fff !important;
  border: 1.6px solid #00adef !important;
  border-radius: 4px !important;
  color: #00adef !important;
  font-family: Inter !important;
  font-size: 14px !important;
  font-weight: 500;
  min-width: 106px !important;
  padding: 6px 16px !important;
  text-transform: capitalize !important;
  transition: all 0.8s !important;
}
.MuiPaper-root
  .MuiDialogActions-root.MuiDialogActions-spacing
  .MuiButtonBase-root.MuiButton-root:last-child {
  padding: 6px 16px !important;
  font-size: 14px !important;
  border-radius: 4px !important;
  background-color: #00adef !important;
  color: #fff !important;
  font-family: Inter !important;
  transition: all 0.8s !important;
  border: 1.6px solid #00adef !important;
  text-transform: capitalize !important;
  font-weight: 500;
  min-width: 106px !important;
}

.meetingfileUpload {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
}

.shareFileMeetingLoader {
  max-height: 35px !important;
}

.shareFileMeetingLoader .MuiCircularProgress-root {
  color: white !important;
  width: unset !important;
  height: unset !important;
}
.freeAccountBtn {
  background-color: #1976d2 !important;
  cursor: pointer !important;
  border-radius: 5px !important;
  padding: 2px !important;
  /* margin-right: 10px !important; */
}

.MuiButtonBase-root.MuiIconButton-root.closeWebinarLeaveModelBtn {
  position: absolute !important;
  right: 10px;
  top: 10px;
  height: 36px;
  width: 36px;
  background-color: #ededed !important;
  color: #1c1c1c !important;
}
.webinarLeaveBtnGrp {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.webinarLeaveBtnGrp .dfultPrimaryBtn,
.webinarLeaveBtnGrp .dfultDarkSecondaryBtn {
  margin: 5px !important;
}

.webinarLeaveBtnGrp .endForAll {
  padding: 6px 16px !important;
  font-size: 14px !important;
  border-radius: 4px !important;
  background-color: red !important;
  color: #fff !important;
  font-family: Inter !important;
  transition: all 0.8s !important;
  border: 1.6px solid #00adef !important;
  text-transform: capitalize !important;
  font-weight: 500;
  min-width: 106px !important;
  border-color: white !important;
}
.freeAccountBtn li.MuiButtonBase-root {
}

.custPopoverModel .formControl .MuiSelect-select.MuiSelect-outlined {
  padding: 8px 32px 8px 12px;
  color: #333;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.custPopoverModel .formControl .MuiFormLabel-root.MuiInputLabel-root {
  font-size: 14px;
  line-height: 34px;
  font-family: Inter;
  color: rgb(0 0 0 / 60%);
  transform: translate(14px, 4px);
}
.custPopoverModel
  .formControl
  .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-shrink {
  background-color: transparent;
  color: #707070;
  transform: translate(14px, -10px) scale(0.75);
}
.custPopoverModel .formInputField {
  color: #1c1c1c !important;
  background-color: #ffffff !important;
}

.custPopoverModel .MuiSvgIcon-root.MuiSelect-icon {
  color: #707070;
}
.custPopoverModel .MuiInputLabel-root.Mui-focused {
  color: #707070 !important;
}

.custPopoverModel .formControl .MuiOutlinedInput-notchedOutline {
  border: 1px solid #eee !important;
  color: #707070;
}

.transView {
  cursor: pointer;
  color: #1976d2;
}

.transcripAutoCompleteView {
  order: 1;
  flex: 1 1;
  min-width: 200px;
  max-width: 200px;
  border-radius: 8px;
  background: #272727;
  outline: none;
  border: none;
  padding: 0;
}

.transcripAutoCompleteView .MuiInputBase-input.MuiOutlinedInput-input {
  color: #fff;
}
.meetingAddGuestGrup {
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 10px;
  flex-wrap: wrap;
}

.modInputFieldGuestAdd {
  width: 100%;
}
.modInputFieldGuestAdd input {
  display: flex;
  width: calc(100% - 16px);
  padding: 10px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid #eee;
  color: black !important;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.14px;
  outline: none;
}

.modInputFieldGuestAdd .MuiFormLabel-root.MuiInputLabel-root {
  color: rgba(0, 0, 0, 0.6) !important;
}
