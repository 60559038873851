.ticket-container {
  /* background-color: #1e1e1e; */
  color: #f5f5f5;
  /* padding: 20px; */
  border-radius: 10px;
  max-width: 900px;
  margin: 0 auto;
  /* font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif; */
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); */
}

.ticket-container h2 {
  text-align: center;
  /* font-size: 28px; */
  margin-bottom: 25px;
}

.ticket-container .ticket-details p {
  /* font-size: 18px; */
  line-height: 1.6;
  margin: 12px 0;
}

.ticket-container .uploaded-files {
  margin-top: 25px;
}

.ticket-container .file-list {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.ticket-container .file-details {
  display: inline-block;
  background-color: #333;
  padding: 5px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}

.ticket-container .file-image-preview {
  max-width: 150px;
  max-height: 150px;
  object-fit: cover;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.ticket-container .file-image-preview:hover {
  transform: scale(1.05);
}

.ticket-container .file-details a {
  color: #4caf50;
  text-decoration: none;
}

.ticket-container .file-details a:hover {
  text-decoration: underline;
}

/* Download button for images */
.ticket-container .image-preview-container {
  position: relative;
  display: inline-block;
}

.ticket-container .download-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  text-decoration: none;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.ticket-container .image-preview-container:hover .download-button {
  opacity: 1;
}

.ticket-details-body {
  background-color: #1e1e1e;
  padding: 10px;
  border-radius: 10px;
}

.ticket-row {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #333;
}

.ticket-label {
  color: #aaa;
  font-weight: bold;
  font-size: 16px;
  flex: 1; /* Ensures the label takes up 1 part of the row */
  text-align: left;
}

.ticket-value {
  color: #f5f5f5;
  font-size: 16px;
  flex: 2; /* Ensures the value takes up 2 parts of the row */
  text-align: right;
  word-wrap: break-word; /* Wraps long text onto the next line */
}

.ticketComments {
  margin-top: 10px;
  background-color: #1e1e1e;
  padding: 10px;
  border-radius: 10px;
}
.ticketMessageHdr {
  margin-top: 10px;
  font-size: 16px;
  margin-bottom: 10px;
}

.supportTicketComments {
  /* border-bottom: 1px solid #333 !important; */
}
