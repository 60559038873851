.header {
  /* background-color: #1c1c1c !important; */
  /* background-image: linear-gradient(#1c1c1c, #1c1c1c) !important; */
  height: 50px;
  box-shadow: none !important;
}
.MuiPaper-root.header .MuiAvatar-root.MuiAvatar-circular {
  border-radius: 0px !important;
}
.MuiPaper-root.header .usercardGrp .MuiAvatar-root.MuiAvatar-circular {
  border-radius: 50% !important;
}
.tempContainer {
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin-left: 70px !important;
  margin-right: 70px !important;
  width: calc(100% - 140px) !important;
}
.refreshIcon.MuiIconButton-root {
  height: 48px;
  width: 48px;
  opacity: 1;
}
.refreshIcon.MuiIconButton-root svg {
  color: #b2b2b2;
}
button {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.topMenu ul li svg {
  margin-right: 10px;
  height: 26px;
  width: 26px;
}
.usercardGrp {
  display: flex;
  align-items: center;
}
.userInfo {
  margin-right: 10px;
  text-align: right;
  font-size: 12px;
  line-height: 14px;
}
.userName {
  font-weight: 600;
  /* -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  color: #fff;
  display: -webkit-box;
  font-family: Inter;
  font-size: 14px;
  opacity: 0.6;
  overflow: hidden;
  text-overflow: ellipsis; */
}
/* .UserOrg {
} */
.MuiPopover-root.MuiMenu-root.topMenu ul.MuiList-root li {
  padding: 10px 16px !important;
}
.MuiPopover-root.MuiMenu-root.topMenu ul.MuiList-root {
  min-width: 250px;
  max-width: 300px;
  width: 100%;
}
.moreAccountLst {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.moreAccountLst .arrowIoc {
  display: flex;
  align-items: center;
  justify-content: center;
}
li.activeMenuList {
  background-color: #d5d5d5;
}
li.listOfAccountItem {
  background-color: #eeeeee;
}
.listOrgImg {
  height: 26px;
  width: 26px;
  border-radius: 20px;
  margin-right: 10px;
}
.listOrgImg img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  object-fit: cover;
}
.orgNameTypography {
  display: flex;
  flex-grow: 1;
  font-family: Inter !important;
  text-decoration: none;
  color: #fff;
  font-size: 18px !important;
  letter-spacing: 1px !important;
}
.contacts {
  /* background-color: #333333 !important; */
}
.topMenu .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
  scrollbar-width: thin;
  -ms-overflow-style: none;
}

.langOption {
  margin-right: 10px;
}
.langOption .formControl .MuiSelect-select.MuiSelect-outlined {
  padding: 4px 24px 4px 8px;
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: 22px;
  opacity: 0.6;
}
.langOption .formControl .MuiOutlinedInput-notchedOutline {
  border: 1px solid #333333 !important;
  color: #ffffff;
}

.doctorSelectWthSubBtn
  .formAutoComInputField
  input.MuiInputBase-input.MuiOutlinedInput-input {
  padding: 8px 32px 8px 12px;
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  min-height: 1.4375em;
  height: 24px;
  text-transform: capitalize;
}

.supportOption {
  margin-right: 14px;
}
.tourList{
  max-height: 40dvh;
  overflow: hidden;
  overflow-y: scroll;

}

.tourList::-webkit-scrollbar {
  width: 6px !important;
}

/* Track */
.tourList::-webkit-scrollbar-track {
  background: #f1f1f1!important; 
}
 
/* Handle */
.tourList::-webkit-scrollbar-thumb {
  background: #888!important; 
}

/* Handle on hover */
.tourList::-webkit-scrollbar-thumb:hover {
  background: #555!important; 
}

.MuiPopover-root.MuiMenu-root.topMenu ul.MuiList-root .tourList li {
  padding: 8px 16px 8px 50px !important;
  text-wrap: auto;
  line-height: 14px;
}