section.aboutUsContainerWthImg {
  height: 80vh;
  background-image: url(../images/aboutusBackground.jpg);
  background-size: cover;
  background-position: center center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.headingText {
  font-size: 6em;
  font-weight: 600;
}
.aboutContain {
  padding: 100px 140px 50px;
  display: flex;
  align-items: center;
  min-height: 70vh;
  background-color: #111111;
}
.aboutText {
  font-size: 16px;
  letter-spacing: 0.5px;
  color: #888888;
}
