.ticketFormContainer {
  background-color: #333333;
  color: #fff;
  padding: 0;
  border-radius: 10px;
  width: calc(100% - 20px);
  margin: 10px;
  font-family: "Inter";
}

.ticketFormContainer .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.ticketFormContainer h2 {
  margin: 0;
  color: #fff;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 1px;
}

.ticketFormContainer .ticketSwitch button {
  background-color: #333;
  color: #fff;
  border: 1px solid #555;
  padding: 8px 16px;
  cursor: pointer;
  margin-left: 10px;
  border-radius: 5px;
}

.ticketFormContainer .ticketSwitch button.active {
  background-color: #007bff;
  border-color: #007bff;
}

.ticketFormContainer form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.ticketFormContainer label {
  margin-bottom: 5px;
  font-size: 14px;
}

.ticketFormContainer input[type="text"],
textarea {
  background-color: #222;
  color: #fff;
  border: 1px solid #444;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  width: calc(100% - 20px);
  font-family: "Inter";
}

.ticketFormContainer textarea {
  height: 150px;
  resize: none;
}

.ticketFormContainer input:focus,
textarea:focus {
  outline: none;
  border-color: #666;
}

.ticketFormContainer .prioritySection {
  margin-bottom: 20px;
}

.ticketFormContainer .priorityOptions label {
  display: inline-block;
  margin-right: 15px;
}

.ticketFormContainer .uploadSection {
  margin-bottom: 20px;
  margin-top: 14px;
  display: flex;
  flex-direction: column;
}

.ticketFormContainer .uploadSection input[type="file"] {
  background-color: #333;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
}

.ticketFormContainer .imagePreview {
  margin-top: 10px;
  /* display: flex !important; */
}

.ticketFormContainer .imagePreview img {
  max-width: 100px;
  margin-right: 10px;
  margin-bottom: 10px;
  border: 2px solid #fff;
  border-radius: 5px;
  /* display: flex  !important; */
}

.ticketFormContainer button[type="submit"] {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 12px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.ticketFormContainer button[type="submit"]:hover {
  background-color: #007bff;
}

.ticketFormContainer button[type="submit"]:disabled {
  background-color: gray;
  cursor: not-allowed;
}
