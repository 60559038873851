.status-card {
  background-color: #1c1c1c;
  border-radius: 8px;
  padding: 20px;
  height: 100%;
  width: calc(100% - 60px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-left: 20px !important;
}
.value.status-online {
  text-transform: capitalize;
}

.card-title {
  font-size: 1.2rem;
  font-weight: bold;
  color: #00bfa6;
  margin-bottom: 15px;
  text-align: center;
}

.card-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.status-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.label {
  font-weight: bold;
  color: #a0a0a0;
}

.value {
  font-weight: normal;
  color: #ffffff;
}

.status-online {
  color: #00ff61;
}

.status-offline {
  color: #f44336;
}

.website-status-card {
  width: 150px;
  padding: 12px;
  background-color: #1c1c1c;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-family: Arial, sans-serif;
}

.website-status-card__title {
  font-size: 14px;
  color: #6b7280; /* Softer gray color */
  margin: 0;
  font-weight: 500;
  position: relative;
  text-align: left;
}

.website-status-card__value {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: flex-start;
}

.website-status-card__time {
  font-size: 36px;
  font-weight: 600;
  color: #ff9900 !important;
}

.website-status-card__unit_status {
  display: flex;
  flex-direction: column;
}

.website-status-card__unit {
  font-size: 14px;
  color: #ff9900 !important;
  margin-left: 5px;
}

.website-status-card__unit_response_time {
  font-size: 14px;
  color: #ff9900 !important;
  margin-left: -4px !important;
}

.website-status-card__status {
  font-size: 12px;
  /* color: #9ca3af; */
  color: #00ff00;
  margin: 0;
  margin-top: 5px;
  font-weight: 500;
  position: relative;
  left: -6px;
}

.website-status-card-parent {
  display: flex;
  justify-content: space-between;
}

.lastChecked {
  left: 8px !important;
}

.websiteStatusPgBtn {
  width: 115px !important;
}

.dashboard-container {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.dashboard-left {
  flex: 8;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard-right {
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
}

.graph-placeholder {
  width: 100%;
  height: 70%;
  border-radius: 8px;
}

.data-card {
  /* border-radius: 8px;
    padding: 15px;
    text-align: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
  /* flex-shrink: 0; */
}
