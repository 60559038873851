.selectTemplateContainerArea {
  height: calc(100vh - 200px);
  width: 100%;
}

.selectTemplateContainArea {
  height: 100%;
  width: 100%;
}

.selectTemplateContainer {
  padding: 20px 10px 10px 10px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 20px;
  overflow: hidden;
  height: calc(100vh - 185px);
}
.selectTemplateDisplay {
  width: 100%;
  height: 100%;
  overflow-y: overlay;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none;
}
.stHedText {
  font-family: "Russo One", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #fff;
  font-size: 24px;
  letter-spacing: 1px;
}
.stSubText {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;
  max-width: 1000px;
}
.selecttempFilterSearch {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
.filterIcon svg {
  font-size: 24px;
  color: #707070;
}
.searchIconWithBtn {
  height: 40px;
  background-color: #333333;
  border: 1px solid #707070;
  border-radius: 40px;
  display: flex;
  align-items: center;
  padding: 1px 0px 0px 10px;
}
.searchIconWithBtn input {
  height: 32px;
  border-radius: 10px;
  outline: none;
  border: none;
  background-color: transparent;
  font-size: 16px;
  font-family: Inter;
  min-width: 300px;
  width: 20vw;
  color: #fff;
}
.searchIconWithBtn .MuiButtonBase-root.MuiIconButton-root svg {
  color: #ffffff;
  font-size: 24px;
}

.indTemplateCard {
  width: calc((100% - 228px) / 4);
  overflow: hidden;
  padding: 20px;
  background-color: #000;
  border: 1px solid #545454;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 20px;
}
.templateImage {
  height: 100%;
  width: 100%;
  position: relative;
}
.templateImage img {
  height: auto;
  width: 100%;
}
.templateName {
  font-size: 18px;
  font-family: Inter;
  font-weight: 600;
  color: #fff;
}
.templateTags {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
}
.temptags {
  background-color: #939393;
  color: #000;
  padding: 4px 11px;
  border-radius: 4px;
}
.templateNMTg {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.templateActBtnGrp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.templateActBtnGrp .MuiButtonBase-root.MuiButton-root {
  color: #fff !important;
  font-family: Inter;
  background-color: #333 !important;
  text-transform: uppercase !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  padding: 6px 16px !important;
  border-radius: 4px !important;
}
.templateActBtnGrp .MuiButtonBase-root.MuiButton-root.Mui-disabled {
  background-color: #00adef !important;
}
.indTemplateCardGroup {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
}
.selectTemplateDisplay::-webkit-scrollbar {
  width: 0px !important;
  height: 6px !important;
}

.selectTemplateDisplay::-webkit-scrollbar-thumb {
  background-color: rgba(107, 107, 107, 1);
}
.selectTemplateDisplay::-webkit-scrollbar-track {
  background: rgb(18 18 18 / 70%);
}
.tempActBtn:disabled {
  color: gray !important;
  cursor: not-allowed !important;
}
.editWebsiteSelTemp {
  position: relative;
  left: 250px;
  bottom: 10px;
}
.MuiButtonBase-root.MuiIconButton-root.editWebsiteSelTemp {
  height: 36px;
  width: 36px;
  background-color: #fe9800;
  border: 1px solid #fff;
  position: absolute;
  top: 0px !important;
  right: 0px !important;
  left: auto !important;
  box-shadow: 2px 4px 8px #00000085;
}
