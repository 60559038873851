.faceAssociateParent {
  display: flex !important;
  align-items: center;
}

.faceAssociateParent .image-section {
  flex: 2 1;
  max-width: 150px;
}

.faceAssociateParent .profile-image {
  width: 100%;
  border-radius: 8px;
}

.faceAssociateParent .info-section {
  flex: 2;
  padding-left: 20px;
}

.faceAssociateParent .info-section h2 {
  margin-bottom: 10px;
  font-size: 1.5rem;
  color: #333;
}

.faceAssociateParent .info-section p {
  margin: 5px 0;
  font-size: 1rem;
  color: #666;
}

.faceAssociateParent .info-section p strong {
  color: #333;
}

/* Responsive Design */
@media (max-width: 768px) {
  .faceAssociateParent .profile-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .faceAssociateParent .info-section {
    padding-left: 0;
    margin-top: 10px;
  }
}

.disMessage {
  font-size: 12px;
  color: red;
  font-weight: 500;
}

.disMessage strong {
  font-size: 10px;
  color: red;
  font-weight: 700;
}
.bookedAppoinmentMsg {
  color: #ffffff;
}
