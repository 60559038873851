.payrollcontainer {
  display: flex;
  height: 100%;
  width: 100%;
}
.payrollsidebar {
  width: 250px;
  background-color: #121212;
  padding: 0px;
  border-right: 1px solid #545454;
}
.payrollcontent {
  flex-grow: 1;
  padding: 0px;
  width: calc(100% - 250px);
  height: 100%;
  overflow: hidden;
  overflow-y: overlay;
}
.payrollcontent .payrollheader {
  position: sticky;
  top: 0px;
  z-index: 1;
  color: rgb(255 255 255 / 60%);
  box-shadow: 0 1px 10px #262626;
}
.payrollcontent .payrollheader span {
  font-weight: 400;
  color: #ffffff;
}
.payrollheader {
  font-size: 24px;
  padding: 10px 10px 10px 20px;
  background-color: #333333;
  text-align: left;
  color: #fff;
}
.payrollmenu {
  margin-top: 0px;
  padding-inline-start: 0px;
}
.payrollmenu-item {
  list-style-type: none;
  padding: 14px 10px 14px 20px;
  border-bottom: 1px solid #545454;
}
.payrollmenu-item.activeMenu {
  border-right: 2px solid #ff7a00;
  background-color: #444444;
}
.payrollmenu-item > .prText {
  color: rgb(255 255 255 / 60%);
  text-decoration: none;
  font-size: 16px;
  font-family: "Inter";
  font-weight: 400;
  cursor: pointer;
}
.prText.prHeading {
  font-size: 18px;
}
.payrollmenu-item.activeMenu .prText.prHeading {
  color: #fff;
  font-weight: 600;
}
.payrollsubmenu .payrollmenu-item.activeItem .prText {
  color: #fff;
  font-weight: 600;
}
.payrollsubmenu {
  padding-left: 20px;
  display: none;
}
.payrollmenu-item.activeMenu .payrollsubmenu {
  display: block;
}
.payrollsubmenu .payrollmenu-item {
  position: relative;
  border: none;
}
.payrollsubmenu .payrollmenu-item::before {
  content: "";
  position: absolute;
  left: -10px;
  top: 0;
  bottom: 0;
  width: 2px;
  background-color: #545454;
}
.payrollsubmenu .payrollmenu-item:last-child:before {
  content: "";
  position: absolute;
  left: -10px;
  top: 0;
  bottom: 0;
  width: 2px;
  background-color: #545454;
  height: 50%;
}
.payrollsubmenu .payrollmenu-item::after {
  content: "";
  position: absolute;
  left: -8px;
  top: 22px;
  width: 18px;
  height: 2px;
  background-color: #545454;
}
.payrollsubmenu .payrollmenu-item.activeItem::after {
  background-color: #ffffff;
}
.payrollContainArea {
  height: calc(100% - 52px);
  width: calc(100% - 40px);
  padding: 0px 20px;
  overflow: hidden;
}
.editPayrollElement {
  width: 700px;
  height: 100%;
}
.checkboxWithlist {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 40px;
}
.empContListCheck .MuiFormGroup-root.formRadioGroup {
  gap: 0px;
}
.empContListCheck .formRadioField {
  position: relative;
  padding: 6px 0px;
}
.checkboxWithlist .formRadioField::before {
  content: "";
  position: absolute;
  left: -17px;
  top: 0;
  bottom: 0;
  width: 2px;
  background-color: #545454;
}

.checkboxWithlist .formRadioField::after {
  content: "";
  position: absolute;
  left: -16px;
  top: 18px;
  width: 22px;
  height: 2px;
  background-color: #545454;
}
.checkboxWithlist .formRadioGroup .formRadioField::after {
  content: "";
  position: absolute;
  left: -16px;
  top: 23px;
  width: 22px;
  height: 2px;
  background-color: #545454;
}
.checkboxWithlist .formRadioField:last-child:before {
  content: "";
  position: absolute;
  left: -17px;
  top: 0;
  bottom: 0;
  width: 2px;
  background-color: #545454;
  height: 50%;
}
.editPayrollElement .tskElemHeding {
  margin-top: 20px;
  font-size: 16px;
  font-weight: 600;
}
.editPayrollElement .textOnlyCheck {
  color: #fff;
  padding-left: 48px;
  opacity: 0.7;
  font-size: 14px;
  font-family: "Inter";
  font-weight: 400;
  letter-spacing: 0.00938em;
}
.inlineRowElmnt {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
  flex-direction: row !important;
}
.ml48 {
  margin-left: 48px;
}
label.MuiFormLabel-root.custFrmlabel {
  font-size: 14px;
  font-family: Inter;
  color: #fff;
  font-weight: 500;
}
.bbsolid {
  border-bottom: 1px solid #373737;
  padding-bottom: 20px;
  margin-bottom: 20px;
  width: -webkit-fill-available;
}
.frmWithOnlyText .formElement {
  width: 50%;
}
.editPayrollElement .OnlyText {
  font-size: 14px;
  font-family: Inter;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.5);
}
.editPayrollElement .frmWithOnlyText .OnlyText {
  font-size: 16px;
  font-family: Inter;
  font-weight: 500;
  color: #fff;
}
.payrollEditor .frmWithOnlyText .OnlyText {
  font-size: 16px;
  font-family: Inter;
  font-weight: 500;
  color: #fff;
}
.fromNotes {
  padding: 10px;
  margin-top: 20px;
  background-color: #525252;
  border-radius: 6px;
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.035em;
}
.PTContainerGrp {
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 10px;
}
.PTElementInd {
  width: calc((100% - 50px) / 2);
  border-radius: 6px;
  background-color: #262626;
  padding: 10px 10px 16px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 6px;
  flex-direction: column;
  color: #b2b2b2;
}
.PTHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.PTEItem {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;
}
.PTEItem .PTEItmFC {
  flex-basis: 40%;
}
.PTEItem .PTEItmSC {
  color: #fff;
  flex-basis: 60%;
}
.PTTitle {
  font-size: 18px;
  color: #fff;
  letter-spacing: 0.05rem;
}
.PTEdit .MuiButtonBase-root.MuiIconButton-root {
  background-color: #3a3a3a;
  color: #b2b2b2;
  transition: all 0.4s;
}
.PTEdit .MuiButtonBase-root.MuiIconButton-root:hover {
  background-color: #121212;
  color: #ffffff;
}
.viewSlab {
  color: #ff9800 !important;
  cursor: pointer;
}
.PTEItemWithNoPT {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border: 1px dashed #545454;
  border-radius: 10px;
  margin-top: 10px;
  min-height: 60px;
  cursor: no-drop;
  height: -webkit-fill-available;
}
.PTEItmFCNoPT svg {
  height: 40px;
  width: 40px;
}
.width850 {
  width: 850px !important;
}
.width850 .PTEItem .PTEItmFC {
  flex-basis: 50%;
}
.width850 .PTElementInd {
  gap: 10px;
}
.smallText {
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  font-weight: 400;
  display: block;
}
.width100p {
  width: 100% !important;
}
.salaryComponentBtnGrp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px 0px;
}
.MuiButtonBase-root.MuiButton-root.addNSCBtn {
  background-color: #00adef;
  text-transform: capitalize;
  color: #fff;
  font-size: 14px;
  font-family: Inter;
}
.salaryCompHead {
  font-size: 18px;
  color: rgb(255 255 255 / 50%);
}
.width50 {
  width: 50px !important;
  min-width: 50px !important;
}

.gap0DC.MuiFormGroup-root.formRadioGroup {
  gap: 0px !important;
  flex-direction: column !important;
  align-items: flex-start;
  padding-left: 0px;
}
.asteric {
  color: #ff5f54;
}
.payrollEditor .MuiFormControl-root.MuiTextField-root.formTextFieldArea {
  background-color: #1f1f1f;
}
.payrollEditor .MuiInputAdornment-root.prIA {
  height: 100%;
  width: 20px;
}
.payrollEditor .MuiInputAdornment-root.prIAauto {
  width: auto;
  height: 100%;
}
.payrollEditor .MuiInputAdornment-root.prIAauto p.MuiTypography-root {
  color: #fff;
}
.payrollEditor .MuiInputAdornment-root.prIA p.MuiTypography-root {
  color: #fff;
}
.payrollEditor .textOnlyCheck {
  color: #cdcdcd;
  padding-left: 40px;
  opacity: 0.5;
  font-size: 14px;
  font-family: "Inter";
  font-weight: 400;
  letter-spacing: 0.00938em;
}
.payrollEditor
  .formElement
  .MuiFormControlLabel-root.Mui-disabled.formRadioField
  .MuiTypography-root.Mui-disabled {
  color: rgba(255, 255, 255, 0.4);
  cursor: no-drop;
}

.MuiFormControlLabel-root.formRadioField.Mui-disabled svg {
  color: #313131;
  height: 20px;
  width: 20px;
}

.payrollEditor .MuiFormGroup-root.formRadioGroup {
  padding-left: 0px;
}

.tdText .active {
  color: #ff9800;
}
.tdText .inActive {
  color: #b2b2b2;
}
.saIPend .formTextFieldArea input {
  padding: 8px 12px 8px 12px;
}
.textRight {
  text-align: right !important;
}
.payrollEditor table tfoot tr td {
  font-weight: 700;
  font-size: 16px;
  color: #fff;
}
.compTypRow {
  background: #101010;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.0095rem;
}
.payrollEditor .indentFormtable tfoot tr {
  background-color: #1c1c1c;
}
