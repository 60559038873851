.app-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}

.app-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #fff;
  justify-content: space-between;
  height: 44px;
}

.app-header .tabs {
  width: 100%;
  padding: 10px 0px;
  text-align: center;
  cursor: pointer;
  color: #000;
  background-color: #d3d3d3;
  font-family: var(--primary-font-family);
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.05rem;
  transition: all 0.8s;
}

.app-header .tabs.active {
  background-color: var(--e-global-color-accent);
  color: #ffffff;
}

.app-header span.friend-status {
  font-size: 0.666rem;
}

.app-message-body {
  background-color: transparent;
  padding: 24px 16px 0;
  overflow-y: auto;
  max-height: 95%;
  font-family: "Inter";
}

.app-message-body::-webkit-scrollbar {
  width: 2px !important;
  height: 0px !important;
}
.app-message-body::-webkit-scrollbar-thumb {
  background-color: rgba(107, 107, 107, 1);
}

.app-message-body::-webkit-scrollbar-track {
  background: rgb(18 18 18 / 70%);
}

.message-wrapper {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.message-wrapper.incoming-msg {
  align-items: flex-start;
  flex-direction: row-reverse;
  justify-content: flex-end;
  text-align: left;
}

.message-wrapper.incoming-msg .message-meta {
  justify-content: flex-start;
  flex: none;
  width: 32px;
}

.message-wrapper.outgoing-msg {
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-end;
  text-align: right;
}

.message-wrapper.outgoing-msg .message-meta {
  flex-direction: row-reverse;
  justify-content: flex-start;
  flex: none;
  width: 32px;
}

.message {
  max-width: 85%;
  min-width: 45%;
  width: auto;
  background-color: transparent;
  padding: 8px;
  border-radius: 5px;
  line-height: 1.1rem;
  display: flex;
  flex-direction: column;
  color: white;
  word-wrap: break-word;
  white-space: normal;
  overflow-wrap: break-word;
}
.message span {
  text-align: left;
}
.message-wrapper.outgoing-msg .message {
  background-color: #003b48;
  text-align: right;
}
.message-wrapper.incoming-msg .message {
  background-color: #003b48;
  text-align: left;
}
.sendChatMessage {
  border: none;
  background-color: #fb851e;
}
.sendChatMessage svg {
  fill: #431f00;
}
.messageMeta {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 10px;
  color: #a9a9a9;
  margin-top: 4px;
}
.incoming-msg .messageMeta {
  justify-content: flex-start;
}
.outgoing-msg .messageMeta {
  justify-content: flex-end;
}
.message-meta {
  display: flex;
  flex: 1 auto;
  margin: 2px 2px 2px;
}

.message-meta .sender-avatar {
  width: 24px;
  height: 24px;
  border-radius: 9999px;
  background: linear-gradient(180deg, #e87d1f, #e87d1f);
  color: #fff;
  line-height: 24px;
  text-align: center;
  font-size: 0.666rem;
  font-weight: 600;
}

.message-meta .timestamp {
  color: #999;
  font-size: 0.666rem;
  line-height: 24px;
  display: inline-block;
  margin: 0 5px;
}

.app-input-area {
  background-color: #d3d3d3;
  border-radius: 0;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  height: 55px;
}

.app-input-area input[type="text"] {
  flex: 1 80%;
  border: none;
  padding: 10px;
  margin: auto 10px;
  font-size: 14px;
  outline: none;
}

.app-input-area button[type="submit"] {
  flex: 1 5%;
  border: none;
  margin: 0 auto;
  /*background: linear-gradient(45deg, #fb551c, #f7b633);*/
  background-color: transparent;

  outline: none;
  cursor: pointer;
}

.app-input-area button[type="submit"] svg {
  fill: #2562ff;
}

.app-input-area button[type="submit"]:hover svg {
  fill: #002481;
}

.app-wrapper .tab-content.message-group {
  height: calc(100% - 130px);
}

.element-speakers {
  background-color: #e87d1f;
  padding-top: 100px;
  padding-bottom: 100px;
}

.p-lr0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.lp-img-effect img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.section-title {
  font-size: 5rem;
  font-family: var(--primary-font-family);
  font-weight: 800;
  color: #fff;
  margin-bottom: 30px;
}

.speaker-name,
.spker-degn {
  font-family: var(--secondary-text-font-family);
  letter-spacing: 0.09rem;
}

.speaker-name {
  font-size: 20px;
  font-weight: 600;
}

.spker-degn {
  font-size: 14px;
  font-weight: 400;
}

.element-remainingDays {
  display: flex;
  align-items: flex-end;
}

.element-remainingDays .timer {
  display: flex;
}

.element-remainingDays .timer {
  display: flex;
  align-items: baseline;
  justify-content: center;
  font-family: var(--secondary-text-font-family);
}

.element-remainingDays .timer span {
  display: block;
  font-size: 20px;
  color: #000;
  text-align: center;
}

.element-remainingDays .timer #days {
  font-size: 5rem;
  font-weight: 800;
  line-height: 4rem;
  color: #e87d1f;
  padding: 0 15px;
  /*    text-shadow: 0 0 7px #fff, 0 0 21px #fff, 0 0 42px #000000, 0 0 82px #000000, 0 0 92px #000000, 0 0 102px #000000, 0 0 151px #000000;*/
  text-shadow: none;
}

.element-remainingDays .timer #hours {
  font-size: 5rem;
  font-weight: 800;
  line-height: 4rem;
  color: #e87d1f;
  padding: 0 15px;
  /*    text-shadow: 0 0 7px #fff, 0 0 21px #fff, 0 0 42px #000000, 0 0 82px #000000, 0 0 92px #000000, 0 0 102px #000000, 0 0 151px #000000;*/
  text-shadow: none;
}

.element-remainingDays .timer #minutes {
  font-size: 5rem;
  font-weight: 800;
  line-height: 4rem;
  color: #e87d1f;
  padding: 0 15px;
  /*    text-shadow: 0 0 7px #fff, 0 0 21px #fff, 0 0 42px #000000, 0 0 82px #000000, 0 0 92px #000000, 0 0 102px #000000, 0 0 151px #000000;*/
  text-shadow: none;
}

.element-remainingDays .timer #seconds {
  font-size: 50px;
  font-weight: 800;
  color: #e87d1f;
  padding: 0 15px;
  /*    text-shadow: 0 0 7px #fff, 0 0 21px #fff, 0 0 42px #000000, 0 0 82px #000000, 0 0 92px #000000, 0 0 102px #000000, 0 0 151px #000000;*/
  text-shadow: none;
}

.dark-color {
  color: #212529;
}

.element-register label {
  font-family: var(--secondary-text-font-family);
  font-size: 14px;
}

.element-register input {
  border-radius: 0px;
  border: 1px solid #232323;
}

.element-register .registerBtn {
  background-color: #01682c;
  outline: none;
  box-shadow: none;
  border: none;
  border-radius: 0px;
  transition: all 0.5s;
}

.registerBtn:hover {
  background-color: #232323;
  box-shadow: none !important;
}

.registerBtn:focus {
  background-color: #000000;
  box-shadow: none !important;
}

.passwordGroup {
  position: relative;
}

.passwordGroup .toggle-password {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.line-amiation {
  padding-left: 20px;
}

.spkerinfo {
  position: relative;
  height: 406px !important;
}

.member__socials {
  position: absolute;
  bottom: 10px;
  left: 20px;
  opacity: 0;
  transition: 0.3s;
  z-index: 1;
}

.member__socials a {
  color: #ffffff;
  font-size: 24px;
  margin-right: 10px;
}

.spkerinfo::before,
.spkerinfo::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: inherit;
  transition: 0.3s;
}

.spkerinfo::after {
  opacity: 0;
  background: linear-gradient(
    180deg,
    transparent 40.63%,
    var(--e-global-color-primary) 100%
  );
}

.spkerinfo:hover .member__socials,
.spkerinfo:hover:after {
  opacity: 1;
}

/*chat style end*/

/*footer start*/
.element-footer {
  background-color: #212529;
  padding: 100px 0px 0px 0px;
  min-height: 300px;
}

.compDetlLable {
  font-size: 10px;
  margin-bottom: 0px;
  font-weight: 400;
  text-transform: uppercase;
  color: #9f9f9f;
}

.orgLogo {
  margin-bottom: 50px;
  display: flex;
}

.companyInfo .brandTitle {
  color: #ffffff;
}

.orgLogo img {
  max-height: 70px;
}

.companyDetl {
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.06rem;
  font-family: var(--secondary-text-font-family);
}

.aboutHead {
  color: #ffffff;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0.06rem;
  font-family: var(--secondary-text-font-family);
  margin: 44px 0px 50px 0px;
}

.abouList ul {
  padding-inline-start: 0px;
}

.abouList li {
  margin-bottom: 12px;
}

.abouList li a {
  display: flex;
  color: #ffffff;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.06rem;
  font-family: var(--secondary-text-font-family);
  transition: 0.8s;
}

.abouList li a .footIcon i {
  font-size: 24px;
}

.abouList li a .footIcon {
  opacity: 0;
  transition: all 0.8s;
  margin-left: -1em;
  color: var(--e-global-color-primary);
}

.abouList li a:hover .footIcon {
  opacity: 1;
  margin-left: 0em;
}

.abouList li a:hover .fotNavItem {
  margin-left: 10px;
}

/*footer end*/
.element-section.showYtvideo {
  height: 80vh;
}

.element-section.showYtvideo .element-container {
  height: 100%;
}

.element-col-100 {
  width: 100%;
}

.abouDtl {
  font-size: auto;
  line-height: normal;
  font-family: var(--secondary-text-font-family);
  color: #ffffff;
}

.developerInfo {
  width: 100%;
  border-top: 1px solid #e5e5e5;
  padding: 20px 0px;
  margin-top: 50px;
}

.developerInfo p {
  color: #d5d5d5;
  font-size: 14px;
  text-align: center;
  margin-bottom: 0px;
}

.developerInfo p a {
  color: #fff;
  font-weight: 600;
}
.speaker-slider .swiper-wrapper {
  margin-bottom: 50px;
}
.swiper-pagination-bullet {
  height: 12px;
  width: 12px;
}
.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #ffffff;
}
/*responsive ui start*/

@media screen and (max-width: 1600px) and (min-width: 1368px) {
  h3.element-title {
    font-size: 4rem;
    font-weight: 800;
    font-weight: var(--primary-font-weight);
    line-height: 4.4rem;
    padding: 0px 0px 10px 0px;
    font-family: var(--primary-font-family);
  }
  .element-widget {
    min-height: 190px;
  }
  .element-widget-card-info {
    padding: 20px;
    background-color: #e87d1f;
    transition: 0.4s;
    font-family: var(--secondary-text-font-family);
  }
  .element-widget-card {
    width: 170px;
    max-width: 195px;
    --container-widget-flex-grow: 0;
    text-align: left;
  }
  .element-wrap-spaker-img img {
    width: 52px;
    height: 52px;
    border-radius: 40px;
    border: 2px solid #fff;
  }
  .element-speakers {
    background-color: #e87d1f;
    padding-top: 100px;
    padding-bottom: 100px;
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media screen and (max-width: 1367px) and (min-width: 1021px) {
  h3.element-title {
    font-size: 3.5rem;
    font-weight: 800;
    font-weight: var(--primary-font-weight);
    line-height: 3.4rem;
    padding: 0px 0px 10px 0px;
    font-family: var(--primary-font-family);
  }
  .element-widget {
    min-height: 150px;
  }
  .element-widget-card-info {
    padding: 20px;
    background-color: #e87d1f;
    transition: 0.4s;
    font-family: var(--secondary-text-font-family);
  }
  .element-widget-card {
    width: 170px;
    max-width: 195px;
    --container-widget-flex-grow: 0;
    text-align: left;
  }
  .element-wrap-spaker-img img {
    width: 52px;
    height: 52px;
    border-radius: 40px;
    border: 2px solid #fff;
  }
  .element-speakers {
    background-color: #e87d1f;
    padding-top: 100px;
    padding-bottom: 100px;
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media screen and (max-width: 1024px) and (min-width: 721px) {
  h3.element-title {
    font-size: 2.5rem;
    font-weight: 800;
    font-weight: var(--primary-font-weight);
    line-height: 2.1rem;
    padding: 0px 0px 10px 0px;
    font-family: var(--primary-font-family);
  }
  .element-widget {
    min-height: 100px;
  }
  .element-widget-group {
    width: 100%;
    display: flex;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column-reverse;
  }
  .element-wrap-spaker-img img {
    width: 60px;
    height: 60px;
    border-radius: 40px;
    border: 2px solid #fff;
  }
  img.attachment-full.size-full {
    width: 100%;
    height: 81vh;
    object-fit: cover;
  }
  .element-widget-card71 {
    width: 100%;
  }
  .element-widget-card29 {
    width: 100%;
  }
  .element-widget-card {
    width: 100%;
    max-width: 100%;
    --container-widget-flex-grow: 0;
    text-align: left;
    padding-right: 15px;
  }
  .webDays,
  .webMonth,
  .webYear,
  .webTime {
    font-size: 18px;
    line-height: 20px;
  }
  .element-widget-info-title span {
    display: flex;
    flex-wrap: wrap;
  }
  .element-widget-info-title span span {
    margin-right: 5px;
    margin-left: 5px;
  }
  .element-speakers {
    background-color: #e87d1f;
    padding-top: 100px;
    padding-bottom: 100px;
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media screen and (max-width: 720px) and (min-width: 320px) {
  #main {
    padding: 20px 0;
  }

  .brandName .brandSlog {
    font-size: 8px;
  }

  .brandName {
    font-size: 12px;
    line-height: 12px;
  }

  h3.element-title {
    font-size: 3rem;
    line-height: 3rem;
  }

  .element-section .element-container {
    flex-direction: column;
  }

  .element-column.element-col-70 {
    margin-bottom: 20px;
  }

  .element-column.element-col-70,
  .element-column.element-col-30,
  .element-widget-card71,
  .element-widget-card29,
  .element-element.element-element-w555,
  .element-element.element-element-w445 {
    width: 100%;
  }

  .element-widget-btn-container {
    display: flex;
  }

  .element-button-2,
  .element-button {
    padding: 12px 20px 12px 20px;
    font-size: 18px;
  }

  .element-widget {
    min-height: 100px;
  }

  .element-widget-group.reverse-column {
    flex-direction: column-reverse;
  }

  .element-wrap-spaker-img img {
    width: 50px;
    height: 50px;
    border-radius: 40px;
  }

  .anchor-btn {
    display: inline-flex;
    align-items: center;
    position: relative;
    justify-content: center;
    font-size: 20px;
  }

  .element-widget-card {
    width: 100%;
    max-width: 100%;
  }

  .element-widget-card-info {
    padding: 15px 30px 15px 30px;
    margin-bottom: 30px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .element-widget-info-title {
    margin-bottom: 20px;
  }

  .element-widget-container.element-widget-image img {
    max-height: 480px;
  }

  .element-widget-timer {
    position: absolute;
    width: 100%;
    top: 35%;
  }

  .element-section#about .element-container {
    flex-direction: column-reverse;
  }

  .about-info.element-element-populated {
    padding: 0px;
  }

  .element-title-wrapper {
    position: relative;
    z-index: 1;
    line-height: 1.167em;
    text-transform: uppercase;
    letter-spacing: 0;
    width: 100%;
    margin: 0;
    font-family: var(--primary-font-family);
    font-size: 32px;
    font-weight: 600;
    line-height: 1.4em;
    padding: 0px 0px 10px 0px;
  }

  .element-widget-container.element-widget-image img.attachment-full.size-full {
    width: 100%;
  }

  .element-video-container {
    margin-bottom: 10px;
  }

  .timer #days,
  .timer #hours,
  .timer #minutes,
  .timer #seconds {
    font-size: 3rem;
    line-height: 2.8rem;
    padding: 0 10px;
  }

  .element-remainingDays .timer #days,
  .element-remainingDays .timer #hours,
  .element-remainingDays .timer #minutes,
  .element-remainingDays .timer #seconds {
    font-size: 3rem;
    line-height: 2.8rem;
    padding: 0 10px;
  }

  .pt-15 {
    padding-top: 80px;
  }
  .element-section.showYtvideo {
    height: auto;
  }

  .showYtvideo .ytStreaming,
  iframe {
    width: 100%;
    height: 212px;
  }
}

.nav-tab-header {
  font-size: 16px !important;
}

#nav-tab {
  padding-left: 20px;
  padding-right: 10px;
  padding-bottom: 10px;
  padding-top: 10px;
}

.webinarChat {
  padding: 10px;
}

.webinarChatSendMsg {
  color: white !important;
  cursor: pointer !important;
}
