.loading-bar-fetch-org {
  width: 25px !important;
  height: 25px !important;
  color: #ff9900 !important;
  cursor: progress;
}

input:disabled {
  -webkit-text-fill-color: #707070 !important;
  cursor: not-allowed;
}

.loading-bar-check-creds {
  width: 25px !important;
  height: 25px !important;
  color: white !important;
  cursor: progress;
}

.logRegForm .lrDesc {
  color: white;
  font-size: 1rem;
  font-family: Inter;
  margin-bottom: 20px;
}

.logRegForm .lrOr {
  color: #ff9900;
  font-size: 1.5rem;
  font-family: Inter;
  margin-bottom: 10px;
  margin-top: 10px;
  display: flex; /* Enable Flexbox */
  justify-content: center; /* Center horizontally */
  align-items: center;
}
.lrButton {
  margin-top: 10px !important;
}
