.joinCallBtn {
  background-color: limegreen;
  border-radius: 5px;
}

.dismissCallBtn {
  background-color: red;
  border-radius: 5px;
}

.joinCallBtn button,
.dismissCallBtn button {
  color: white !important;
}
