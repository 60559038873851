.subcriptionContainerArea {
  height: 100%;
  width: 100%;
}
.subcriptionContainer {
  padding: 40px 70px 40px 70px;
  width: calc(100% - 140px);
  height: calc(100vh - 160px);
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 10px;
  overflow: hidden;
}
.showActivePlan {
  height: 100%;
  width: 100%;
}
.persoanlPlanContainer {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
}
.otherPlanShow,
.personalPlanView {
  width: 50%;
  height: 100%;
}
.planContainer {
  height: 100%;
  width: 100%;
  border: 1px solid #777777;
  background-color: #000000;
}
.planHeader {
  height: calc(70px - 16px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #ffffff;
  padding: 8px 16px;
  width: calc(100% - 32px);
  cursor: pointer;
}
.personalHead {
  background-color: #707070;
}
.professionalhead {
  background-color: #f39c12;
}
.enterprisehead {
  background-color: #c56cf0;
}
.planName {
  font-family: "Russo One", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 24px;
  line-height: 24px;
  text-transform: uppercase;
}
.planPrice {
  font-family: "Russo One", sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 42px;
  line-height: 34px;
  letter-spacing: 1px;
}
.planDetails {
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: calc(100% - 110px);
  gap: 40px;
  overflow: hidden;
  overflow-y: overlay;
}

.planDetails::-webkit-scrollbar {
  width: 0px !important;
  height: 0px !important;
}
.planDetails::-webkit-scrollbar-thumb {
  background-color: rgba(107, 107, 107, 1);
}
.planDetails::-webkit-scrollbar-track {
  background: rgb(18 18 18 / 70%);
}
.plantext {
  font-family: Inter;
  font-size: 16px;
  color: #ffffff;
}

.planServGrid {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-auto-rows: auto;
  grid-auto-flow: dense;
}

.gridElement {
  /* background-color: #ffffff; */
  color: #ffffff;
}
.planServGrid .wide {
  grid-column: span 2;
}
.planServGrid .tall {
  grid-row: span 2;
}
.planServGrid .big {
  grid-column: span 2;
  grid-row: span 2;
}

.gridElHead {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}
.gridElHead .gridElicon img {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  pointer-events: none;
  height: 54px;
}
.gridElNm {
  font-size: 18px;
  line-height: normal;
  font-weight: normal;
  font-family: "Russo One", sans-serif;
  letter-spacing: 1px;
}
.girdElListFun ul {
  padding-inline-start: 0px;
  list-style-type: none;
  font-size: 16px;
  line-height: 24px;
  font-family: Inter;
}
.girdElListFun ul li {
  margin: 6px 0px;
}
.professionalPlanOverView {
  height: 69%;
}
.enterprisePlanOverView {
  height: 30%;
}
.otherPlanShow {
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
}
.plNm_Price {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}
.planprice {
  font-size: 18px;
  font-family: inter;
  text-transform: uppercase;
  font-weight: 600;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
.planprice span {
  font-size: 42px;
  line-height: 40px;
  font-weight: 700;
}

.upgredBtn .MuiButtonBase-root.MuiButton-root {
  padding: 10px 18px;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 0px;
  font-size: 18px;
  line-height: 44px;
  font-weight: 600;
  text-transform: uppercase;
  height: 44px;
}
.upgredBtn .MuiButtonBase-root.MuiButton-root:hover {
  background-color: #b36e01;
}
.planDetailsShow {
  padding: 0px;
  display: flex;
  flex-direction: column;
  height: calc(100% - 80px);
  gap: 10px;
  overflow: hidden;
  overflow-y: overlay;
  align-items: normal;
  justify-content: space-between;
  padding-bottom: 10px;
}
.proIconGridShow {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.proIocGrid {
  height: 90px;
  width: calc((100% - 3px) / 4);
  border: solid;
  border-color: #777777;
  border-width: 0 1px 1px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.8s;
}
.comingSoonImg img {
  transition: all 0.8s;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  pointer-events: none;
  height: 40px;
}
.proIocGrid img {
  height: 36px;
  transition: all 0.8s;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  pointer-events: none;
}
.proIocGrid:hover img {
  transform: scale(1.2);
}
.proIocGrid.noRbord {
  border-right: none;
}
.proInfodtl {
  padding: 8px 20px;
  font-family: Inter;
  font-size: 16px;
  color: #ffffff;
}
.proInfoBtnGrp {
  padding: 4px 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}
.proGrigInfo {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.proBtnInfo.MuiButtonBase-root.MuiButton-root {
  font-size: 18px;
  line-height: 44px;
  font-weight: 600;
  font-family: Inter;
  color: #fff;
  text-transform: uppercase;
  border: 1px solid #fff;
  border-radius: 0px;
  background-color: #f39c12;
  padding: 10px 18px;
  height: 44px;
  transition: all 0.4s;
}
.proBtnInfo.MuiButtonBase-root.MuiButton-root:hover {
  background-color: #b36e01;
}

/* profesional Plan Start */
.profesionalPlanContainer {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
}
.profesionalPlanView {
  width: 83.34%;
  height: 100%;
}
.proOtherPlanShow {
  width: 16.66%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
}
.personalIconGridShow {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.personalIocGrid {
  height: 120px;
  width: calc((100% - 1px) / 2);
  border: solid;
  border-color: #777777;
  border-width: 0 1px 1px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.8s;
}
.personalIocGrid img {
  height: 40px;
  transition: all 0.8s;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  pointer-events: none;
}
.personalIocGrid:hover img {
  transform: scale(1.2);
}
.personalIocGrid.noRbord {
  border-right: none;
}
.personalIocGrid.noBbord {
  border-bottom: none;
}
.personalPlanOverView .planContainer {
  height: auto;
  width: 100%;
  border: 1px solid #777777;
  background-color: #000000;
}
.comingSoonImg2 img {
  transition: all 0.8s;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
  pointer-events: none;
  height: 96px;
}

.profesionalPlanContainer .enterprisePlanOverView {
  height: 100%;
}
.comingSoonImg2 {
  margin-top: 20px;
}

.proPlanServGrid {
  display: flex;
  flex-wrap: wrap;
  padding: 0px;
  width: 100%;
  height: 100%;
}
.proPlanServGrid .gridColumn {
  flex: 33%;
  max-width: 33.33%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  border-right: 1px solid #545454;
}
.proPlanServGrid .gridColumn .gridElement {
  width: calc(100% - 40px);
  padding: 20px;
  border: solid;
  border-width: 0 1px 1px 0px;
  border-color: #545454;
}

.proPlanDetails {
  padding: 0px;
  display: flex;
  flex-direction: column;
  height: calc(100% - 70px);
  overflow: hidden;
  overflow-y: overlay;
}
.planDetailsShow::-webkit-scrollbar,
.proPlanDetails::-webkit-scrollbar {
  width: 0px !important;
  height: 0px !important;
}
.planDetailsShow::-webkit-scrollbar-thumb,
.proPlanDetails::-webkit-scrollbar-thumb {
  background-color: rgba(107, 107, 107, 1);
}
.planDetailsShow::-webkit-scrollbar-track,
.proPlanDetails::-webkit-scrollbar-track {
  background: rgb(18 18 18 / 70%);
}
.gridElement.noborder {
  border-bottom: none !important;
}
.proInfoBtnFloat {
  padding: 0px 0px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: absolute;
  bottom: 20px;
  right: 20px;
}
/* profesional Plan end */
.profesionalPlanView {
  position: relative;
  animation: 0.6s divSmoothAppearPro;
}
.personalPlanOverView {
  animation: 0.6s divSmoothAppearSmall;
}
.personalPlanView {
  animation: 0.6s divSmoothAppear;
}

@keyframes divSmoothAppearPro {
  from {
    opacity: 0;
    width: 17%;
    height: 30%;
  }
  to {
    opacity: 1;
    width: 83.34%;
    height: 100%;
  }
}

@keyframes divSmoothAppear {
  from {
    opacity: 0;
    width: 17%;
    height: 30%;
  }
  to {
    opacity: 1;
    width: 50%;
    height: 100%;
  }
}
@keyframes divSmoothAppearSmall {
  from {
    opacity: 0;
    width: 600px;
    height: 500px;
    border: 1px solid #707070;
    background-color: #000000;
  }
  to {
    opacity: 1;
    width: 100%;
    height: auto;
    /* transform: translate(0px, 0px); */
    border: none;
    background-color: transparent;
  }
}

.persoanlPlanContainer .enterprisePlanOverView {
  animation: 0.6s enterpriseBigdiv;
}
@keyframes enterpriseBigdiv {
  from {
    opacity: 0.5;
    transform: translate(-200px, -200px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}

.profesionalPlanContainer .enterprisePlanOverView {
  animation: 0.6s enterpriseBigdivSmall;
}
@keyframes enterpriseBigdivSmall {
  from {
    opacity: 0.5;
    transform: translate(400px, 100px);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}

.modelContainer {
  height: 100%;
  width: 100%;
  background-color: #00000052;
  position: fixed;
  top: 0;
  left: 0;
  backdrop-filter: blur(3px);
  z-index: 2;
}

.modelContain {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 530px;
  height: auto;
  max-height: 90vh;
  overflow: hidden;
  overflow-y: overlay;
  /* animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp; */
}
.closeModelIoc {
  position: absolute !important;
  top: 6px !important;
  right: 26px !important;
  padding: 4px !important;
  z-index: 999;
}

.animated {
  animation-duration: 0.6s;
  animation-fill-mode: both;
  -webkit-animation-duration: 0.6s;
  -webkit-animation-fill-mode: both;
}

@keyframes fadeInUp {
  from {
    transform: translate(-50%, 120%);
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadeInUp {
  from {
    transform: translate3d(0, 40px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.payCardOrCopunContainer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 0px;
}
.paymentCardInfo {
  padding: 30px;
  border-radius: 20px 20px 0px 0px;
  background-color: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 15px;
  width: calc(100% - 60px);
  position: relative;
}
.payCouponInfo {
  background-color: #fff1db;
  padding: 30px;
  border-radius: 0px 0px 20px 20px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 15px;
  width: calc(100% - 60px);
}
.inputArea {
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 100%;
}
.inputArea label {
  font-size: 16px;
  font-family: Inter;
  line-height: 24px;
}
.inputArea .inputFrmControl {
  font-size: 16px;
  line-height: 24px;
  font-family: Inter;
  color: #333333;
  border-radius: 8px;
  padding: 14px 16px;
  border: 1px solid #707070;
  outline: none;
}
.inputFrmControl.Mui-focused,
.inputArea .inputFrmControl:focus-visible {
  outline: #00adef auto 1px;
}

.inputAreaGroup {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  width: calc(100% - 16px);
}
.inputAreaGroup .inputArea {
  width: 50%;
}

button.makePamentBtn {
  font-size: 14px;
  text-transform: uppercase;
  line-height: 24px;
  color: #fff;
  text-align: center;
  padding: 6px 16px;
  border-radius: 4px;
  background-color: #00adef;
  width: 100%;
  height: 54px;
  transition: all 0.4s;
}

button.makePamentBtn:hover {
  background-color: #0086bb;
}
.inputFrmControl.custSelect {
  padding: 0px !important;
}
.inputArea
  .inputFrmControl.custSelect
  .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input {
  padding: 14px 16px;
  padding-right: 32px;
}

.inputFrmControl.custSelect .MuiSvgIcon-root.MuiSelect-icon {
  color: rgba(56, 56, 56, 0.6);
}

.opDivider {
  width: calc(100% - 40px);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: -9px;
  left: 0px;
  padding: 0px 20px;
  font-weight: 600;
  font-size: 14px;
  font-size: Inter;
}
.leftLine {
  height: 1px;
  width: 45%;
  background-color: #707070;
  margin: 4px 6px 4px 0px;
}
.rightLine {
  height: 1px;
  width: 45%;
  background-color: #707070;
  margin: 4px 0px 4px 6px;
}
.couponheading {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
}
.couponheading img {
  height: 26px;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
  pointer-events: none;
}
.couponheading .coupHedTxt {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  color: #000000;
  font-family: Inter;
}

.couponInputGroup {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 12px;
}
.inputFrmControl.couponcode {
  text-transform: uppercase;
}

.submitCouponBtn {
  padding: 6px 16px !important;
  border: 4px !important;
  color: #fff !important;
  background-color: #00adef !important;
  font-size: 14px !important;
  line-height: 24px !important;
  font-weight: 600 !important;
  text-transform: uppercase !important;
  height: 54px;
  width: 120px;
  transition: all 0.4s;
}
.submitCouponBtn:hover {
  background-color: #0086bb !important;
}
.closeModelIoc svg {
  color: #000000;
}

.modelContain.fullwidthModel {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50vw;
  min-height: 20vh;
  height: auto;
  max-height: 90vh;
  overflow: hidden;
  overflow-y: overlay;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
  scrollbar-width: none;
}
.fwModelContainArea {
  background-color: #fff;
  height: 100%;
  min-height: 20vh;
  width: calc(100% - 60px);
  border-radius: 20px;
  padding: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 20px;
}

.MuiFormControlLabel-root.formRadioField.checkWHBG {
  color: rgba(0, 0, 0, 0.6);
  margin-left: 0px;
}
/* 
.formControl .MuiAutocomplete-root.modelformAutocompleteField {
  color: #333333 !important;
  background-color: #ffffff !important;
  border-radius: 4px;
} */

.elementWithPostTextGrp {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 6;
}

.elementWithPostTextGrp .formElement {
  width: 200px;
  max-width: 400px;
}

.fwmHeader {
  font-size: 20px;
  font-weight: 700;
  position: sticky;
  top: 0px;
  background-color: #fff;
  z-index: 99;
  display: block;
  width: 100%;
  padding: 12px 0px;
  border-bottom: 1px dashed #b2b2b2;
}

.fromHeading {
  font-size: 16px;
  font-family: Inter;
  font-weight: 600;
  text-transform: capitalize;
}

.paymentStripe {
  width: 100% !important;
}
.orgFinalDomain {
  /* position: absolute; */
  /* float: right; */
  /* left: 50%; */
  margin-left: 50px;
}
.uploadedLogoOrg {
  width: 50px;
  height: 50px;
}

.actionsCreateOrg {
  display: flex;
  justify-content: space-between !important;
  width: 100% !important;
}
.fullwidthModel::-webkit-scrollbar {
  width: 0px !important;
  height: 0px !important;
}

.fullwidthModel::-webkit-scrollbar-thumb {
  background-color: rgba(107, 107, 107, 1);
}

.fullwidthModel::-webkit-scrollbar-track {
  background: rgb(18 18 18 / 70%);
}
.paymentChecked {
  color: green;
}

table.salaryTable {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #b2b2b2;
}
table.salaryTable tr td {
  border-collapse: collapse;
  border: 1px solid #b2b2b2;
  font-size: 14px;
  font-family: Inter;
  padding: 4px 2px;
}
table.salaryTable tr th {
  border-collapse: collapse;
  border: 1px solid #b2b2b2;
  font-size: 14px;
  font-family: Inter;
  font-weight: 600;
  padding: 4px 2px;
  color: #fff;
  background-color: #1c1c1c;
}
.tabAlignCent {
  text-align: center !important;
}

.removeCouponBtn {
  padding: 6px 16px !important;
  border: 4px !important;
  color: #fff !important;
  background-color: #e40909 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  font-weight: 600 !important;
  text-transform: uppercase !important;
  height: 54px;
  width: 120px;
  transition: all 0.4s;
}
.removeCouponBtn:hover {
  background-color: #e40909 !important;
}
