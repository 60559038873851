.ModelBox .formControl .MuiFormLabel-root.MuiInputLabel-root {
    font-size: 14px;
    line-height: 34px;
    font-family: Inter;
    color: rgba(0, 0, 0, 0.6);
    transform: translate(14px, 4px);
  }
  .ModelBox
    .formControl
    .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-shrink {
    background-color: transparent;
    color: #707070;
    transform: translate(14px, -10px) scale(0.75);
  }
  
  .ModelBox .formControl .MuiInputLabel-root.Mui-focused {
    color: #707070 !important;
  }

  .modelContain .formControl .MuiFormLabel-root.MuiInputLabel-root.setCompletionDate,
  .ModelBox .formControl .MuiFormLabel-root.MuiInputLabel-root.setCompletionDate  {
    font-size: 14px;
    line-height: 14px;
    font-family: Inter;
    color: rgb(0 0 0 / 50%);
    transform: translate(11px, -5px) scale(0.75);
    background-color: #ffffff;
    padding: 0px 6px;
  }
  .react-datepicker-popper {
    z-index: 5;
  }
  .ModelBox .formControl .MuiSvgIcon-root.MuiSelect-icon {
    color: rgba(0, 0, 0, 0.6);
  }
  .ModelBox
    .formControl
    .react-datepicker__input-container
    .react-datepicker__calendar-icon {
    position: absolute;
    padding: 4px;
    box-sizing: content-box;
    margin-left: 4px;
  }
  .modelContain .formElement .formDatePicker,
  .ModelBox .formElement .formDatePicker {
    padding: 8px 12px 8px 32px;
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    border: 1px solid #707070;
    outline: none;
    border-radius: 4px;
    width: 100%;
    background-color: #ffffff;
  }
  .ModelBox .formElement .formAutoComInputField .MuiButtonBase-root.MuiChip-root {
    background-color: #dddddd;
    color: #000;
    font-family: "Inter";
    cursor: pointer;
  }
  .ModelBox .MuiFormControlLabel-root.formRadioField {
    color: #333333;
  }
  .ModelBox .MuiButtonBase-root.MuiRadio-root.Mui-checked {
    color: #1976d2;
  }

  .customActiveButton{
    color: #1976d2;
  }