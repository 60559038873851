.flotingContainer {
  position: absolute;
  width: 50vw;
  height: 60vh;
  overflow: overlay;
  box-shadow: inset 0 0 0.5px 1px hsla(0, 0%, 100%, 0.075),
    /* shadow ring 👇 */ 0 0 0 1px hsla(0, 0%, 0%, 0.05),
    /* multiple soft shadows 👇 */ 0 0.3px 0.4px hsla(0, 0%, 0%, 0.02),
    0 0.9px 1.5px hsla(0, 0%, 0%, 0.045), 0 3.5px 6px hsla(0, 0%, 0%, 0.09);
  border-radius: 8px;
  border: 1px solid #606060;
}
.dragableContainArea {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.flotingDragAndDropContainer {
  position: absolute;
  min-width: 30vw;
  min-height: 30vh;
  /* min-width: 400px; */
  /* min-height: 400px; */
  overflow: overlay;
  /* box-shadow: inset 0 0 0.5px 1px hsla(0, 0%, 100%, 0.075),
     0 0 0 1px hsla(0, 0%, 0%, 0.05),
     0 0.3px 0.4px hsla(0, 0%, 0%, 0.02),
    0 0.9px 1.5px hsla(0, 0%, 0%, 0.045), 0 3.5px 6px hsla(0, 0%, 0%, 0.09); */
  border-radius: 12px;
  border: 1px solid #606060;
  resize: both;
  z-index: 1;
  cursor: move;
}

.dragableContainDragAndDropArea {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: hidden;
  box-sizing: border-box;
  cursor: context-menu;
}

.resize-handle {
  position: absolute;
  background-color: transparent;
  z-index: 1;
}

.resize-handle-top-left,
.resize-handle-top-right,
.resize-handle-bottom-left,
.resize-handle-bottom-right {
  width: 10px;
  height: 10px;
}

.resize-handle-top-left {
  top: 0px;
  left: 0px;
  cursor: nwse-resize;
}

.resize-handle-top-right {
  top: 0px;
  right: 0px;
  cursor: nesw-resize;
}

.resize-handle-bottom-left {
  bottom: 0px;
  left: 0px;
  cursor: nesw-resize;
}

.resize-handle-bottom-right {
  bottom: 0px;
  right: 0px;
  cursor: nwse-resize;
}

.resize-handle-left,
.resize-handle-right {
  position: absolute;
  top: 0;
  width: 5px;
  height: 100%;
  background-color: transparent;
}

.resize-handle-left {
  left: 0px;
  cursor: ew-resize;
}

.resize-handle-right {
  right: 0px;
  cursor: ew-resize;
}

.resize-handle-top,
.resize-handle-bottom {
  width: 100%;
  height: 5px;
}

.resize-handle-top {
  top: 0px;
  cursor: ns-resize;
}

.resize-handle-bottom {
  bottom: 0px;
  cursor: ns-resize;
}
.floatingDNDHeader {
  display: flex;
  align-items: center;
  width: calc(100% - 40px);
  justify-content: space-between;
  padding: 10px 20px;
  position: sticky;
  top: 0px;
  background-color: #606060;
  z-index: 1;
}
.fdadActionBtnGrp {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.dragableContainDragAndDropArea .driveContainerArea {
  height: auto;
  width: 100%;
}
.dragableContainDragAndDropArea .driveContainer {
  padding: 0px 70px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 10px;
  overflow: hidden;
  height: auto;
}
