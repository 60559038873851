.meeting-summary {
  display: flex;
  height: calc(100vh - 112px);
  border: 1px solid #545454;
  border-radius: 5px !important;
}

.meeting-summary .left-section,
.meeting-summary .right-section {
  padding: 20px;
  overflow-y: auto;
}

.meeting-summary .left-section {
  width: 40%;
  border-right: 1px solid #545454;
}
.meeting-summary .right-section {
  width: 60%;
}

.meeting-summary .section {
  margin-bottom: 20px;
}

.meeting-summary h2,
.meeting-summary h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.meeting-summary .transcript,
.meeting-summary .meeting-files,
.meeting-summary .meeting-links,
.meeting-summary .meeting-agenda {
  max-height: 200px;
  overflow-y: auto;
  border-radius: 0px !important;
  border-bottom: 1px dashed #545454;
  padding-bottom: 8px;
}

.meeting-summary .meeting-tasks {
  /* max-height: 200px; */
  overflow-y: auto;
  /* border: 1px solid #545454;
  padding: 10px; */
  border-radius: 5px !important;
}

.meeting-summary .transcript .buttons {
  display: flex !important;
}

.meeting-summary .transcript .translateEachBtn {
  background: transparent;
  color: white;
  border: none;
  position: relative;
  left: 10px;
  top: 6px;
  cursor: pointer;
}

.meeting-summary .meeting-links a,
.meeting-summary .meeting-files a {
  display: block;
  color: #007bff;
  text-decoration: none;
  margin: 5px 0;
}

.meeting-summary .buttons {
  margin-bottom: 10px;
  height: 35px !important;
  display: flex !important;
}

.meeting-summary .buttons button {
  margin-right: 0;
  padding: 4px 4px;
  color: #545454;
}

.meeting-summary table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 5px !important;
}

.meeting-summary table,
.meeting-summary th,
.meeting-summary td {
  border: 1px solid #545454;
}

.meeting-summary th,
.meeting-summary td {
  padding: 8px;
  text-align: left;
}

.meeting-summary .meeting-details {
  margin-top: 20px;
}

.zRTEmomMainDiv {
  width: 100% !important;
  /* border: 1px solid black; */
  /* border-radius: 10px !important; */
}

.zRTEmomMainDivFooter {
  display: flex;
  justify-content: space-between;
}
.momUsers {
  color: black !important;
}
.mSMomMW {
  max-width: 60% !important;
}
.transcripAutoComplete {
  padding: 0px !important;
}
.transcripAutoCompleteView .MuiInputBase-input.MuiOutlinedInput-input {
  padding: 0px !important;
}
.meetSummaryContainer {
  width: calc(100% - 140px) !important;
  padding: 10px 70px 10px 70px !important;
}
.meetSummaryContainer .transcriptionContainArea {
  opacity: 1;
}
.meetSummaryContainer .meeting-summary .left-section {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.metGustDtlInOutTime ul {
  margin: 0;
  padding-inline-start: 36px;
  list-style-type: none;
}
.metGustDtlInOutTime ul li .inoutTime {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 4px;
  gap: 14px;
}
.inoutTime .outTime,
.inoutTime .intime {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}
.inoutTime .outTime svg,
.inoutTime .intime svg {
  height: 20px;
  width: 20px;
  opacity: 0.7;
}
.editor-scroller .editor-input > div:first-child:focus-visible {
  outline: none;
}
.meeting-summary .right-section h2 {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 6px;
}
.transcripAutoCompleteView .MuiInputBase-root.MuiOutlinedInput-root {
  padding-right: 32px !important;
  border: 1px solid #545454;
}
.meeting-summary .right-section .transcripAutoCompleteView {
  max-width: 300px;
}
.MuiButtonBase-root.MuiIconButton-root.IconBtnTranslate {
  background-color: #333333;
  color: #fff;
  margin-left: 6px;
  width: 28px;
  height: 28px;
}
.MuiButtonBase-root.MuiIconButton-root.IconBtnTranslate svg {
  height: 18px;
  width: 18px;
}
.transcript p {
  margin: 6px 0px;
}
.section.meeting-links {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
}
.recMeetingsLinks {
  padding: 4px 8px;
  background-color: #333333;
  color: #ffffff !important;
  border-radius: 4px;
  cursor: pointer;
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.recMeetingsLinks svg {
  color: #be0000;
}
.meetingFilesGroup {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
  flex-wrap: wrap;
}
.meetFilesInd {
  padding: 4px 8px;
  background-color: #333333;
  color: #b2b2b2;
  border-radius: 6px;
}
.meetFilesInd .MuiButtonBase-root.MuiIconButton-root {
  padding: 2px;
  color: #ccc;
  margin-left: 4px;
}
.meeting-summary .section.meeting-agenda {
  max-height: min-content !important;
}
.MuiButtonBase-root.MuiIconButton-root.downloadAgendaFile {
  color: #fff;
  background-color: #333333;
  padding: 4px;
  height: 28px;
  width: 28px;
}
.poliongHand {
  color: #fff;
}
.textAlignCenter {
  text-align: center !important;
}
.nodataPresent {
  opacity: 0.5;
}
.intime svg {
  color: #00af1c;
  opacity: 1 !important;
}
.outTime svg {
  color: #ec0000;
  opacity: 1 !important;
}
.loadMoreSpn .MuiButtonBase-root.MuiButton-root {
  color: #fff;
  opacity: 0.7;
  font-style: italic;
  background-color: #444444;
  font-weight: 300;
  letter-spacing: 1px;
}
