.taskViewPannel {
  display: flex;
  flex-direction: row;
  height: calc(100vh - 80px);
  overflow: hidden;
  position: relative;
  border-left: 0.1px solid #545454;
  background-color: #000;
}
.taskViewContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: #000000;
}
.taskHeaderPanel {
  order: 1;
  display: flex;
  width: auto;
  padding: 16px 24px 16px 24px;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  background-color: #000;
  border-bottom: 1px solid #545454;
}
.taskContain {
  order: 2;
  flex: 1 1;
  padding: 15px 25px 10px;
  height: calc(100vh - 235px);
}
.taskContainDetail {
  overflow: hidden;
  overflow-y: overlay;
  position: relative;
  width: 100%;
  height: 100%;
}
.taskControlBtnGrp {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
.taskVDContBtn .MuiButton-contained {
  text-transform: capitalize;
  font-weight: 500;
  font-size: 14px;
  background: #4c4c4c;
  padding: 6px 10px;
  border-radius: 4px;
  border: 1px solid #4c4c4c;
  display: flex;
  align-items: center;
  color: #a2a2a2;
  max-height: 32px;
}
.taskVDContBtn .MuiButton-contained.Mui-disabled {
  background: #4c4c4c;
  color: #fff;
}
.taskVDContBtn .MuiButton-contained .MuiButton-startIcon {
  margin-left: 0px;
}

.taskVDContBtn .MuiButton-outlined .MuiButton-startIcon {
  margin-left: 0px;
}
.taskVDContBtn .MuiButton-contained:hover {
  color: #fff;
  background: #4c4c4c;
}
.taskVDContBtn .MuiButton-outlined:hover {
  border: 1px solid #4c4c4c;
  color: #fff;
}
.taskVDContBtn .MuiButton-outlined {
  text-transform: capitalize;
  font-weight: 500;
  font-size: 14px;
  background: transparent;
  padding: 6px 10px;
  border-radius: 4px;
  border: 1px solid #4c4c4c;
  display: flex;
  align-items: center;
  color: #a2a2a2;
  max-height: 32px;
}
.taskContainDetail::-webkit-scrollbar {
  width: 0px !important;
  height: 6px !important;
}

.taskContainDetail::-webkit-scrollbar-thumb {
  background-color: rgba(107, 107, 107, 1);
}
.taskContainDetail::-webkit-scrollbar-track {
  background: rgb(18 18 18 / 70%);
}
.closeBtnD10 {
  margin-left: 10px;
}
.taskActBtn .MuiIconButton-root {
  color: #808080;
  height: 28px;
  width: 28px;
  padding: 4px;
}
.taskActBtn .MuiIconButton-root:hover {
  color: #ffffff;
}
.taskActionBtnGrp {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2px;
}

.taskContDtlContainer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 24px;
  padding-bottom: 10px;
}
.taskTitleContrct {
  display: flex;
  padding: var(--numberLength, 0px);
  flex-direction: column;
  align-items: flex-start;
  gap: 7px;
}
.taskTitleNM {
  color: #fff;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.24px;
}

.taskContractNm {
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.16px;
}
.taskID {
  color: #eec84f;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.14px;
}
.tskElemHeding {
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.7;
}
.taskElementGrp {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  flex-direction: column;
  width: 100%;
  gap: 6px;
}
.tskTimeline {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
}
.tskTimBar {
  width: 100%;
}
.taskElementGrp .progress {
  background-color: #303030;
  border-radius: 8px;
  position: relative;
  margin: 2px 0;
  height: 5px;
  width: 100%;
}

.progress-done {
  border-radius: 8px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 0;
  opacity: 0;
  transition: 1s ease 0.3s;
  position: relative;
}
.pwCount {
  position: absolute;
  right: 0px;
  top: 6px;
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.14px;
}
.progress-done.up50 {
  /* background: linear-gradient(to left, #f2709c, #ff9472); */
  background-color: #04d500;
}
.progress-done.up80 {
  /* background: linear-gradient(to left, #9b0b3b, #a02804); */
  background-color: #d5a600;
}
.progress-done.up100 {
  background-color: #ff0000;
}

.progress-done.ps50 {
  /* background: linear-gradient(to left, #f2709c, #ff9472); */
  background-color: #0088d5;
}
.progress-done.ps80 {
  background: linear-gradient(to left, #005281, #0088d5);
  /* background-color: #04d500; */
}
.progress-done.ps100 {
  background: linear-gradient(to left, #014e7a, #0088d5);
  /* background-color: #ff0000; */
}
.tskDtSE {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.tskDTEnd,
.tskDTStart {
  color: #fff;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.12px;
}
.elmntAddMrFiles {
  order: 1;
}
.width100p {
  width: 100%;
}
/* .MuiSelect-icon.Mui-disabled,
.Mui-disabled {
  opacity: 1 !important;
  -webkit-text-fill-color: rgb(255 255 255 / 50%) !important;
  color: rgb(255 255 255 / 50%) !important;
  cursor: not-allowed;
} */
.elemntFilsgrp {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  overflow: hidden;
  max-width: 100%;
  width: 100%;
}
.horaizonScroll {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: calc(100% - 88px);
  order: 2;
}
.goNextBtn,
.goPrevousBtn {
  width: 22px;
  color: #4c4c4c !important;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 2s !important;
}
.goNextBtn:hover,
.goPrevousBtn:hover {
  color: #ffffff !important;
}
.goNextBtn svg,
.goPrevousBtn svg {
  width: 20px;
}
.goPrevousBtn:disabled {
  display: none;
  transition: all 2s !important;
}
.elemntFile {
  display: flex;
  align-items: center;
  border-radius: 0px;
  border: 1px solid #707070;
  width: -moz-fit-content;
  width: fit-content;
  transition: all 0.6s;
}
.elmFileTolTip {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 5px;
}
.elemntFilIoc {
  height: 24px;
  width: 24px;
  display: flex;
}
.elemntFilIoc svg {
  color: #707070;
  transition: all 0.6s;
}
.elemntFilNm {
  display: -webkit-box;
  width: 72px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: #fff;
  opacity: 0.6;
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.14px;
}

.elemntFile:hover .elemntFilIoc svg {
  color: #fff;
}
.elemntFile:hover .elemntFilNm {
  opacity: 1;
}

.elemntFile:hover {
  background-color: #606060 !important;
}
.elemntMrFileBtn {
  padding: 2px !important;
  border-radius: 4px !important;
  height: 34px !important;
  width: 34px !important;
  min-width: 34px !important;
  border: 1px solid #707070 !important;
  transition: all 0.6s;
}
.elemntMrFileBtn svg {
  color: #707070 !important;
  height: 30px !important;
  width: 30px !important;
  font-size: 30px !important;
  transition: all 0.6s;
}
.elemntMrFileBtn:hover {
  border: 1px solid #ffffff !important;
}
.elemntMrFileBtn:hover svg {
  color: #fff !important;
}
.elementFileListedGrp {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 7px;
  width: 100%;
}

.tskPriot {
  display: flex;
  padding: 3px 8px;
  align-items: flex-start;
  gap: 15px;
  border-radius: 4px;
  cursor: pointer;
}
.priStat {
  color: #fff;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.12px;
  text-transform: uppercase;
}
.statUrgent {
  background-color: #ff0000;
}

.statNormal {
  background-color: #19b500;
}
.statImportant {
  background-color: #edb900;
}

.taskElementsGrpWithTwo {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}
.taskElementGrp .tskElemntInfo {
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.taskElementsGrpWithTwo .taskElementGrp {
  width: 50%;
}
.taskUpCommentGrp {
  display: flex;
  padding: var(--numberLength, 0px);
  flex-direction: column;
  align-items: flex-start;
  gap: 18px;
  flex-shrink: 0;
}
.taskUserComment {
  display: flex;
  padding: var(--numberLength, 0px);
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}
.tskUderImgDT {
  display: flex;
  padding: var(--numberLength, 0px);
  align-items: flex-start;
  gap: 13px;
}
.tskUseComImg {
  height: 40px;
  width: 40px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.tskUseComImg img {
  height: 40px;
  width: 40px;
  border-radius: 40px;
  object-fit: cover;
}
.tskUsrCNM {
  display: flex;
  padding: var(--numberLength, 0px);
  flex-direction: column;
  align-items: flex-start;
  gap: 3px;
}
.tskUCNM {
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.tskUCDT {
  color: #fff;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: -0.12px;
}

.taskUPComnt {
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.14px;
}
.taskFooterPanel {
  order: 3;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 6px 24px 10px 24px;
}
.tskInputGrp {
  display: flex;
  width: 100%;
  border-radius: 20px;
  background-color: #474747;
}
.tskComntInput {
  height: 100%;
  flex: 1 1;
  border: none;
  outline: none;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background-color: transparent;
  padding: 6px 10px 6px 22px;
  height: 28px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
}
.tskComtSndBtn {
  color: #d9d9d9;
  padding: 0px 16px;
}
.tskComtSndBtn svg {
  color: #d9d9d9;
}

.taskTempaltesPannel {
  display: flex;
  flex-direction: row;
  height: calc(100vh - 80px);
  overflow: hidden;
  position: relative;
  border-left: 0.1px solid #545454;
  background-color: #000;
}
.taskTemplatesContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: #000000;
}
.taskTempHeaderPanel {
  order: 1;
  display: flex;
  width: auto;
  padding: 16px 24px 16px 24px;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  background-color: #000;
  border-bottom: 1px solid #545454;
}
.tskTempContainFull {
  order: 2;
  flex: 1 1;
  padding: 15px 25px 10px;
}
.tskTempContainFull .taskContain {
  padding: 15px 0px 5px;
  height: calc(100vh - 225px);
}
.tskHedCon {
  font-family: Inter;
  font-size: 22px;
  font-weight: 600;
  color: #ffffff;
}

.taskContainDetail {
  overflow: hidden;
  overflow-y: overlay;
  position: relative;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none;
  width: 100%;
  height: 100%;
}

.elemntFilsgrpTemplate {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  flex-wrap: wrap;
  overflow: hidden;
}
.moreTempList,
.suggestTempList {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
}
.moreTemp,
.sugestTemp {
  border-bottom: 1px solid #545454;
  padding: 10px 0px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 10px;
}
.moreTempNm,
.sugTempNm {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  gap: 4px;
}
.moreTempIOC svg,
.sugTempIOC svg {
  height: 22px;
  width: 22px;
  color: #707070;
}
.moreTemFlNm,
.sugTemFlNm {
  font-size: 14px;
  line-height: normal;
  color: #ffffff;
  font-family: Inter;
}
.moreTemBtnGrp,
.sugTemBtnGrp {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}
button.moreTemButton,
button.sugTemButton {
  border-radius: 4px !important;
  padding: 6px 10px !important;
  background-color: #707070 !important;
  color: #ffffff !important;
  font-size: 14px !important;
  line-height: 14px !important;
  font-family: inter;
  text-transform: capitalize;
}
.viewAllSugTemp {
  width: 100%;
  text-align: end;
}
.viewAllSugTemp span {
  color: #ffffff;
  font-size: 12px;
  font-family: Inter;
  cursor: pointer;
}

.morTaskSearch {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
}
.morTaskSearch .searchUser {
  display: flex;
  width: -webkit-fill-available;
  padding: 5px 9px;
  align-items: center;
  gap: 14px;
  border-radius: 4px;
  background: #272727;
}
.morTaskSearch .searchIcon {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}
.morTaskSearch .searchIcon svg {
  color: #a8a8a8;
  width: 20px;
  height: 20px;
}
.morTaskSearch .serchInput {
  width: 100%;
  flex-shrink: 100%;
  flex: 1 1;
  color: #ffffff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  background-color: transparent;
  outline: none;
  box-shadow: none;
  border: none;
}
button.srchMrTempBtn {
  border-radius: 4px !important;
  padding: 6px 16px !important;
  background-color: #00adfe !important;
  color: #ffffff !important;
  font-size: 14px;
  line-height: 24px;
}
.taskTemplatesContainer .taskTitleNM {
  color: #fff;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.24px;
}

.morAvlTemp {
  font-size: 12px;
  color: #ffffff;
  font-family: "Inter";
}
.tskAprovedItmList {
  height: 100%;
  width: 100%;
}

.tskAprovedItmTable {
  width: 100%;
}

.tskAprovedItmTable .MuiTableCell-root.MuiTableCell-head {
  font-family: "Inter";
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.01071em;
  display: table-cell;
  vertical-align: inherit;
  border-bottom: 1px solid #cbcbcb;
  text-align: left;
  padding: 16px 16px;
  color: rgb(255 255 255);
  background-color: transparent;
}

.MuiTableCell-root.MuiTableCell-body {
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.01071em;
  display: table-cell;
  vertical-align: inherit;
  border-bottom: 1px solid #545454;
  text-align: left;
  padding: 10px 16px;
  color: rgba(255, 255, 255, 0.8);
  background-color: transparent;
}
.tskElemntInfo {
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.createTaskContainerArea {
  height: 100%;
  width: 100%;
  display: block;
}
.createTaskContainArea {
  height: 100%;
  width: 100% !important;
  margin: 0;
  padding: 0;
}
.cerateTaskContainer {
  padding: 10px 70px 10px 70px;
  width: calc(100% - 140px);
  height: calc(100vh - 100px);
}
.pRelative {
  position: relative;
}
.RightFlotingContainerArea {
  height: calc(100vh - 80px);
  width: 46vw;
  position: absolute;
  bottom: 0px;
  right: 0px;
  background-color: #000000;
  z-index: 99;
}
.cerateTaskContainer {
  width: 610px;
  height: calc(100vh - 100px);
  flex-shrink: 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 10px;
  overflow: hidden;
}

.elementFormContainer {
  overflow: hidden !important;
  overflow-y: overlay !important;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 6px;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none;
}

.elementFormContainer::-webkit-scrollbar {
  width: 0px !important;
  height: 0px !important;
}

.elementFormContainer::-webkit-scrollbar-thumb {
  background-color: rgba(107, 107, 107, 1);
}

.elementFormContainer::-webkit-scrollbar-track {
  background: rgb(18 18 18 / 70%);
}

.formControl {
  width: 100%;
  height: auto;
  margin: 2px 0px !important;
}
.formInputField {
  color: #ffffff !important;
  background-color: #333333 !important;
  /* width: 100%;
  height: 38px;
  padding: 10px 18px;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #707070;
  background: #333; */
}
.formControl .MuiOutlinedInput-notchedOutline {
  border: 1px solid #707070 !important;
  color: #ffffff;
}
.formControl .MuiFormLabel-root.MuiInputLabel-root {
  font-size: 14px;
  line-height: 34px;
  font-family: Inter;
  color: rgb(255 255 255 / 60%);
  transform: translate(14px, 4px);
}
.formControl .MuiAutocomplete-root.formAutocompleteField {
  color: #ffffff !important;
  background-color: #333333 !important;
  /* z-index: 1; */
}
.formAutoComInputField input.MuiInputBase-input.MuiOutlinedInput-input {
  padding: 8px 32px 8px 12px;
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  min-height: 1.4375em;
  height: 24px;
}
.formControl .MuiSelect-select.MuiSelect-outlined {
  padding: 8px 32px 8px 12px;
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.formAutocompleteField .MuiInputBase-root.MuiOutlinedInput-root {
  padding: 0px;
}
.formControl .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-shrink {
  background-color: transparent;
  color: #707070;
  transform: translate(14px, -10px) scale(0.75);
}
.formTextAreaField input {
  padding: 8px 32px 8px 12px;
  border-radius: 4px;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #ffffff !important;
  background-color: #333333 !important;
  height: 24px;
  /* border: 1px solid #707070 !important; */
}

.formTextFieldArea input {
  padding: 8px 32px 8px 12px;
  border-radius: 4px;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #ffffff !important;
  background-color: #333333 !important;
  height: 24px;
  /* border: 1px solid #707070 !important; */
}
.MuiSvgIcon-root.MuiSelect-icon {
  color: rgb(255 255 255 / 60%);
}

.crtTaskHeader {
  color: #fff;
  font-family: Inter;
  font-size: 28px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.MuiFormGroup-root.formRadioGroup {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
  padding-left: 10px;
}
.MuiFormControlLabel-root.formRadioField {
  color: #ffffff;
}
.MuiFormControlLabel-root.formRadioField svg {
  color: #b2b2b2;
  height: 20px;
  width: 20px;
}
.formElementGroup {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
  width: 100%;
}
.mt40 {
  margin-top: 40px;
}
.comBtnOutline {
  border: 1px solid #707070 !important;
  color: #545454 !important;
}
.formElement {
  width: 100%;
}
.formElement .removeRowBtn {
  color: #f7a7a7 !important;
  background-color: #333 !important;
}
.formElement .matrixHeadFlex {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  color: #fff;
  font-size: 16px;
}
.pmsMatricsList .formElement {
  padding-bottom: 4px;
  margin-bottom: 10px;
}
.formElement .matrixHeadFlex span {
  color: #fff;
}
.formElement .formDatePicker {
  padding: 8px 12px 8px 36px;
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  border: 1px solid #707070;
  outline: none;
  border-radius: 4px;
  width: calc(100% - 48px);
  background-color: #333333;
}
.react-datepicker__input-container.react-datepicker__view-calendar-icon {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.react-datepicker__calendar-icon {
  fill: #707070;
}

.formControl .MuiFormLabel-root.MuiInputLabel-root.setCompletionDate {
  font-size: 14px;
  line-height: 14px;
  font-family: Inter;
  color: rgb(255 255 255 / 50%);
  transform: translate(11px, -1px) scale(0.75);
  background-color: #333333;
  padding: 0px 6px;
}
.formControl
  .react-datepicker__input-container
  .react-datepicker__calendar-icon {
  position: absolute;
  padding: 0.5rem;
  box-sizing: content-box;
  margin-left: 4px;
}

.ModelBox .formControl .MuiFormLabel-root.MuiInputLabel-root {
  font-size: 14px;
  line-height: 34px;
  font-family: Inter;
  color: rgba(0, 0, 0, 0.6);
  transform: translate(14px, 4px);
}
.ModelBox
  .formControl
  .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-shrink {
  background-color: transparent;
  color: #707070;
  transform: translate(14px, -10px) scale(0.75);
}

.ModelBox .formControl .MuiInputLabel-root.Mui-focused {
  color: #707070 !important;
}

.formControl .modelSelectFild .MuiSelect-select.MuiSelect-outlined {
  padding: 8px 32px 8px 12px;
  color: #000000;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.formControl .modelSelectFild .MuiOutlinedInput-notchedOutline {
  border: 1px solid #b2b2b2 !important;
  color: #ffffff;
}
.ModelBox .formControl .MuiFormLabel-root.MuiInputLabel-root.setCompletionDate {
  font-size: 14px;
  line-height: 14px;
  font-family: Inter;
  color: rgb(0 0 0 / 50%);
  transform: translate(11px, -5px) scale(0.75);
  background-color: #ffffff !important;
  padding: 0px 6px;
}
.react-datepicker-popper {
  z-index: 5;
}
.ModelBox .formControl .MuiSvgIcon-root.MuiSelect-icon {
  color: rgba(0, 0, 0, 0.6);
}
.ModelBox
  .formControl
  .react-datepicker__input-container
  .react-datepicker__calendar-icon {
  position: absolute;
  padding: 4px;
  box-sizing: content-box;
  margin-left: 4px;
}
.ModelBox .formElement .formDatePicker {
  padding: 8px 12px 8px 32px;
  color: #000 !important;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  border: 1px solid #b2b2b2 !important;
  outline: none;
  border-radius: 4px;
  width: 100%;
  background-color: #ffffff !important;
}
.ModelBox .formElement .formAutoComInputField .MuiButtonBase-root.MuiChip-root {
  background-color: #dddddd;
  color: #000;
  font-family: "Inter";
  cursor: pointer;
}
.ModelBox .MuiFormControlLabel-root.formRadioField {
  color: #333333;
}
.ModelBox .MuiButtonBase-root.MuiRadio-root.Mui-checked {
  color: #1976d2;
}
.descptionTextFild .MuiInputBase-root.MuiOutlinedInput-root {
  font-family: "Inter";
  background: #333333;
  color: #fff;
}
.formBtnElement {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.workUpdateBtnGrp {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
  margin-bottom: 30px;
  margin-top: 8px;
  flex-wrap: wrap;
}
.commonUpdate .workUpdateBtnGrp {
  margin-bottom: 16px;
}
.workUpdateBtnGrp .MuiButtonBase-root.MuiButton-root {
  padding: 4px !important;
  min-width: 0px;
  width: 32px;
  height: 32px;
  border-radius: 34px;
  font-size: 12px;
  font-family: Inter;
  line-height: 18px;
  color: #ffffff;
  background-color: #00adef;
}
.workUpdateBtnGrp .MuiButtonBase-root.MuiButton-root.Mui-disabled {
  color: #a2a2a2;
  background-color: #dedede;
}

.workUpdateBtnGrp .activeStatus {
  background-color: #005ccf !important;
  border: 1px solid #005ccf !important;
}
.activeButton {
  background-color: red;
}
.activeStatus {
  /* background-color: #00adef !important;  */
  color: #fff !important;
  border: 1px solid #00adef !important;
}
.audioNote.activeStatus {
  background-color: #00adef !important;
  color: #fff !important;
}
.uploadedFiles {
  color: #ffffff;
  margin: 20px 0px;
}
.recodedAudioNots {
  color: #000000;
  font-family: Inter;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.7;
  display: flex;
  width: 100%;
}
.fullWidth {
  width: 100%;
}
.playRemoveAudioNotes {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  margin-bottom: 20px;
}
.ryvnHeading {
  color: #000000;
  font-family: Inter;
  font-size: 16px;
  line-height: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 10px;
}
.hedUpFils {
  font-size: 18px;
  font-family: Inter;
  color: #fff;
  font-weight: 600;
}
.formElement .formAutoComInputField .MuiButtonBase-root.MuiChip-root {
  background-color: #707070;
  color: #fff;
  font-family: "Inter";
  cursor: pointer;
}

.autocompFildPlaceholder::placeholder {
  color: rgb(255 255 255 / 60%);
}

.autocompFildPlaceholder::-ms-input-placeholder {
  color: rgb(255 255 255 / 60%);
}

.formElement
  .MuiAutocomplete-endAdornment
  .MuiButtonBase-root.MuiIconButton-root
  svg {
  color: rgb(255 255 255 / 60%);
}
.formAutoComInputField .MuiChip-deleteIcon {
  color: rgb(255 255 255 / 60%);
}

.modTextareaFild {
  width: 100%;
}
.formControl .modelTextFild .MuiInputBase-root.MuiOutlinedInput-root {
  font-family: Inter;
  padding: 10px 32px 10px 12px;
}

.formControl .modelTextFild .MuiOutlinedInput-notchedOutline {
  border: 1px solid #b2b2b2 !important;
  color: #ffffff;
}
.formControl .modelTextFild .MuiFormLabel-root.MuiInputLabel-root.Mui-focused {
  color: rgba(0, 0, 0, 0.6) !important;
}
.formControl .modelTextFild .MuiFormLabel-root.MuiInputLabel-root {
  font-size: 14px;
  line-height: 34px;
  font-family: Inter;
  color: rgba(0, 0, 0, 0.6);
  transform: translate(14px, 4px);
}
.formControl
  .modelTextFild
  .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-shrink {
  background-color: transparent;
  color: #707070;
  transform: translate(14px, -10px) scale(0.75);
}

.formControl .modelformAutocompleteField {
  color: #000000 !important;
  background-color: #ffffff !important;
}
.modelformAutocompleteField .MuiInputBase-root.MuiOutlinedInput-root {
  padding: 2px 32px 2px 12px !important;
}
.modelformAutocompleteField input.MuiInputBase-input.MuiOutlinedInput-input {
  padding: 8px 32px 8px 0px;
  color: #000000;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  min-height: 1.4375em;
  height: 24px;
}

.formElement
  .modelformAutocompleteField
  .MuiAutocomplete-endAdornment
  .MuiButtonBase-root.MuiIconButton-root
  svg {
  color: rgba(0, 0, 0, 0.6);
}

.formControl .modelformAutocompleteField .MuiOutlinedInput-notchedOutline {
  border: 1px solid #b2b2b2 !important;
  color: #707070;
}
.ModcomBtnOutline {
  border: 1px solid #b2b2b2 !important;
  color: #707070 !important;
  width: fit-content;
}
/* task list start */
.taskContainerArea {
  height: 100%;
  width: 100%;
  display: block;
}
.taskContainArea {
  height: 100%;
  width: 100% !important;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.taskContainer {
  padding: 10px 70px 10px 70px;
  width: calc(100% - 140px);
  height: calc(100vh - 100px);
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 10px;
  overflow: hidden;
}
.taskListHeader {
  color: #fff;
  font-family: Inter;
  font-size: 28px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  cursor: pointer;
}
.searchTasklist {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 320px;
  border: 1px solid #707070;
  background-color: #333333;
  border-radius: 4px;
  height: 38px;
}
.serchTaskInput {
  width: 100%;
  flex-shrink: 0;
  flex: 1 1;
  color: #ffffff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  background-color: transparent;
  outline: none;
  box-shadow: none;
  border: none;
  height: 100%;
  padding: 0px 10px;
  /* border-right: 1px solid #545454; */
}
.searchTasklist .seacrhIcon {
  padding: 8px;
  height: 38px;
  width: 38px;
  color: #ffffff;
  background-color: #333333 !important;
  border-radius: 0px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.filterTaskListBtn {
  color: #707070 !important;
}
.taskListTopBtnGroup {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
}
.taskListTopBtnLeft {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  flex: 1 1;
}
.taskListTopBtnRight {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}
.Mui-disabled.dfultDarkSecondaryBtn {
  border: 1.6px solid transparent !important;
  color: #707070 !important;
}
.dfultPrimaryBtn svg {
  height: 24px;
  width: 24px;
}
.taskListTableContainer {
  height: calc(100vh - 195px);
  width: 100%;
  overflow: hidden;
  overflow-y: overlay;
  border-bottom: 1px solid #545454;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none;
}
.taskListTableContainer::-webkit-scrollbar {
  width: 0px !important;
  height: 6px !important;
}
.taskListTableContainer::-webkit-scrollbar-thumb {
  background-color: rgba(107, 107, 107, 1);
}
.taskListTableContainer::-webkit-scrollbar-track {
  background: rgb(18 18 18 / 70%);
}
.taskListTable {
  width: 100%;
  border-spacing: 1px;
}
.taskListtableHeader {
  background-color: #262626;
  height: 48px;
  box-shadow: inset 0 -1px 0 #474747;
}
.taskListtableHeader tr th {
  font-size: 14px;
  font-family: Inter;
  font-weight: 600;
  color: #ffffff;
  text-align: left;
  padding-left: 10px;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #262626;
}
.taskListTable .MuiButtonBase-root.MuiCheckbox-root {
  color: #707070;
}
.taskListTable .MuiButtonBase-root.MuiCheckbox-root svg {
  height: 18px;
  width: 18px;
}
.taskContainArea .RightFlotingContainerArea {
  /* height: calc(100vh - 60px) !important; */
}
.listOfLinkTask {
  padding-inline-start: 16px;
  margin-block-start: 0px;
}
.listOfLinkTask .linkTskInd {
  cursor: pointer;
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 4px;
}
.linktaskBreadCrumb {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
.linktaskBreadCrumb .MuiButtonBase-root.MuiIconButton-root {
  background-color: #333333;
  color: #ffffff;
}
.prentTaskNM {
  font-size: 14px;
  font-family: Inter;
  color: #eec84f;
  cursor: pointer;
}
.taskListTable .t-w38 {
  width: 38px;
  padding-left: 5px;
}
.linkTskIoc svg {
  color: #707070;
  height: 18px;
  width: 18px;
}
.inOutIcon .outGoingTask svg,
.inOutIcon .incomTask svg {
  height: 21px;
  width: 18px;
}
.tskLstTaskNM {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 7px;
  color: #b2b2b2;
  font-size: 14px;
  font-family: "Inter";
}
.tskLstTaskDescription {
  font-size: 14px;
  font-family: "Inter";

  display: -webkit-box;
  width: 300px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: #fff;
  opacity: 0.6;
  text-overflow: ellipsis;
}
.tskLstTaskTypeNm {
  color: #b2b2b2;
  font-size: 14px;
  font-family: "Inter";
}
.tskLstTaskContract {
  color: #b2b2b2;
  font-size: 14px;
  font-family: "Inter";
}
.tskLstTaskStatus {
  color: #b2b2b2;
  font-size: 14px;
  font-family: "Inter";
}
.tskLstTaskPriority {
  background-color: #3d3d3d;
  border-radius: 4px;
  font-size: 14px;
  line-height: 24px;
  padding: 0 10px;
  color: #ffffff;
  text-transform: uppercase;
  text-align: center;
  max-width: 90px;
}
.statRoutine {
  border-left: 5px solid #03ab00;
}
.statCritical {
  border-left: 5px solid #f90000;
}
.statImportant {
  border-left: 5px solid #edb900;
}
.tskLstTaskCompDt {
  font-size: 14px;
  font-family: Inter;
  color: #b2b2b2;
}
.tskLstTaskRequstBy {
  font-size: 14px;
  font-family: Inter;
  color: #b2b2b2;
}
.taskListTable tbody tr td {
  padding-left: 10px;
}
.taskListTable tbody tr {
  box-shadow: inset 0 -1px 0 #333333;
  border-spacing: 0px;
  transition: all 0.4s;
  cursor: pointer;
  height: 38px;
}
.taskListTable tbody tr:hover {
  background-color: #333333;
}
.taskListTable tbody tr.trowSelected {
  background-color: #000000;
}
.taskListTable tbody tr.highlitedTask {
  background-color: rgb(56, 56, 56);
}

ol.listOfLinkTask {
  counter-reset: item;
}
.listOfLinkTask li.linkTskInd {
  display: block;
}
.listOfLinkTask li.linkTskInd:before {
  content: counters(item, ".") ".  ";
  counter-increment: item;
  margin-right: 4px;
  color: #ffffff;
  opacity: 0.7;
  font-weight: 600;
  font-family: "Inter";
}
.linkTskNm {
  transition: all 0.4s;
}

.linkTskNm:hover {
  letter-spacing: 0.5px;
  color: #eec84f;
}
.tskLstTaskNM .tskListTskName {
  font-size: 14px;
  font-family: "Inter";
  display: -webkit-box;
  max-width: 300px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: #fff;
  opacity: 0.6;
  text-overflow: ellipsis;
}
.linktaskBreadCrumb button.MuiButtonBase-root.MuiIconButton-root {
  height: 26px;
  width: 26px;
  padding: 4px;
}

.multipleUpdateTaskCard {
  border-bottom: 0.5px solid #545454;
  width: 100%;
  padding: 10px 0px;
}
/* task list end */

.commonUpdate .formElement {
  /* max-width: 320px; */
}

.commonUpdate {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 10px;
  gap: 6px;
}

.taskHeadingNM {
  color: #fff;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.24px;
  margin-bottom: 10px;
}
.tskCretdByElmnt {
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.24px;
  margin-bottom: 10px;
}

.commonUpdat .workUpdateBtnGrp {
  margin-bottom: 10px;
  margin-top: 10px;
}
.commonUpdate
  .modInputFild
  .MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementEnd {
  color: #fff;
}

.commonUpdate .modInputFild .MuiButtonBase-root.MuiCheckbox-root {
  color: #545454;
}

/* floting form start */
.RightFlotingFormContainerArea {
  height: calc(100vh - 80px);
  width: 52vw;
  position: absolute;
  bottom: 0px;
  right: 0px;
  background-color: #000000;
  z-index: 99;
}
.RightFlotingContainerArea .RightFlotingFormContainerArea {
  position: fixed;
}
.flottingContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: #000000;
}
.flotHeaderPanel {
  order: 1;
  display: flex;
  width: auto;
  padding: 16px 24px 16px 24px;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  background-color: #000;
  border-bottom: 1px solid #545454;
}
.flotHedCon {
  font-family: Inter;
  font-size: 22px;
  font-weight: 600;
  color: #ffffff;
}
.flotActionBtnGrp {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2px;
}
.flotActBtn .MuiIconButton-root {
  color: #808080;
  height: 28px;
  width: 28px;
  padding: 4px;
}

.flotContain {
  order: 2;
  flex: 1 1;
  padding: 15px 25px 10px;
  height: calc(100vh - 235px);
}
.flotFooterPanel {
  order: 3;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 24px 10px 24px;
  border-top: 1px solid #545454;
}
.RightFlotingFormContainerArea .formElement .MuiButtonBase-root.MuiRadio-root {
  padding: 6px;
}
.RightFlotingFormContainerArea .formElement {
  width: 100%;
  border-bottom: 1px solid #141414;
}
.formElement
  .MuiFormControl-root
  .MuiFormLabel-root.MuiFormLabel-colorPrimary.radioLabel {
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.7;
}
/* floting form end */

.formRadioGroup .MuiTypography-root {
  font-size: 14px;
  font-family: Inter;
}

.RightFlotingFormContainerArea .multiFormGroup .formElement {
  width: auto;
  min-width: 160px;
  border-bottom: none;
}
.RightFlotingFormContainerArea .multiFormGroup {
  width: 100%;
  border-bottom: 1px solid #141414;
  padding: 0px 0px;
}

.RightFlotingFormContainerArea
  .formControl
  .MuiSelect-select.MuiSelect-outlined {
  padding: 6px 32px 6px 12px;
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.RightFlotingFormContainerArea .formElement .formDatePicker {
  padding: 5px 12px 5px 32px;
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  border: 1px solid #707070;
  outline: none;
  border-radius: 4px;
  width: calc(100% - 0px);
  background-color: #333333;
}

.RightFlotingFormContainerArea
  .formControl
  .MuiFormLabel-root.MuiInputLabel-root.setCompletionDate {
  font-size: 14px;
  line-height: 14px;
  font-family: Inter;
  color: rgb(255 255 255 / 50%);
  transform: translate(11px, -2px) scale(0.75);
  background-color: #333333;
  padding: 0px 6px;
}

.RightFlotingFormContainerArea
  .formControl
  .MuiFormLabel-root.MuiInputLabel-root {
  font-size: 14px;
  line-height: 34px;
  font-family: Inter;
  color: rgb(255 255 255 / 60%);
  transform: translate(14px, 2px);
}

.RightFlotingFormContainerArea
  .formControl
  .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-shrink {
  background-color: transparent;
  color: #707070;
  transform: translate(14px, -10px) scale(0.75);
}

.RightFlotingFormContainerArea .formTextFieldArea input {
  padding: 6px 32px 6px 12px;
  border-radius: 4px;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: #ffffff !important;
  background-color: #333333 !important;
  height: 24px;
  /* border: 1px solid #707070 !important; */
}
.indentFormtable {
  width: 100%;
  height: auto;
  max-height: 100%;
}
.indtableResponsive {
  overflow-x: auto;
  margin-bottom: 20px;
}

.indentFormtable table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #141414;
  color: #ffffff;
}
.indentFormtable table th,
.indentFormtable table td {
  border: 1px solid #141414;
  border-collapse: collapse;
  text-align: left;
  padding: 4px;
  color: #b4b4b4;
  height: 26px;
}
.multiFormGroup .formElementGroup {
  flex-wrap: wrap;
}
.indentFormtable table tr th {
  font-size: 14px;
  font-family: Inter;
  padding: 4px !important;
  min-width: 160px;
  width: auto;
  cursor: pointer;
  background-color: #1c1c1c;
}
.indentFormtable table tr th.widthMin120 {
  min-width: 120px;
}
.indentFormtable .MuiButtonBase-root.MuiIconButton-root {
  color: #fff;
  background-color: #141414;
  padding: 4px;
}
.indentFormtable .MuiButtonBase-root.MuiIconButton-root svg {
  font-size: 22px;
  height: 22px;
  width: 22px;
  fill: #fff;
}
.mt20 {
  margin-top: 20px;
}
.w200 {
  min-width: 200px !important;
}
.mw80 {
  max-width: 80px !important;
  min-width: 80px !important;
}
.mdButtonGroup {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  margin-top: 10px;
}
.mdButtonGroup .formBtnElement {
  width: auto;
  min-width: 250px;
}
.mdButtonGroup .formElement {
  width: auto;
}
.formBtnElement .comBtnOutline {
  border: 1px solid #707070;
  color: #545454;
  padding: 6px 16px;
  font-size: 14px;
  border-radius: 4px;
}
.iconBtnGroup {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}
.fw600 {
  font-weight: 700;
  color: #fff;
}
.tvText {
  text-align: right;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
}
.mt5 {
  margin-top: 5px;
}
td .textOnly {
  font-size: 14px;
  font-family: Inter;
}
td .textOnlySub {
  font-size: 12px;
  font-family: Inter;
  opacity: 0.8;
}
.iconBtnGroup .saveBtn {
  background-color: #707070 !important;
  color: #fff !important;
  border: none !important;
  padding: 6px 4px !important;
  min-width: 54px !important;
  font-size: 14px;
  line-height: 14px;
}
.formRadioField .MuiButtonBase-root.MuiCheckbox-root {
  padding: 6px;
}
.hoverVisible {
  opacity: 0;
}
.indtableResponsive table tbody tr {
  cursor: pointer;
  transition: all 0.4s;
}
.indtableResponsive table tbody tr:hover {
  background-color: #0a0a0a;
}
.indtableResponsive table tbody tr:hover .hoverVisible {
  opacity: 1;
}

.indntQunt {
  min-width: 100px !important;
}
.StockQty {
  min-width: 80px;
}

.taskElementGrpRow {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  flex-direction: row;
  width: 100%;
  gap: 25px;
}
.taskElementGrpRow .tskElemHeding {
  min-width: 155px;
  max-width: 155px;
}
.previewSpace {
  gap: 16px !important;
}

.inlineDoc {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}

.RightFlotingFormContainerArea .ModelBox .formElement {
  width: 100%;
  border-bottom: 1px solid transparent;
}

.RightFlotingFormContainerArea
  .ModelBox
  .formControl
  .MuiSelect-select.MuiSelect-outlined {
  padding: 6px 32px 6px 12px;
  color: #333333;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.RightFlotingFormContainerArea
  .ModelBox
  .formControl
  .MuiFormLabel-root.MuiInputLabel-root {
  color: rgba(0, 0, 0, 0.6);
}
.elementWithAddElm {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  flex-direction: column;
  gap: 2px;
  width: 100%;
}
.addelemBtn {
  font-size: 12px;
  font-family: Inter;
  color: #005ccf;
  cursor: pointer;
  text-decoration: underline;
  transition: all 0.6s;
}
.addelemBtn:hover {
  color: #333333;
}

.pdl10 {
  padding-left: 10px;
}
.ModelBox .elementFormContainer {
  max-height: 60vh;
}

.smallModal {
  width: 350px;
}

.formAutocompleteField .MuiInputBase-input.MuiOutlinedInput-input {
  color: #fff;
}
.MuiPopper-root.MuiAutocomplete-popper.MuiAutocomplete-popperDisablePortal {
  z-index: 1;
  overflow: none;
}

.mrl7px {
  margin-left: 7px;
}
/* .MuiOutlinedInput-notchedOutline legend {
  max-width: 100%;
} */
.singleUpldfile {
  display: flex;
  align-items: center;
  color: #b2b2b2;
  font-size: 14px;
  margin-left: 10px;
  text-decoration: underline;
}
.singleUpldfile .MuiButtonBase-root.MuiIconButton-root svg {
  color: #ffa5a8;
  height: 16px;
}

.height36 {
  height: 36px;
}
.ModelBox
  .formControl
  .MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementEnd {
  justify-content: flex-end !important;
  margin-left: 0px;
  color: #000000;
}

.MuiFormGroup-root.formRadioGroup.column {
  flex-direction: column !important;
  align-items: flex-start;
  gap: 0px;
}

.ModelBox .tskElemHeding {
  color: #1c1c1c;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.7;
}

.ModelBox .addNewElmBtn {
  padding: 6px 16px !important;
  font-size: 14px !important;
  border-radius: 4px !important;
  background-color: transparent !important;
  border: 1px solid #707070 !important;
  color: #545454 !important;
  font-family: Inter !important;
  transition: all 0.8s !important;
  text-transform: capitalize !important;
  font-weight: 500;
  min-width: 106px !important;
}

.comBtnActiveStatus {
  background-color: #00adef !important;
  color: #fff !important;
  outline: none !important;
  border: none !important;
}
.bankingAcDtlCrd {
  border-radius: 6px;
  background-color: #f2f2f2;
  padding: 8px;
  margin-bottom: 10px;
  cursor: pointer;
  width: calc(100% - 16px);
}
.bankingAcDtlCrd ul {
  list-style-type: none;
  padding-inline-start: 26px;
  margin: 0px;
}
.bankingAcDtlCrdList li {
  font-size: 14px;
  font-weight: 600;
}
.bankingAcDtlCrdList li span {
  opacity: 0.7;
  min-width: 100px;
  display: inline-block;
}
.bankingAcDtlCrd .MuiFormControlLabel-root .MuiTypography-root {
  font-size: 14px;
  font-weight: 600;
}
.bankingAcDtlCrd .MuiFormControlLabel-root .MuiButtonBase-root.MuiRadio-root {
  padding: 2px;
  margin-left: 12px;
}
.UplodedFilNm span.flnm {
  display: -webkit-box;
  width: 123px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: #000;
  opacity: 1;
  text-overflow: ellipsis;
}
.UplodedFilNm {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}
.UplodedFilNm svg {
  color: #ed3237;
}
.fontColorWhite {
  color: #fff;
}
.MuiButtonBase-root.MuiButton-root.showJdKraBtn {
  text-transform: capitalize;
  font-weight: 500;
  font-size: 14px;
  background: #4c4c4c;
  padding: 6px 10px;
  border-radius: 4px;
  border: 1px solid #4c4c4c;
  display: flex;
  align-items: center;
  color: #a2a2a2;
  max-height: 32px;
  min-width: 80px;
}
.showJDKRAforPosition {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  gap: 60px;
  flex-wrap: wrap;
}
.jdkraViewItem {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  color: #fff;
  font-size: 16px;
  font-family: Inter;
}
.JDKRAforPositionList {
  width: calc(100% - 40px);
  max-width: 100%;
  list-style-type: decimal;
  color: #fff;
}
.indMatricsDtl {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}
.matRatingComment {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
}

.RightFlotingFormContainerArea .matRatingComment .formElement {
  width: 100%;
  border-bottom: 1px solid transparent;
}
.JDKRAforPositionList li {
  border-bottom: 1px solid #545454;
  margin-bottom: 20px;
}
.indMatricsDtl .matName {
  font-size: 16px;
}

.matRatingComment .MuiSlider-thumb.MuiSlider-thumbSizeSmall::before {
  background-color: #ff9800;
}
.matRatingComment .MuiSlider-root {
  color: #ff9800;
}
.matRatingComment
  .MuiFormControl-root.MuiTextField-root.descriptionTextFild
  .MuiInputBase-root.MuiOutlinedInput-root {
  color: #fff;
}

.invoiceContainer {
  width: auto !important;
}
.invoHeader {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.ivhLogo {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
}
.ivhLogoImg {
  height: 50px;
  width: auto;
}
.ivhLogoImg img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.inhOrgName {
  margin-left: 8px;
  font-size: 1.7rem;
  font-weight: 300 !important;
  text-transform: uppercase;
  letter-spacing: -1px !important;
}
.inhOrgName span {
  font-weight: 600 !important;
  color: #023047;
  letter-spacing: -1px !important;
}
.invhTitle {
  font-size: 1.7rem;
  font-weight: 400 !important;
  color: #000000;
}

/* start rating start */
.nameWithReatingStat {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.pmsModelBox .ratingWithTxt {
  background-color: transparent;
  width: auto;
  gap: 10px;
}
.ratingWithTxt {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 20px);
  gap: 50px;
  background-color: #333333;
  padding: 2px 10px;
  border-radius: 6px;
}
.star-rating {
  display: flex;
  align-items: center;
  gap: 4px;
}

.star {
  font-size: 2rem;
  cursor: pointer;
  color: #b2b2b2;
  transition: all 0.6s;
}

.star.filled {
  color: #ff9800;
}

.star:hover,
.star.filled:hover {
  color: #ff5722;
}
.ratingText {
  /*background-color: #4c4c4c; */
  padding: 4px 12px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

/* rating colors */
.rating-poor {
  color: red;
}

.rating-average {
  color: orange;
}

.rating-good {
  color: #03a9f4;
}

.rating-very-good {
  color: #9acd32;
}

.rating-excellent {
  color: #1fe000;
}
/* start rating end */
.width320 {
  width: 320px;
}
.employeeDtlHeader {
  width: 100%;
}
.employeeDtlHeader ul.empdtlUL {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
  list-style: none;
  padding-inline-start: 0px;
}
.employeeDtlHeader ul.empdtlUL .empDtlList {
  font-size: 14px;
  margin-bottom: 3px;
  color: #6b6b6b;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.employeeDtlHeader ul.empdtlUL .empDtlList span.empDtlInfo {
  color: #1c1c1c;
  font-weight: 500;
}
.empDtlInfoHeAD {
  width: 156px;
}
.pmsDetailInfoGrp {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-bottom: 12px;
  padding-bottom: 12px;
  border-bottom: 1px dashed #545454;
}
.pmsDtlIndElement {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
}
.pmsDtlInfoTitle {
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.7;
  min-width: 160px;
}
.pmsDtlInfoDescn {
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 10px;
}

.tblActionBtnGrp .MuiButtonBase-root.MuiIconButton-root.pmsDoneBtn svg {
  fill: #19b500;
}
.newStrct.textCenter,
.curntStrct.textCenter,
.textCenter {
  text-align: center !important;
}
.salrElement {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.crntsalstruc {
  background-color: #f2f2f2;
}
.newsalstruc {
  background-color: #e2ffe4;
}
.newsalstrucHigh {
  background-color: #71b175 !important;
  border-color: #35993c !important;
}
.mMxW200 {
  min-width: 200px !important;
  max-width: 250px !important;
  width: 200px !important;
}
.curntStrct,
.newStrct {
  text-align: right !important;
}
.amuntunit {
  font-weight: 600 !important;
  margin-left: 6px;
}
.salElmAmunt {
  text-align: right !important;
  font-weight: 700;
}

.newsalstruc .modelTextFild .MuiInputBase-root.MuiOutlinedInput-root {
  height: 42px;
  background: white;
  padding: 10px 8px 10px 12px;
}
.newsalstruc
  .modelTextFild
  .MuiInputBase-root.MuiOutlinedInput-root
  .MuiInputBase-input.MuiOutlinedInput-input {
  padding: 2px !important;
  font-weight: 700;
  text-align: right;
}
.ModelBox.pmsModelAutoWidth {
  z-index: 100;
  max-height: 90vh;
  width: auto;
}
.modTableFoot tr td {
  background-color: #c9c9c9;
}
.tfHText {
  font-weight: 700;
}
.salGropuHead {
  font-weight: 700;
  font-size: 16px;
  background-color: #e3e3e3;
}

.newsalstruc
  .modelTextFild
  .MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled {
  height: 42px;
  background: rgb(166 166 166);
  padding: 10px 8px 10px 12px;
}

.modResptable table.pmsReviewTable tr th {
  font-size: 14px;
  font-family: Inter;
  padding: 4px !important;
  width: auto;
  cursor: pointer;
  background-color: #303030;
  border-color: #3e3e3e;
  color: #fff;
  font-weight: 600;
}
.emptyrow td {
  height: 6px !important;
}

.warningsMsg {
  padding: 6px 10px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  background-color: #ffe3e3;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}
.hilighttxt {
  font-weight: 700;
}
.mxH-55vh {
  max-height: 55vh;
}
.selectedBankPayment {
  background: #9f9f9f;
}
.modelContain.width50vwModel {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50vw;
  min-height: 20vh;
  height: auto;
  max-height: 90vh;
  overflow: hidden;
  overflow-y: overlay;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
}
.modelContain .JDKRAContainEdit {
  width: calc(100% - 32px);
}
.audioNoteLengthDisc {
  font-size: 10px !important;
  color: red;
}

.tskTimeHeading {
  color: white !important;
}

.statChangeReq {
  background-color: #4971a9;
}
.tskFilter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.acnDcnIcon .MuiButtonBase-root.MuiIconButton-root {
  /* background-color: #333333; */
  color: #a0a0a0;
  height: 36px;
  width: 36px;
  padding: 4px;
}
.acnDcnIcon .MuiButtonBase-root.MuiIconButton-root.filterActive {
  color: #ff9900;
  background-color: #1c1c1c;
}

.taskTitlePatId {
  color: #fff;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.24px;
  font-size: 18px !important;
}
.customCalender {
  overflow: unset !important;
  height: auto !important;
  margin: 10px 0px !important;
  display: block !important;
}
.editTimelineBtn.MuiButtonBase-root.MuiIconButton-root {
  background-color: #333333;
  height: 28px;
  width: 28px;
  color: #b2b2b2;
}
.editTimelineBtn.MuiButtonBase-root.MuiIconButton-root svg {
  height: 18px;
  width: 18px;
  color: #b2b2b2;
}
.taskContainEditBtnGrp {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
}
.filterTaskGroup {
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: absolute;
  top: 105px;
  right: 20px;
}
.filElmInd .MuiButtonBase-root.MuiIconButton-root {
  background-color: #333333;
  color: #f1f1f1;
}
.filElmInd .MuiButtonBase-root.MuiIconButton-root:hover {
  background-color: #666666;
  color: #ffffff;
}
.filElmInd .MuiButtonBase-root.MuiIconButton-root.overDue {
  color: #bd0000;
}
.filElmInd .MuiButtonBase-root.MuiIconButton-root.overDue.active {
  background-color: #000000;
  color: #bd0000;
  box-shadow: rgb(0 0 0 / 28%) 0px 13px 11px -5px,
    rgb(0 0 0 / 30%) 0px 8px 0px -8px;
}
.filElmInd .MuiButtonBase-root.MuiIconButton-root.outOfTime {
  color: #ff8989;
}
.filElmInd .MuiButtonBase-root.MuiIconButton-root.outOfTime.active {
  background-color: #000000;
  color: #ff8989;
  box-shadow: rgb(0 0 0 / 28%) 0px 13px 11px -5px,
    rgb(0 0 0 / 30%) 0px 8px 0px -8px;
}
.filElmInd .MuiButtonBase-root.MuiIconButton-root.onTime {
  color: #c6e441;
}
.filElmInd .MuiButtonBase-root.MuiIconButton-root.onTime.active {
  background-color: #000000;
  color: #c6e441;
  box-shadow: rgb(0 0 0 / 28%) 0px 13px 11px -5px,
    rgb(0 0 0 / 30%) 0px 8px 0px -8px;
}
.filElmInd .MuiButtonBase-root.MuiIconButton-root.beforeTime {
  color: #17c900;
}
.filElmInd .MuiButtonBase-root.MuiIconButton-root.beforeTime.active {
  background-color: #000000;
  color: #17c900;
  box-shadow: rgb(0 0 0 / 28%) 0px 13px 11px -5px,
    rgb(0 0 0 / 30%) 0px 8px 0px -8px;
}
.filterTaskGroup .filElmInd .MuiBadge-badge.MuiBadge-standard {
  color: #fff;
  background-color: #545454;
  transform: scale(1) translate(50%, -25%);
}
.groupbtnPriot {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}
.fordBtn .MuiButtonBase-root.MuiIconButton-root svg {
  color: #959595;
}
.fordBtn .MuiButtonBase-root.MuiIconButton-root.fordTskcompleted svg {
  color: #be99ff;
}
.rippleEffect {
  -webkit-animation: rippleEffect 1s linear infinite;
  animation: rippleEffect 1s linear infinite;
}

@-webkit-keyframes rippleEffect {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(158, 158, 158, 0.3),
      0 0 0 2px rgba(158, 158, 158, 0.3), 0 0 0 4px rgba(158, 158, 158, 0.3),
      0 0 0 6px rgba(158, 158, 158, 0.3);
    box-shadow: 0 0 0 0 rgba(158, 158, 158, 0.3),
      0 0 0 2px rgba(158, 158, 158, 0.3), 0 0 0 4px rgba(158, 158, 158, 0.3),
      0 0 0 6px rgba(158, 158, 158, 0.3);
  }
  100% {
    -webkit-box-shadow: 0 0 0 4px rgba(158, 158, 158, 0.3),
      0 0 8px rgba(158, 158, 158, 0.3), 0 0 0 10px rgba(158, 158, 158, 0.3),
      0 0 0 12px rgba(158, 158, 158, 0);
    box-shadow: 0 0 0 4px rgba(158, 158, 158, 0.3),
      0 0 8px rgba(158, 158, 158, 0.3), 0 0 0 10px rgba(158, 158, 158, 0.3),
      0 0 0 12px rgba(158, 158, 158, 0);
  }
}
@keyframes rippleEffect {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(158, 158, 158, 0.3),
      0 0 0 2px rgba(158, 158, 158, 0.3), 0 0 0 4px rgba(158, 158, 158, 0.3),
      0 0 0 6px rgba(158, 158, 158, 0.3);
    box-shadow: 0 0 0 0 rgba(158, 158, 158, 0.3),
      0 0 0 2px rgba(158, 158, 158, 0.3), 0 0 0 4px rgba(158, 158, 158, 0.3),
      0 0 0 6px rgba(158, 158, 158, 0.3);
  }
  100% {
    -webkit-box-shadow: 0 0 0 4px rgba(158, 158, 158, 0.3),
      0 0 8px rgba(158, 158, 158, 0.3), 0 0 0 10px rgba(158, 158, 158, 0.3),
      0 0 0 12px rgba(158, 158, 158, 0);
    box-shadow: 0 0 0 4px rgba(158, 158, 158, 0.3),
      0 0 8px rgba(158, 158, 158, 0.3), 0 0 0 10px rgba(158, 158, 158, 0.3),
      0 0 0 12px rgba(158, 158, 158, 0);
  }
}
.forwardList {
  width: 100%;
}
.forwardList ul {
  padding-inline-start: 0px;
  list-style-type: none;
  color: #949494;
  margin-top: 0px;
}
.forwardList ul li {
  margin-bottom: 4px;
  list-style: none;
}

.forwardList ul .forwDtlGrp {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 2px;
  color: #ffffff;
  background-color: #0f0f0f;
  padding: 0;
  border-radius: 4px;
}
.forwUsrDt {
  display: flex;
  flex-direction: column;
  gap: 1px;
}
.forwUsrDateTime {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.5px;
  opacity: 0.5;
  line-height: normal;
}
.forwTskStatus {
  background-color: #4c4c4c;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.5px;
}
.moreMenu {
  height: 40px;
  width: 40px;
}
.moreMenu .MuiButtonBase-root.MuiIconButton-root {
  background-color: #333;
  color: #b2b2b2;
}
.frwUserDtlWthBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 20px);
  padding: 4px 10px;
  background-color: #1c1c1c;
}
.fordBtn .MuiButtonBase-root.MuiIconButton-root {
  padding: 6px;
}
.frwuNmdate {
  display: flex;
  flex-direction: column;
  gap: 0px;
}
.leatestComment {
  width: calc(100% - 20px);
  padding: 2px 10px 4px 10px;
}
.actionReplyBtnGrp .MuiButtonBase-root.MuiIconButton-root {
  background-color: #606060;
  color: #fff;
}
.actionReplyBtnGrp {
  width: 70px;
  display: flex;
  align-items: center;
  gap: 4px;
}
.rplyFrmWithText {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
  margin-top: 3px;
  padding-top: 3px;
  border-top: 1px dashed #545454;
}
.ltctext {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.14px;
}
.inComment {
  justify-content: flex-start;
  opacity: 1;
  position: relative;
}
.outComment {
  justify-content: flex-end;
  opacity: 0.7;
}
.inComment span {
  text-align: left;
  background-color: #545454;
  padding: 4px 6px;
  border-radius: 4px;
  border-top-left-radius: 0px;
  position: relative;
  margin-left: 20px;
}
.outComment span {
  text-align: right;
  background-color: #1c1c1c;
  padding: 4px 6px;
  border-radius: 4px;
  border-top-right-radius: 0px;
  position: relative;
  margin-right: 20px;
}

.inComment span::after {
  border-left: 16px solid #0000;
  border-right: 0 solid #0000;
  border-top: 9px solid #545454 !important;
  clear: both;
  content: "";
  height: 0;
  left: -16px;
  position: absolute;
  top: 0;
  width: 0;
}
.outComment span::after {
  border-bottom: 9px solid #0000;
  border-left: 16px solid #1c1c1c !important;
  border-right: 0 solid #0000;
  clear: both;
  content: "";
  height: 0;
  position: absolute;
  right: -16px;
  top: 0;
  width: 0;
}
.ltctext span {
  max-width: 95%;
}
.inOutIcon {
  position: relative;
}
.MuiSvgIcon-root.starTask {
  color: #f59006;
  position: absolute;
  top: -10px;
  left: -11px;
  height: 20px;
  width: 20px;
  padding: 0px;
}
.MuiSvgIcon-root.starTask path {
  fill: #f59006 !important;
}
.forwardList .frwUserDtlWthBtn .horaizonScroll {
  width: calc(100% - 8px);
}
