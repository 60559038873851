.mnuCompoIncomingInstanse::-webkit-scrollbar {
  width: 0px !important;
  height: 6px !important;
}
.mnuCompoIncomingInstanse::-webkit-scrollbar-thumb {
  background-color: rgba(107, 107, 107, 1);
}

.mnuCompoIncomingInstanse::-webkit-scrollbar-track {
  background: rgb(18 18 18 / 70%);
}
.homeContainerArea {
  height: 100%;
  width: 100%;
  display: block;
}
.homeContainArea {
  height: 100%;
  width: 100% !important;
  margin: 0;
  padding: 0;
}
.hmContWrkSpc {
  padding: 14px 0px 20px 70px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  gap: 20px;
}
.hmTabsContainer {
  display: flex;
  width: 750px;
  flex-direction: column;
  align-items: flex-start;
}
.hmTabsGroup {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
}
.hmTabsBtn {
  display: flex;
  height: 48px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  flex: 1 0 0;
  background: transparent;
  outline: none;
  border: none;
  cursor: pointer;
}
.hmTabLayer {
  display: flex;
  padding: 0px 16px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  position: relative;
}
.hmTabContnt {
  display: flex;
  /* height: 48px; */
  padding: 14px 0px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  /* gap: 12px; */
}

.hmTabContnt .MuiBadge-root .MuiBadge-badge.MuiBadge-standard {
  right: -12px;
  top: -2px;
  background-color: #333;
  font-size: 12px;
  font-family: Inter;
  opacity: 0.5;
}
.hmBtnActive .hmTabContnt .MuiBadge-root .MuiBadge-badge.MuiBadge-standard {
  opacity: 1;
}
.hmTbText {
  color: #606060;
  text-align: center;

  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.1px;
}
.hmBtnActive .hmTbText {
  color: #ffffff;
}
.hmTabActvIndcator {
  display: none;
  width: 145px;
  height: 2px;
  position: absolute;
  bottom: 0px;
  background: #fff;
  transition: all 0.5s;
}

.hmBtnActive .hmTabActvIndcator {
  display: block;
}

.FilterCardContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
.filterIconBtn .MuiIconButton-root svg {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  fill: #707070;
}
.filteredTagsCont {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
}
.filtrdTagsCrd {
  display: inline-flex;
  padding: 4px 11px;
  align-items: center;
  gap: 5px;
  border-radius: 4px;
  background: #000;
}
.fltrdTgTxt {
  color: #b0b0b0;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
  letter-spacing: -0.14px;
}
.fltrdTgClose {
  display: flex;
  padding: 2px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 20px;
  background: #818181;
}
.fltrdTgClose svg {
  fill: #000;
  height: 14px;
  width: 14px;
}

.filtrdTgArea {
  display: inline-flex;
  flex-direction: row;
  gap: 14px;
  width: 100%;
  overflow: hidden;
}
.mnuCompoContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2px;
  height: calc(100vh - 182px);
}
.fltrdTgClose {
  cursor: pointer;
}
.prvFilterdBtn,
.NxtFilterdBtn {
  display: none;
}

.button-contianer {
  display: flex;
  justify-content: space-between;
}

.img-container {
  display: flex;
  overflow: hidden;
  width: 100%;
  border: 5px solid #ffffff;
  border-radius: 10px;
}
.msgCardContainer {
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid #545454;
  width: 100%;
  cursor: pointer;
  transition: all 0.5s;
}
.msgCardContainer:hover {
  background-color: #000;
}
/* task card start */
.mnuCompoIncomingInstanse {
  overflow: hidden !important;
  overflow-y: overlay !important;
  position: relative;
  width: 100%;
  height: 100%;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none;
}
.taskCardContainer {
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid #545454;
  width: 100%;
  cursor: pointer;
  transition: all 0.5s;
}
.taskCardContainer:hover {
  background-color: #000;
}

.contMxWidthLim {
  display: flex;
  padding: 22px 0px 22px 10px;
  align-items: flex-start;
  gap: 14px;
  width: 100%;
  max-width: 620px;
}
.tskInOutIoc {
  height: 24px;
  width: 24px;
}
.msgInOutIoc {
  height: 24px;
  width: 24px;
}
.msgInfoDtl {
  display: flex;
  align-items: flex-start;
  gap: 7px;
}
/* .tskInOutIoc svg{

} */
.tskInfoDtl {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 13px;
  flex: 1;
}
.tskNmPriot {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 20px;
  width: 100%;
}
.tskTitle {
  color: #fff;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.18px;
}
.tskPriot {
  display: flex;
  padding: 3px 8px;
  align-items: flex-start;
  gap: 15px;
  border-radius: 4px;
}
.priStat {
  color: #fff;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.12px;
  text-transform: uppercase;
}
.statUrgent {
  background-color: #ff0000;
}
.statImportant {
  background-color: #edb900;
}
.statNormal {
  background-color: #19b500;
}
.tskTimeline {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
}
.tskTimBar {
  width: 100%;
}
.progress {
  background-color: #d8d8d8;
  border-radius: 8px;
  position: relative;
  margin: 2px 0;
  height: 5px;
  width: 100%;
}

.progress-done {
  border-radius: 8px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 0;
  opacity: 0;
  transition: 1s ease 0.3s;
}
.progress-done.up50 {
  /* background: linear-gradient(to left, #f2709c, #ff9472); */
  background-color: #04d500;
}
.progress-done.up80 {
  /* background: linear-gradient(to left, #9b0b3b, #a02804); */
  background-color: #d5a600;
}
.progress-done.up100 {
  background-color: #ff0000;
}
.tskDtSE {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.tskDTEnd,
.tskDTStart {
  color: #fff;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.12px;
}
.tskUser {
  display: flex;
  align-items: flex-start;
  gap: 77px;
}
.tskAsgnUsr {
  display: flex;
  align-items: flex-start;
  gap: 7px;
}
.asgnUsrImg {
  height: 32px;
  width: 32px;
  border-radius: 32px;
}
.asgnUsrImg img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 32px;
  border: 1.3px solid #000;
}
.tskAsginNM {
  display: flex;
  min-width: 182px;
  flex-direction: column;
  align-items: flex-start;
  gap: 3px;
}
.tskAsginUsrName {
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.16px;
}
.tskAsginHead {
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.14px;
  opacity: 0.7;
}

/* task card end */

/* meetings card start */
.meetingCardContainer {
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid #545454;
  width: 100%;
  cursor: pointer;
  transition: all 0.5s;
}
.meetingCardContainer:hover {
  background-color: #000;
}
.meetInOutIoc {
  height: 24px;
  width: 24px;
}

.meetInfoDtl {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 13px;
  flex: 1;
}
.meetHead {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 7px;
}
.meetTitle {
  color: #fff;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.18px;
}
.meetDT {
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.14px;
}
.meetDescrp {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 3px;
}
.metDesTitle {
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  /* letter-spacing: -0.14px; */
  opacity: 0.7;
}
.meetingCardContainer .metDesDtil {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}
.metDesDtil {
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.joinMetBtn {
  display: flex !important;
  padding: 6px 18px !important;
  align-items: center !important;
  gap: 6px !important;
  border-radius: 4px !important;
  background: #00adef !important;
  color: #fff !important;
  font-family: Inter !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 24px !important;
  letter-spacing: 0.14px !important;
  text-transform: capitalize !important;
}
.joinMetBtn .MuiButton-startIcon {
  margin-right: 1px;
}
.joinMetBtn svg {
  width: 24px;
  height: 14px;
  fill: #fff;
}
.meetLnkBtnGrp {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}
.metCopyLnk {
  display: flex;
  align-items: center;
  gap: 18px;
}
.lnkTxt {
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  word-break: break-word;
}
.lnkCpyBtn .MuiIconButton-root {
  padding: 0px;
  margin: 0px;
  height: 32px;
  width: 32px;
}
.lnkCpyBtn .MuiIconButton-root svg {
  width: 19px;
  height: 25px;
  color: #707070;
}
.meetConvDtl {
  display: flex;
  align-items: flex-start;
  gap: 8px;
}
.metConvImg {
  width: 26px;
  height: 26px;
  flex-shrink: 0;
  border-radius: 26px;
  position: relative;
}
.metConvImg img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border: 1.6px solid #fff;
  border-radius: 26px;
}
.metConvNmD {
  display: flex;
  align-items: flex-start;
}
.metConvName {
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 175% */
}
.metConvName .nmSpan {
  color: #a0a0a0;
}
.vUchk {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 15px;
  width: 15px;
  border-radius: 15px;
  position: absolute;
  bottom: -6px;
  right: -4px;
}

/* meetings card end */
.msgSendDtl {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  width: 100%;
}
.msgSendNmGrp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.msgSunderImg {
  height: 50px;
  width: 50px;
  border-radius: 50px;
}
.msgSunderImg img {
  height: 50px;
  width: 50px;
  object-fit: cover;
  border-radius: 50%;
}
.senderName {
  color: #fff;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.18px;
}
.sendTime {
  color: #fff;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.12px;
}
.sndTmMM {
  margin-left: 10px;
}
.msgSendCommnt {
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.14px;
}

.msgCardContainer .msgSendCommnt {
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.14px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rightShowDtlPanel {
  height: 100%;
  width: 100%;
}
.dashboardTaskContainer,
.dashboardMeetingsContainer,
.dashboardChatContainer,
.activeContainer {
  background-color: #000;
  width: 100%;
}
.dashboardChatContainer .chatFrwUsrLstHeader,
.dashboardChatContainer .chatGrpDtlHeader,
.dashboardChatContainer .chatConvoHeader {
  background-color: #000;
  border-bottom: 1px solid #545454;
}
.dashboardChatContainer .chatConversationPannel {
  height: calc(100vh - 80px);
}
.dashboardChatContainer .chatFrwUsrLstContainer,
.dashboardChatContainer .chatGrpDtlContainer {
  max-width: 100%;
}
.dashboardChatContainer .chatConversationPannel .hideWhenUseInDashboard {
  display: none;
}

.dashboardChatContainer .grpCretFooter,
.dashboardChatContainer .chatConvoFooter {
  background-color: #000;
  border-top: 1px solid #545454;
}
.dashboardChatContainer .chatFrwUsrLstHeader,
.dashboardChatContainer .chatGrpDtlHeader {
  padding: 10px 70px 10px 16px;
}
.dashboardChatContainer .chatGrpDtlContainer .chatGrpDtlArea .cNwGrpImgNmArea {
  margin: 20px 70px 5px 16px;
}
.dashboardChatContainer .cNwGrpNmContain {
  flex: 1 1;
  width: 100%;
}
.dashboardChatContainer .chatGrpDtlContainer .chatContactList {
  margin: 0px 78px 0px 16px;
  width: calc(100% - 94px);
  padding: 0;
}
.dashboardChatContainer .selectedPartList {
  margin: 4px 70px 4px 16px;
}
.dashboardChatContainer .chtsrchUsr {
  padding: 0 70px 0 16px;
  margin-right: 8px;
}
.dashboardChatContainer .closeBtnForDashboard {
  display: flex !important;
}

.noDataCard {
  color: #606060;
  font-size: 16px;
  font-weight: 600;
  font-family: Inter;
  text-align: center;
  width: 100%;
  max-width: 750px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.noDataCard span {
  font-size: 24px;
  font-weight: 800;
}

.dashboardMailContainer {
  height: 100%;
  width: 100%;
  background-color: #000;
}
.dashMailPannel {
  display: flex;
  flex-direction: row;
  height: calc(100vh - 80px);
  overflow: hidden;
  position: relative;
  border-left: 0.1px solid #545454;
  background-color: #000;
}
.dashMailContain {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: #000000;
}
/* .dashMailHeaderPnl {
} */

.dashMailContain .emailSubject {
  color: #fff;
  margin-left: 0px;
}
.dashMailContain .backToEmailList {
  order: 3;
}
.dashMailContain .emailTitle {
  order: 1;
  align-items: center;
}
.dashMailContain .emailAction {
  order: 2;
}

.emailViewContainer {
  padding: 0 16px;
}
.tskTyp {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  flex-direction: column;
}
.tskTypLabel {
  background-color: #333333;
  padding: 2px 10px;
  border-radius: 4px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
  color: #fff;
}
.tsktypCommnt {
  font-size: 14px;
  font-family: Inter;
  line-height: 18px;
  color: #ffffff;
}
.ModelBox.pmsModelBox {
  z-index: 100;
  max-width: 70vw;
  max-height: 90vh;
  width: 100%;
}

.ModelBox.pmsModelBox .mw80 {
  min-width: 80px;
  max-width: 80px;
  width: 80px;
}
.ModelBox.pmsModelBox table tr th {
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  z-index: 3;
}
.ModelBox.pmsModelBox .elementFormContainer {
  max-height: 55vh;
}
.MuiButtonBase-root.MuiIconButton-root.closeIfremBtn {
  position: absolute;
  top: 33px;
  right: 10px;
}
.policy-accordion {
  width: calc(100% - 20px);
  padding-inline-start: 20px;
  list-style-type: decimal;
}
.policy-items,
.policy-container {
  width: 100%;
}
.MuiButtonBase-root.MuiAccordionSummary-root.policyHeader {
  background-color: #cccccc;
}
.MuiButtonBase-root.MuiButton-root.policy-view-document-Btn {
  border: 1px solid #545454;
  color: #1c1c1c;
}
.privecyPolicyInd {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2px 6px;
}
.policy-accordion li {
  margin-bottom: 5px;
  border-bottom: 1px solid #d6d6d6;
}
.activePP {
  background-color: #e5e5e5;
  border-radius: 6px;
}
.dfcfs {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.orderUnset {
  order: unset !important;
  width: 100%;
}
.mb10 {
  margin-bottom: 10px;
}
