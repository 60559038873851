.task-cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: flex-start;
  margin: 4px 0 12px 0;
}

.task-card {
  background-color: #272727;
  border: 1px solid #545454;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 16px;
  width: calc((100% - 84px) / 2);
  display: flex;
  flex-direction: column;
  position: relative;
}

.task-card h3 {
  margin: 0 0 8px;
  color: #333;
  font-size: 1.25em;
}

.task-card p {
  margin: 4px 0;
  color: #ffffff;
}

.task-card strong {
  color: #ffffff;
  opacity: 0.7;
  letter-spacing: 0.5px;
  font-weight: 600;
}
.draft-task-btn {
  position: absolute;
  bottom: 16px;
  right: 16px;
  padding: 8px 16px;
  background-color: #00adef;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.draft-task-btn:hover {
  background-color: #0056b3;
}
